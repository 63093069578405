import React from 'react'
import {  introductionUIForYoutubeVideo } from '../../script/backgroundUI'
import { FaLink } from 'react-icons/fa';
import { TbVideo } from "react-icons/tb";
import { Heading } from './Heading';

export const Step4 = () => {
  return (
    <div className='mb-[50px] ' >
        <Heading title={'Scale With Efficiency'} desc={'Automate and scale your video production to meet the demands of your growing audience'} />

        <div style={introductionUIForYoutubeVideo} className=' rounded-2xl shadow-lg grid grid-cols-1 laptop:grid-cols-2 gap-[30px] laptop:gap-[70px] p-[25px] laptop:p-[50px] mt-[20px] ' >
            {
                step4.map((el,i)=>(
                    <div key={i} className='rounded-lg bg-white p-[30px] border ' >
                        <div className={`rounded-md flex justify-center items-center h-[35px] w-[35px] text-white ${i==0?'text-[16px] bg-green-400 ':'text-[20px] bg-blue-400  '} mb-2 `} >{el.icon}</div>
                        <h2 className='text-off-black font-[700] text-[22px] ' >{el.title}</h2>
                        <p className='text-dark-gray text-[17px] ' >{el.desc}</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

let step4 = [
    {
      title:'API for Scale',
      desc : 'Integrate Personate’s automation features to streamline video production at any scale',
      icon : <FaLink />
    },
    {
      title:'Personalized Videos at Scale',
      desc : 'Share custom messages on email, WhatsApp, and other platforms',
      icon : <TbVideo />
    },
  ]
