import React from 'react'
import { Link } from 'react-router-dom'
import { GoArrowRight } from "react-icons/go";


export const IntroductionWithoutImage = ({title,title2,desc,btn1name,btn1link,UI}) => {
  return (
    <div style={UI} className='font-redHat py-[70px] ' >
        <h1 className='text-[28px] laptop:text-[50px] text-off-black text-center font-[500] w-[90%] mx-auto ' >{title}</h1>
        <p className='text-[14px] laptop:text-[16px] text-dark-gray font-[400] tracking-wide text-center w-[90%] laptop:w-[50%] mx-auto mt-[10px] '  >{desc}</p>

        <div className='flex justify-center ' >
            <Link to={btn1link} target='_blank' >
                <button className='mx-auto text-[16px] border shadow-sm font-[500] ease-in-out duration-300 hover:bg-gray-100 bg-white mt-[20px] text-off-black rounded-md py-[7px] px-[12px] flex items-center gap-x-1 ' >{btn1name}</button>
            </Link>
        </div>
    
    </div>
  )
}
