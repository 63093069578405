import React  from 'react'
import { Introduction } from '../GlobalComponents/Introduction'
import { register, typeform } from '../script/buttonLinks'
import { FourTitleBoxIcon } from '../GlobalComponents/FourTitleBoxIcon';

import { CiVideoOn } from "react-icons/ci";
import { MdOutlineVoicemail } from "react-icons/md";
import { SiOctanerender } from "react-icons/si";
import { ThreeDots } from '../GlobalComponents/ThreeDots';
import { introductionUIForNormalVideo } from '../script/backgroundUI';
import { baseURL } from '../script/baseURL';
import { Helmet } from 'react-helmet';
import { Newsletter } from '../myComponents/HomePageComponents/Newsletter';
export const InstantAiVideo = () => {
    
  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Instant AI Video | Streamline Your Video Updates</title>
          <link rel="canonical" href={`${baseURL}/features/instant-ai-video`} />
          <meta name="description" content="Update videos instantly with new scripts. Seamless lip-syncing keeps your content fresh and engaging." />
        </Helmet>
        <Introduction
            title={<>Quickly Refresh Your Videos With <br />Our <span className='text-primary'>Instant AI Video</span></>}
            desc={`Instant AI Video enables rapid modifications to your existing video content by syncing new dialogue to the same visuals. Keep your video's original look while updating the script for fresh messaging`}
            btn1name={'Get Started for Free'}
            btn1link={register}
            UI={introductionUIForNormalVideo}
            poster={'/instantAIVideoPoster.png'}
            videoURL={'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/intstant_ai_avatar_video_generation.mp4'}
        />

        <FourTitleBoxIcon
            title={'How It Works: Easy Steps to Update Your Videos'}
            desc={'Instant AI Video swiftly aligns new audio scripts with your existing video footage, refreshing your content while keeping the original visuals intact'}
            data={updateSteps}
            btn2name={'Try Instant AI Video Now'}
            btn2link={register}
            bottomDesc={'Act now and witness first-hand how Instant AI Video can elevate your content'}
        />

        <ThreeDots
            poster={'/instantAIVideoHomePoster.png'}
            title={'Discover the Competitive Edge of Instant AI Videos '}
            desc={'Smoothly integrate audio with video, ensuring perfect lip-sync and streamlined updates'}
            points={data1}
            btn1name={'Explore the Possibilities '}
            videoURL={'https://personate-data-v2.s3.amazonaws.com/website+data+/instant-ai-videos-personate-ai.mp4'}

        />
        <div className='' ><Newsletter bg='bg-lite-gray' /></div>
    </div>
  )
}
const updateSteps = [
    {
      title: "Upload Your Existing Video",
      desc: "Begin by uploading the video you want to update",
      icon : <CiVideoOn />
    },
    {
      title: "Upload New Audio File",
      desc: "Submit the new script recorded as an audio file that you want to sync",
      icon : <MdOutlineVoicemail />
    },
    {
      title: "Generate Your Updated Video",
      desc: "Our AI seamlessly integrates the new audio with perfect lip-syncing to the video's original visuals",
      icon : <SiOctanerender />
    }
];

const data1 = [
  {
    "title": "Flawless Sync:",
    "desc": "Precisely aligns new audio with presenter’s lip movements"
  },
  {
    "title": "Visual Integrity:",
    "desc": "Update audio without changing the video’s original visuals"
  },
  {
    "title": "Quick Updates:",
    "desc": "Rapidly adapt content to new trends without full reshoots"
  },
  {
    "title": "Cost-Effective:",
    "desc": "Reduces expenses compared to traditional video production"
  },
  {
    "title": "User-Friendly: ",
    "desc": "Simple interface for easy video updates"
  }
]

  
  