import React, { useState } from 'react'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading';
import { TwoButtons } from '../../GlobalComponents/TwoButtons';
import { typeform } from '../../script/buttonLinks';
import { IoMdPlay } from "react-icons/io";
import { FaSquareFull } from "react-icons/fa6";
export const AllVoices = () => {

    const [playingIndex, setPlayingIndex] = useState(null);
    const [audio, setAudio] = useState(null);

    const handleAudioPlay = (index, voiceUrl) => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
        }
        if (index === playingIndex) {
          setPlayingIndex(null);
          setAudio(null);
        } else {
          const newAudio = new Audio(voiceUrl);
          newAudio.play();
          setAudio(newAudio);
          setPlayingIndex(index);
        }
    };

  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto mb-[50px] font-redHat' >
            
            <HeadingAndSubheading heading={`AI Voices in Action`} subHeading={`Discover the versatility and realism of our AI Voices with demos highlighting their wide-ranging applications`} />

            
            <div className='grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 gap-4 laptop:gap-8 mt-[25px] laptop:mt-[50px]' >
                {
                    allVoicesData.map((el,i)=>(
                        <div  onClick={() => handleAudioPlay(i, el.voice)} key={i} className={`flex justify-between items-center px-[20px] py-[20px] rounded-md cursor-pointer border border-gray-100 transition ease-in-out duration-300 bg-white hover:bg-gray-100`} >
                            <div className='flex gap-[10px] items-center ' >
                                <div className='w-[25px] h-[25px]' >
                                    <img className='w-[100%] h-[100%] object-contain ' src={el.flag} alt={`${el.name} AI Voices `}/>
                                </div>
                                <div className='font-[600] text-[14px] ' >
                                    {el.name}
                                </div>
                            </div>
                            <div className={`flex justify-center items-center ${playingIndex==i?'text-[14px]':'text-[22px]'} text-gray-600 `} >
                                {playingIndex === i ? <FaSquareFull /> : <IoMdPlay />}
                            </div>
                        </div>

                       
                    ))
                }
            </div>
        
            <div className='flex justify-center mt-[20px] ' >
            <TwoButtons
                btn1name={'Create Your Custom AI Voice'}
                btn1link={typeform}
            />
            </div>
        
        </div>
  )
}


let allVoicesData = [
    {
        name : 'Arabic',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/arabic-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/arabic_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Bengali',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/bengali_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'English',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/uk-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/english_personate_ai_avatar_voice.wav'
    },
    {
        name : 'Finnish',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/finnish-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/finnish_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'French',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/french-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/french_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'German',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/german-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/german_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Gujrati',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/gujarati_personate_ai_avatar_voice.wav'
    },
    {
        name : 'Hindi',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/hindi_personate_ai_avatar_voice.wav'
    },
    {
        name : 'Japanese',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/japanese-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/japanese_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Kannada',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/kannada_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Malayalam',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/Malayalam_personate_ai_voice_.mp3'
    },
    {
        name : 'Marathi',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/marathi_personate_ai_avatar_voice.wav'
    },
    {
        name : 'Portuguese',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/portuguese-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/portuguese_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Spanish',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/spain-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/spanish_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Tamil',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/tamil_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Telugu',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/telugu_personate_ai_avatar_voice.mp3'
    },
    {
        name : 'Urdu',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/arabic-flag.png',
        voice : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_voices_samples+/urdu_personate_ai_avatar_voice.mp3'
    }
]










