import React, { useEffect } from 'react'
import IntroductionCompo from '../myComponents/HomePageComponents/IntroductionCompo'
import ClientsCarousels from '../myComponents/HomePageComponents/ClientsCarousels'
import Avatars from '../myComponents/HomePageComponents/Avatars'
import UseCases from '../myComponents/HomePageComponents/UseCases'
import AvatarBackground from '../myComponents/HomePageComponents/AvatarBackground'
import LanguageAvatars from '../myComponents/HomePageComponents/LanguageAvatars'
import Testemonials from '../myComponents/HomePageComponents/Testemonials'
import PopularPersonalites from '../myComponents/HomePageComponents/PopularPersonalites'
import GetStarted from '../myComponents/HomePageComponents/GetStarted'
import Comparison from '../myComponents/HomePageComponents/Comparison'
import ROI from '../myComponents/HomePageComponents/ROI'
import MediaCarousels from '../myComponents/HomePageComponents/MediaCarousels'
import { Newsletter } from '../myComponents/HomePageComponents/Newsletter'
import { VideoCollage } from '../myComponents/HomePageComponents/VideoCollage'
import { Helmet } from 'react-helmet';
import { baseURL } from '../script/baseURL'
import { AIVIdeoTranslation } from '../myComponents/HomePageComponents/AIVIdeoTranslation'
import { PersonalisedVideo } from '../myComponents/HomePageComponents/PersonalisedVideo'
import { InstantAIVideo } from '../myComponents/HomePageComponents/InstantAIVideo'
import Comparision from '../GlobalComponents/Comparision'
import { withOutPersonate, withPersonate } from '../script/homepageData'
const HomePage = () => {

  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Personate.ai",
    "description": "Personate.ai stands as the top-rated AI video generation platform.",
    "foundingDate": "2021",
    "url": "https://personate.ai/",
    "logo": "https://personate.ai/personate-logo.png",
    "founders": [
      {
        "@type": "Person",
        "name": "Akshay Sharma",
        "jobTitle": "Co-founder and CEO",
        "sameAs": "https://www.linkedin.com/in/beyondaksh/"
      },
      {
        "@type": "Person",
        "name": "Rishab Sharma",
        "jobTitle": "Co-founder and CTO",
        "sameAs": "https://www.linkedin.com/in/rishab-sharmaddn/"
      }
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Sales",
      "email": "hello@personate.ai"
    },
    "sameAs": [
      "https://www.linkedin.com/company/74974624",
      "https://twitter.com/Personate_ai",
      "https://www.instagram.com/personate_ai/",
      "https://www.youtube.com/@personateai",
      "https://personate.ai/"
    ],
    "owns": {
      "@type": "Product",
      "name": "Personate STUDIO", 
      "url": "https://app.personate.ai/"
    }
  }


  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://personate.ai/",
    "name": "Personate.ai",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://personate.ai/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const webpageSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://personate.ai/",
    "name": "Homepage",
    "description": "Personate.ai stands as the top-rated AI video generation platform.",
    "publisher": {
      "@type": "Organization",
      "name": "Personate.ai"
    }
  };

  // const imageObjectSchema = {
  //   "@context": "https://schema.org",
  //   "@type": "ImageObject",
  //   "url": "https://www.yourcompany.com/image.jpg",
  //   "height": "800",
  //   "width": "1200"
  // };

  // const videoObjectSchema = {
  //   "@context": "https://schema.org",
  //   "@type": "VideoObject",
  //   "name": "Your Video Title",
  //   "description": "Description of your video",
  //   "thumbnailUrl": "https://www.yourcompany.com/video-thumbnail.jpg",
  //   "uploadDate": "2024-06-19",
  //   "contentUrl": "https://www.yourcompany.com/video.mp4",
  //   "embedUrl": "https://www.youtube.com/embed/your-video-id"
  // };

  const thingSchema = {
    "@context": "https://schema.org",
    "@type": "Thing",
    "name": "Personate",
    "description": "Personate.ai stands as the top-rated AI video generation platform.",
    "url": "https://personate.ai/"
  };

  const siteNavigationElementSchema = {
    "@context": "https://schema.org",
    "@type": "SiteNavigationElement",
    "name": "Home",
    "url": "https://personate.ai/"
  };


  return (
    <div>
        {/* <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(websiteSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(webpageSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(thingSchema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(siteNavigationElementSchema)}
          </script>
        </Helmet> */}

        <Helmet>
          <meta charSet="utf-8" />
          <title>Personate.ai | AI Video Generator </title>
          <link rel="canonical" href={`${baseURL}`} />
          <meta name="description" content="Create engaging videos with Personate AI Video Generator. Easy-to-use, cost-efficient, and scalable AI solutions." />
        </Helmet>


        <IntroductionCompo/> 
        <ClientsCarousels/>
        <PopularPersonalites/>
        
        

        {/* <Features/> */}
        <Avatars data={avatarsVideo} />
        <LanguageAvatars/>
        <AIVIdeoTranslation/>
        <PersonalisedVideo/>
        <InstantAIVideo/>
        
        <GetStarted/>
        <Comparision
          heading={'Say Goodbye to High Production Costs'}
          subHeading={'Personate eliminates the need for expensive equipment, unreliable actors, and complicated editing tools.'}
          withImage={'/Comparision/Normal.png'}
          withOutImage={'https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled+design+(18).png'}
          withPersonate={withPersonate}
          withOutPersonate={withOutPersonate}
          title1={'Without Personate'}
          title2={'With Personate'}
          
        />
        <ROI/>
        <UseCases/>

        <VideoCollage/>
        <MediaCarousels/>
        <Testemonials/>
        <Newsletter/>
        <AvatarBackground/>
    </div>
  )
}

export default HomePage


const avatarsVideo = [
  {
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/different_avatar_videos/lily.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/homeavatarposter/lily.png'
  },
  {
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/different_avatar_videos/amina.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/homeavatarposter/amina.png'
  },
  {
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/different_avatar_videos/maya.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/homeavatarposter/maya.png'
  },
  {
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/different_avatar_videos/parul.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/homeavatarposter/parul.png'
  },
  {
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/different_avatar_videos/sahil.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/homeavatarposter/sahil.png'
  },
  {
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/different_avatar_videos/d93af435-b4fb-4420-a134-a4aba41daf7c.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/homeavatarposter/andrew.png'
  },
]






