import React, {useRef, useState } from 'react'
import {FaCircle} from 'react-icons/fa'
import { BsCreditCard2Front } from "react-icons/bs";
import { TwoButtons } from '../../GlobalComponents/TwoButtons';
import { GoArrowRight } from "react-icons/go";
import { HiSpeakerWave } from "react-icons/hi2";
import { HiSpeakerXMark } from "react-icons/hi2";

const IntroductionCompo = () => {

    const [isMute,setIsMute] = useState(true);
    const [isLoop,setIsLoop] = useState(true);
    const videoRef = useRef(null)
    const timerRef = useRef(null)

    const handleMute = () => {
        setIsMute(!isMute);
        
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.muted = !isMute;
        }
    }; 

 

  return (
    <div className='font-redHat w-[100%] tablet:w-[100%] laptop:w-[85%] laptop:ml-[10%] flex flex-col-reverse tablet:flex-col-reverse laptop:flex-row p-[20px]  laptop:py-[10px] laptop:px-0 desktop:mt-0'>
        
        {/* CONTENT SECTION */}
        <div className='w-[100%] my-auto laptop:w-[45%]' >
            <h3 className='text-[14px] laptop:text-[14px] text-gray-500 leading:[15px] laptop:leading-[15px] font-[500] ' ><i>Powering Stories for 200M+ Daily Viewers</i></h3>
            
            <h1 className='text-[24px] laptop:text-[30px] text-[#232833] leading:[40px] laptop:leading-[48px] font-bold' >Elevate Every Story With <br /><span className='text-transparent bg-clip-text bg-gradient-to-r from-[#05D6F9] to-primary'>AI Video Generator</span> </h1>
            {
                introdPoints.map((el,i)=>(
                    <ul key={i}>
                        <li className={`flex text-[16px] font-[500] text-dark-gray ${i == introdPoints.length - 1 ? 'mb-[20px]' : 'mb-[10px]'}`}>
                            <span className={`text-primary text-[8px] flex mt-[8px] mr-[10px]`}>
                                <FaCircle />
                            </span>
                            {el}
                        </li>
                    </ul>
                ))
            }

            <TwoButtons btn1name={`Get Started for Free`} btn2name={`Get in Touch`} />
            <p className='text-dark-gray text-[13px] mt-1 flex' ><span className='inine-block text-[14px] font-[400] text-dark-gray mr-1 my-auto' >{<BsCreditCard2Front />}</span> No credit card required</p>
        </div>
        

        
        {/* VIDEO SECTION */}
        <div className='w-[100%] laptop:w-[55%] mb-[50px] laptop:mb-0 flex items-center' >

            <div className='relative group ' onClick={handleMute}>
                <img  style={{boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"}} className='rounded-2xl cursor-pointer' src="https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled_design_3_tdrl13.jpg" alt="into-bg" />
                <div className=' absolute top-[14.5%] left-[48%] transform -translate-x-1/2 h-[46%] overflow-hidden ' >
                    <video  ref={videoRef} autoPlay muted={isMute} loop className='cursor-pointer w-full h-full object-cover'  src={'https://personate-data-v2.s3.amazonaws.com/website+data+/sample_videos_home_page/INTRO+VIDEO.mp4'} ></video>
                </div>
                <div className='absolute cursor-pointer left-[4%] bottom-[4%] h-[35px] w-[35px] laptop:h-[50px] laptop:w-[50px] group-hover:justify-start group-hover:pl-[5px] gap-x-[6px] group-hover:w-[80px] group-hover:laptop:w-[100px] transition-width duration-300 rounded-full bg-gray-400 bg-opacity-50 flex justify-center items-center' >
                    <div className='flex justify-center overflow-hidden items-center h-[25px] w-[25px] laptop:h-[40px] laptop:w-[40px] text-[17px] laptop:text-[20px] bg-white rounded-full ' >{isMute?<HiSpeakerXMark />:<HiSpeakerWave />}
                    </div>
                    <div className='hidden group-hover:block text-off-black font-[600] text-[15px] transition ease-in-out duration-300  ' >
                        {isMute?"Mute":"Listen"}
                    </div>
                </div>
            </div>

        </div>
        
    </div>
  )
}

export default IntroductionCompo


const introdPoints = [
    // 'Create videos in minutes using Custom Avatars',
    'Choose from an extensive library of AI Avatars',
    ' Dub existing videos into multiple languages with lip-sync',
    'Access over 100 hyper-realistic AI Voices in 60+ languages & accents',
]