import React, { useEffect, useRef, useState } from 'react'
import styles from './videocollage.module.css'
import {FaPlay} from 'react-icons/fa'
import {RxCross1} from 'react-icons/rx'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'

export const VideoCollage = () => {
    const [videoIndex,setVideoIndex] = useState(0)
    const [isPlay,setIsPlay] = useState(false)
    const videoRef = useRef(null)
    const [isModalOpen,setIsModalOpen] = useState(false)


    const handlePlay=()=>{
        if (videoRef.current) {
            if (videoRef.current.paused) {
              videoRef.current.play();
              setIsPlay(true)
            } else {
              videoRef.current.pause();
              setIsPlay(false)
            }
        }
    }

    const handleClick = (i)=>{
        setVideoIndex(i)
        setIsModalOpen(true)
    }

    const handleCut = ()=>{
        setIsPlay(false)
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Clean up function to reset overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);


  return (
    <div id='PersonateVideoCollage' style={{ backgroundImage: "url('videoCollageImages/videoCollageBg.png')", backgroundSize: 'cover', backgroundPosition: 'center', }} className={`w-[90%] mx-auto rounded-t-3xl rounded-b-3xl laptop:rounded-b-[60px] py-[30px] relative mb-[50px]  `} >
        <div>
            <h3 className={`text-[26px]  laptop:text-[30px] w-[90%] mx-auto font-[600] text-center mb-[9px] text-white tracking-wide `} >Discover Personate in Action</h3>
            <p className={`laptop:w-[65%] mx-auto text-center font-[400] text-[12px] laptop:text-[15px]  text-gray-300  mb-[20px] laptop:mb-[50px] tracking-wider `} >Explore real examples of AI-generated videos</p>
        </div>
        
        <div className={`${styles.wrapper}`} >
            {
                videoCollage.map((el,i)=>(
                    <div onClick={()=>handleClick(i)} key={i} className={`rounded-lg overflow-hidden cursor-pointer relative `} >
                        <img className={`w-[100%] h-[100%] object-cover`} src={el.image} alt="" />
                        <button className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-black bg-opacity-40 h-[45px] w-[45px] laptop:h-[50px] laptop:w-[50px]`} >{<FaPlay/>}</button>
                    </div>
                ))
            }
        </div>

        {isModalOpen && (
            <div className={`${styles.modalOverlay}  `}  onClick={()=>setIsModalOpen(false)}>
                <div onClick={(e) => e.stopPropagation()} className="w-[95%] tablet:w-[90%] laptop:w-[70%]" data-aos={isModalOpen ? "zoom-out" : "zoom-in"} >
                    <button className='text-[16px] ml-auto mb-2 hover:bg-gray-100 flex items-center bg-white text-black font-[500] rounded-lg py-[3px] px-[10px]  ' onClick={handleCut}>Close <span>{<RxCross1/>}</span></button>
                    <video
                        onClick={
                            (e)=>{
                                e.stopPropagation();
                                handlePlay();
                            }
                        }
                        ref={videoRef}
                        className='w-[100%] h-[100%] object-cover cursor-pointer  z-30 rounded-2xl overflow-hidden '
                        poster={videoCollage[videoIndex].image}
                        src= {videoCollage[videoIndex].video}
                    />
                    <button onClick={handlePlay} className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-black bg-opacity-40 h-[45px] w-[45px] laptop:h-[50px] laptop:w-[50px] ${isPlay?'hidden':'block'} `} >{<FaPlay/>}</button>
                </div>
            </div>
        )}
    </div>


  
  )
}


let videoCollage = [
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/sample_videos_home_page/sportstak+sample+final.mp4',
        image: '/videoCollageImages/sportstak.png'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/sample_videos_home_page/Add+a+heading.mp4',
        image: '/videoCollageImages/sportstak2.png'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/sample_videos_home_page/angelone-hindi.mp4',
        image: '/videoCollageImages/angleone.png'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/shiv+aroor++personate+Ai+Video+Translation.mp4',
        image: 'https://personate-data-v2.s3.amazonaws.com/website+data+/Ai+Video+Transalation+shiv+aroor+personate+ai+video.jpg'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/prime+mininster+ai+avatar+personate+ai+video+translation.mp4',
        image: 'https://personate-data-v2.s3.amazonaws.com/website+data+/prime+mininster+ai+avatar+personate+ai+video+translation+thumbnail.jpg'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/AI+Video+Transtion+united+nations+personate+ai+brewery.mp4',
        image: 'https://personate-data-v2.s3.amazonaws.com/website+data+/united+nations+personate+ai+video+transalation.jpg',
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/anjana+om+kashyap+2.0+personate+ai+avatar+video.mp4',
        image: 'https://personate-data-v2.s3.amazonaws.com/website+data+/anjana+om+kashyap+2.0+personate+ai+avatar.jpg'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/sample_videos_home_page/result_49ca8325-8205-461a-b4d2-cfbc822b546d.mp4',
        image: '/videoCollageImages/anjanaca.png'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/vtv+ai+news+anchor+avatar+personate+ai+top+ai+company+in+india+video.mp4',
        image: 'https://personate-data-v2.s3.amazonaws.com/website+data+/vtv+ai+news+anchor+avatar+personate+ai+top+ai+company+in+india.jpg'
    },
    {
        video: 'https://personate-data-v2.s3.amazonaws.com/website+data+/sample_videos_home_page/WhatsApp+Video+2024-04-10+at+22.22.59.mp4',
        image: '/videoCollageImages/whatsappmantri.png'
    }
];

