import React, { useRef, useState } from 'react'
// import { CircleUser } from 'lucide-react'
import { FaPlay, FaPause } from 'react-icons/fa'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'
import { TwoButtons } from '../../GlobalComponents/TwoButtons'
import { FiUser } from "react-icons/fi";
import { register, typeform } from '../../script/buttonLinks';
import { IoPlaySharp,IoPauseSharp } from "react-icons/io5";
export const IntroductionComponent = () => {

    const [isPlaying, setIsPlaying] = useState(100);
    const videoRefs = useRef([]);

    const handlePlayPause = (index) => {
        const video = videoRefs.current[index];
        const prevVideo = videoRefs.current[isPlaying];
        
        if (video.paused) {
            console.log('if video')
            video.play();
            setIsPlaying(index)
        }else {
            console.log('else if')
            video.pause();
            setIsPlaying(null)
        }


        if(prevVideo?.play){
            console.log('prev video')
            prevVideo?.pause()
        }
        
    };

    

  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto pt-[50px] font-redHat mb-[50px] ' >
        <HeadingAndSubheading heading={'AI Avatars in Action'} subHeading={`Witness the versatility and realism of our AI Avatars through a carousel of videos that demonstrate their potential in various scenarios`} />
        <div className='grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 gap-4 laptop:gap-8 mt-[30px]' >
            {
                avatars.map((el,i)=>(
                    <div  key={i} className='relative cursor-pointer rounded-md h-[325px] laptop:h-[245px] laptop:w-[250px] border border-gray-200 hover:border-2 hover:border-blue-300 overflow-hidden ' >
                        <video onEnded={()=>setIsPlaying(null)} poster={el.image} ref={(ref) => (videoRefs.current[i] = ref)} onClick={() => handlePlayPause(i)}  className='object-cover  ' src={el.video} alt='' ></video>
                        <button onClick={() => handlePlayPause(i)} className='transition ease-in-out duration-300 absolute bottom-3 flex items-center gap-x-[5px] left-3 bg-gray-600 bg-opacity-40 hover:bg-gray-800 hover:bg-opacity-60 tracking-wider  text-white rounded-lg text-[15px] font-semibold py-[7px] px-[15px]' > 
                            <span className='text-[16px] ' >{isPlaying==i?<IoPauseSharp />:<IoPlaySharp />}</span> {el.name}
                        </button>
                    </div>
                ))
            }
        </div>

        <div className='hidden tablet:flex justify-center mt-[30px] ' >
            <TwoButtons
                btn1name={'Create Your Custom AI Avatar'}
                btn1link={typeform}
            />
        </div>
    
    
    
    </div>
  )
}


let avatars = [
    {
        video : '/avatars/lily.mp4',
        image : '/avatars/lily.png',
        name : 'Lily'
    },
    {
        video : '/avatars/parul.mp4',
        image : '/avatars/parul.png',
        name : 'Parul'
    },
    {
        video : '/avatars/divya.mp4',
        image : '/avatars/divya.png',
        name : 'Divya'
    },
    {
        video : '/avatars/andrew.mp4',
        image : '/avatars/andrew.png',
        name : 'Andrew'
    },
    {
        video : '/avatars/rehan.mp4',
        image : '/avatars/rehan.png',
        name : 'Rehan'
    },
    {
        video : '/avatars/annie.mp4',
        image : '/avatars/annie.png',
        name : 'Annie'
    },
    {
        video : '/avatars/mia.mp4',
        image : '/avatars/mia.png',
        name : 'Mia'
    },
    {
        video : '/avatars/ria.mp4',
        image : '/avatars/ria.png',
        name : 'Ria'
    },
    {
        video : '/avatars/lilly2.mp4',
        image : '/avatars/lily2.png',
        name : 'Lily'
    },
    {
        video : '/avatars/sophie.mp4',
        image : '/avatars/sophie.png',
        name : 'Sophie'
    },
    {
        video : '/avatars/sahil.mp4',
        image : '/avatars/sahil.png',
        name : 'Sahil'
    },
    {
        video : '/avatars/phillip.mp4',
        image : '/avatars/phillip.png',
        name : 'Phillip'
    },
]





