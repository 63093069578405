import React, { useState,useEffect,useRef } from 'react'
import { FiUser } from "react-icons/fi";
import styles from './aboutus.module.css'
import axios from 'axios';
export const Newsletter = () => {

  const [isLoading,setIsLoading] = useState(false)

  const [email,setEmail] = useState('')
  const [resData,setResData] = useState({
    successfull : false,
    error : false,
    empty : false,
  })
  const timeoutRef = useRef(null);


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const handleClick = () => {

    if (!emailRegex.test(email)) {
      setResData({
        successfull: false,
        error: false,
        empty: true,
      });
      timeoutRef.current = setTimeout(() => {
        setResData({
          successfull: false,
          error: false,
          empty: false,
        });
      }, 5000);
    } 
    else{
      setIsLoading(true)
      let data = new FormData();
      data.append('Email', email);
    
      let config = {
        method: 'post',
        url: 'https://script.google.com/macros/s/AKfycbxgVl-xAM5bbycdfsGrH7ECnWiv_27FkLpHVqt4Cw14nyV8ZzOw4jvVoSzr2wCgEkpp0g/exec',
        headers: { 
          'Content-Type': 'multipart/form-data'
        },
        data : data
      };
    
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsLoading(false)
        setResData({
          successfull : true,
          error : false,
          empty : false,
        })
        
        setEmail('')
        timeoutRef.current = setTimeout(() => {
          setResData({
            successfull: false,
            error: false,
            empty: false,
          });
        }, 5000);

      })
      .catch((error) => {
        console.log(error);
        setResData({
          successfull : false,
          error : true,
          empty : false,
        })
        setEmail('')
        timeoutRef.current = setTimeout(() => {
          setResData({
            successfull: false,
            error: false,
            empty: false,
          });
        }, 5000);
      });


      
    }


  }


  useEffect(() => {
    // Clear the timeout if the component unmounts or if email changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className={`mt-[50px] rounded-xl bg-white bg-opacity-30 w-[90%] laptop:w-[80%] mx-auto p-[20px] font-redHat `} >

<h2 className='laptop:text-[35px] text-off-black font-[600] text-center ' >Stay Connected</h2>
        <p className="text-center text-gray-500 laptop:text-[16px] w-[95%] laptop:w-[80%] mx-auto mb-[20px] ">Stay connected with Personate for the latest updates and innovations. Sign up for our newsletter.</p>
        
        <div className='flex items-center mt-[10px] gap-1 laptop:w-[40%] mx-auto  ' >
            <div className={styles.inputContainer}>
                <span className={styles.inputIcon}><FiUser /></span>
                <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" className={styles.inputField} id="username" placeholder="Enter your email" />
            </div>
            <button onClick={handleClick} disabled={isLoading} className={`text-[14px] items-center justify-center py-[5px]  ${isLoading?'bg-gray-100':'bg-off-black'} text-white rounded-md px-[10px]  `} >Subscribe</button>
        </div>
        {
        resData.successfull && (
          <p className={`text-center text-green-500 font-[500] text-[14px] `} >Your are now subscribed to Personate newsletter</p>
        )
       }
       {
        resData.error && <p className={`text-center text-red-500 font-[500] text-[14px] `} >Ohoo! Something went wrong</p>
       }
        {
          resData.empty && (<p className={`text-center text-red-500 font-[500] text-[14px] `} >Please enter your email</p>)
        }
    </div>
  )
}
