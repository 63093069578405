import React from 'react'
import styles from './universal.module.css'
import { Link } from 'react-router-dom'

export const BottomBanner1 = ({heading,para,para2,btn1name,btn1link,btn2name,btn2link,icon1,icon2,icon1size,icon2size,image}) => {
  // console.log(btn1name?.length,'sdfsdf')
  return (
    <div className={`${styles.bottomBanner1Bg} w-[100%] relative overflow-hidden font-redHat `} >
      <div className='flex laptop:flex-row flex-col gap-0 laptop:gap-[50px] w-[90%] laptop:h-[450px] mx-auto laptop:ml-auto pt-[30px] laptop:pt-0 ' >
        <div className=' w-[100%] laptop:w-[40%] my-auto ' >
          <div>
              <h2 className='text-white font-[600] text-[24px] laptop:text-[34px] ' >{heading}</h2>
              <p className='text-white text-[13px] laptop:text-[14px] font-[400] mb-[5px] tracking-wide ' >{para}</p>
              <p className='text-white text-[13px] laptop:text-[14px] font-[600] mb-[20px] tracking-wider ' >{para2}</p>
              
              <div className='flex gap-x-[10px] laptop:mb-0 ' >
                  <div className={`${btn1name?'block':'hidden'}`} >
                      <Link to={btn1link} target="_blank" >
                          <button className={`transition ease-in-out duration-300 text-[14px] tracking-wide rounded-lg font-[600]  px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] bg-white text-black bg-transparent flex justify-center items-center  `} >{btn1name} <span className={`ml-[3px] ${icon1size} `} >{icon1}</span> </button>
                      </Link>
                  </div>
                  <div className={`${btn2name?'block':'hidden'}`}>
                      <Link to={btn2link} target="_blank">
                          <button className={`transition ease-in-out duration-300 text-[14px] tracking-wide rounded-lg font-semibold px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] text-white bg-primary hover:bg-dark-primary flex justify-center items-center  `} >{btn2name} <span className={`ml-[3px] ${icon2size} `} >{icon2}</span> </button>
                      </Link>
                  </div>
              </div>
          </div>
        </div>
      
      
        <div className=' w-[100%] laptop:w-[60%] transform translate-y-[30px] laptop:translate-x-[170px] laptop:translate-y-[70px]   '>
          <img src={'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/all_features_bottom_banner_personate_ai.png'} alt={heading} />
        </div>
      </div>
    </div>
  )
}
