import React, { useEffect } from 'react'
import { privacyPolicyData } from '../script/privacyPolicyData'

const PrivacyPolicy = () => {

    useEffect(()=>{
        document.title = 'Privacy and Policy | Personate '
    },[])
  return (
    <div className='w-[90%] tablet:w-[80%] laptop:w-[65%] mx-auto my-[100px] font-redHat' >
        <h1 className='text-center text-off-black text-[40px] mb-[30px] font-semibold tracking-wider' >Privacy Policy</h1>
        <div>
            {
                privacyPolicyData.map((el,i)=>(
                    <div key={i} className='mb-[20px] text-[#6C6C73] tracking-wide text-[16px] leading-[28px] font-[400]'>
                        <p>{el.title}</p>
                        <p>{el.desc}</p>
                    </div>
                ))
            }
        </div>
      
    </div>
  )
}

export default PrivacyPolicy
