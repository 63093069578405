import React, { useState } from 'react'
import { introductionUIForYoutubeVideo } from '../../script/backgroundUI'

import { Heading } from './Heading';
export const Step2 = () => {
    const [index,setIndex] = useState(0)
  return (
    <div className='mb-[70px]' >
        <Heading title={'Customize and Personalize'} desc={'Use AI to create unique and engaging video experiences, personalizing your videos to connect more deeply with your audience'} />

        <div className='block laptop:flex gap-[3%] mt-[40px] ' >
            
            {/* LEFT DIV */}
            <div className='w-[100%] laptop:w-[30%] ' >
                {
                    custom.map((el,i)=>(
                        <div onClick={()=>setIndex(i)} key={i} className={`cursor-pointer rounded-lg px-[10px] ${index==i?'bg-[#F5F8FF] border py-[10px] mb-[18px] ':'mb-[40px]'} `} >
                            <h3 className={`text-[18px] font-[700] tracking-wider mb-[10px] ${index==i?'text-blue-500':'text-gray-400 '} `} >{el.title}</h3>
                            <p className={`${index==i?'block':'hidden'} text-gray-500 text-[15px] `} >{el.desc}</p>
                        </div>
                    ))
                }
            </div>


            {/* RIGHT DIV */}
            <div style={introductionUIForYoutubeVideo} className='w-[100%] laptop:w-[70%] rounded-xl p-[20px] laptop:p-[50px] ' >
                <div className=' mx-auto ' >
                    <img className='w-[100%] h-[100%] object-cover rounded-xl ' src={custom[index].image} />
                </div>
            </div>
        </div>
    </div>
  )
}


const custom = [
    {
      title: "AI Avatar Library",
      desc: "Access a diverse array of AI Avatars ready to represent your narrative",
      image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/library+avatars+.png'
    },
    {
      title: "Custom AI Avatars",
      desc:"Tailor Avatars to reflect your brand’s unique identity",
      image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/custom+avatars+.png'
    },
    {
      title:"AI Voices",
      desc: "Use hyper-realistic AI voices for consistent audio in all your videos",
      image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/Natural+voices.png'
    },
    {
      title:"AI Cloned Voices",
      desc: "Exactly replicate specific voices for a uniform brand sound across all platforms",
      image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/cloned+voices.png'
    }
  ]