import React from 'react'
import styles from './aboutus.module.css'
const Introduction = () =>{
    return(
        <div  >
        <div className='w-[90%] laptop:w-[80%] mx-auto font-redHat mb-[100px] ' >
            <h1 className='text-off-black text-[26px] laptop:text-[32px] font-[500] mb-[50px] text-center' >Personate: Advancing AI to Transform Storytelling Worldwide</h1>
            {/* <p className='text-dark-gray mb-[50px] text-center text-[16px] ' ><i>Born from a bold vision, Personate was founded in 2021 by trailblazers from ISRO, IIT Roorkee, and BITS Pilani.</i></p> */}





            {/* OUR REACH */}
            {/* <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500]' >Our Vision and Mission</h2>
            <p className='text-dark-gray mb-[50px] text-[16px] ' >In January 2021, amidst the serene heights of the Himalayas, two visionary brothers founded our research center. Surrounded by breathtaking beauty, we embarked on a mission to revolutionize communication with generative AI</p> */}


            {/* OUR ETHICAL */}
            {/* <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500]' >Born from a Bold Vision</h2>
            <p className='text-dark-gray mb-[50px] text-[16px] ' >Personate was founded in 2021 by trailblazers from ISRO, IIT Roorkee, and BITS Pilani. Our commitment to innovation and excellence has driven us to become a global leader in AI-driven video creation</p> */}

            {/* OUR JOURNEY */}
            {/* <div className='bg-white bg-opacity-30 font-redHat rounded-xl p-[20px]  ' >
                <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] mb-[10px] ' >Our Journey</h2>
                {
                    data1.map((el,i)=>(
                        <div key={i} className='mb-[30px] ' >
                            <h3 className='text-off-black text-[22px] font-[500] ' >{el.heading}</h3>
                            <h4 className='text-dark-gray text-[19px] font-[500] mb-1 ' >{el.subHeading}</h4>
                            {
                                el.para.map((para,index)=>(
                                    <p key={index} className='text-off-black text-[16px] font-[400] ' >{para}</p>
                                ))
                            }
                        </div>
                    ))
                }
            </div> */}


            {/* OUR REACH */}
            <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] mt-[50px] ' >Our Reach</h2>
            <p className='text-dark-gray mb-[50px] text-[16px] ' >With a suite of over seven cutting-edge software solutions, we’ve impacted more than a million users worldwide. We’ve established ourselves as a global leader in AI-driven video creation, making sophisticated video production accessible to everyone. </p>


            {/* OUR ETHICAL */}
            <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500]' >Our Ethical Compass</h2>
            <p className='text-dark-gray mb-[50px] text-[16px] ' >In an era where technology shapes futures, Personate leads with ethical innovation at its heart. We pledge to advance the AI frontier responsibly, ensuring our platform serves as a force for good.</p>


            

            {/* OUR FOUNDERS */}
            <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] text-center mb-[30px] '>Meet Our Visionary Leaders</h2>
            <div className='flex flex-col laptop:flex-row justify-center gap-8 laptop:w-[80%] mx-auto ' >
                {
                    CEOData.map((el,i)=>(
                        <div className={`bg-white bg-opacity-30 rounded-xl p-[20px] border  `} >
                            <div className='flex items-center gap-2' >
                                <div className='w-[100px] h-[100px] laptop:w-[150px] laptop:h-[150px] ' >
                                    <img className='w-[100%] h-[100%] object-cover ' src={el.image} alt="" />
                                </div>
                                <div>
                                    <h2 className='text-[20px] laptop:text-[26px] font-[500] tracking-wide ' >{el.name}</h2>
                                    <p className='text-dark-gray text-[15px] font-[400] ' ><i>{el.designation}</i></p>
                                </div>
                            </div>
                            <p className='mt-2 text-[16px] text-dark-gray font-[400] ' >{el.desc}</p>
                        </div>
                    ))
                }
            </div>
        
        </div>
        </div>
    )
} 

export default Introduction

let CEOData = [
    {
        name :'Akshay Sharma',
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/1.png',
        designation : 'Co-Founder, CEO',
        desc : 'Akshay’s visionary leadership keeps us at the cutting edge of AI innovation, driving our mission forward every day.'
    },
    {
        name :'Rishab Sharma',
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/2.png',
        designation : 'Co-Founder, CTO',
        desc : 'Rishab’s technical expertise powers our groundbreaking solutions, making sophisticated video production accessible for everyone.'
    },
]

let data1 = [
    {
        heading : 'Achieving Global Recognition',
        subHeading : 'October 2021:',
        para : ['🏆 Awarded Top 500 Global Start-Up by Slingshot. This recognition fueled our ambition and drive to innovate.']
    },
    {
        heading : 'Launching a Revolutionary Platform',
        subHeading : 'April 2022:',
        para : ['🚀 Unveiled the Digital Human Video Chatbot Builder Platform. Businesses worldwide now create interactive, AI-powered human-like chatbots, transforming customer engagement.']
    },
    {
        heading : 'Expanding Through Strategic Partnerships',
        subHeading : 'October 2022:',
        para : ['🤝 Partnered with industry giants Nvidia, AWS, and the Wadhwani Foundation. These collaborations provided invaluable resources, expert guidance, and support.']
    },
    {
        heading : 'Reaching New Heights',
        subHeading : 'December 2022:',
        para : ['📈 Over a hundred companies adopted our platform, solidifying our position as industry leaders in AI-driven communication.']
    },
    {
        heading : 'Recognition and Innovation',
        subHeading : 'January 2023:',
        para : ['🎉 Finalists in the National Start-Up Awards 2022.','📹 Launched Personate Studio: A game-changing platform for creating personalized, lifelike videos.']
    },
    {
        heading : 'Widespread Impact',
        subHeading : 'April 2023:',
        para : [`🌍 Personate AI videos captivated over 100 million viewers across TV and digital platforms, showcasing our solutions' immense popularity and impact.`]
    },
]