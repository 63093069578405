import { createContext, useState } from "react";

export const MyContext = createContext()

export const MyContextProvider = ({children})=>{
    const [isSuccessFull,setIsSuccfull] = useState(false)
    const [isError,setIsError] = useState(false)
    const [isMenuOpen,setIsMenuOpen] = useState(false)


    return(
        <MyContext.Provider value={{isError,setIsError,isMenuOpen,setIsMenuOpen}} >
            {children}
        </MyContext.Provider>
    )
}


