import React, {useRef, useState } from 'react'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'
import {  FaPause, FaPlay } from 'react-icons/fa';
import { GoArrowRight } from 'react-icons/go';
import { Link } from 'react-router-dom';
const Avatars = ({data}) => {
    const videoRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

      
    const [isPlaying, setIsPlaying] = useState([false, false, false, false,false,false]);
    
    const handlePlayClick = (index) => {
      console.log("----click")
        const newIsPlaying = { vid1: false, vid2: false, vid3: false, vid4: false,vid5: false, vid6: false, };
      
        for (let i = 1; i <= 6; i++) {
          const isPlayingKey = `vid${i}`;
          if (i === index) {
            const videoRef = videoRefs[i - 1].current;
            if (videoRef.paused) {
              videoRef.currentTime = 0;
              videoRef.play();
              newIsPlaying[isPlayingKey] = true;
            } else {
              videoRef.pause();
              newIsPlaying[isPlayingKey] = false;
            }
          } else {
            // Pause other videos
            videoRefs[i - 1].current.pause();
          }
        }
      
        setIsPlaying(newIsPlaying);
    };
      
      
    
    const handleOnEnded = () => {
        setIsPlaying([false, false, false, false,false,false]);
    };
      


  return (
      <div className='font-redHat  mb-[50px] ' >
        <HeadingAndSubheading heading={'Key Features of Personate That Our Customers Love'} />
        {/* SEE ALL AVATARS DIV */}
        <div className='w-[90%] laptop:w-[80%] block tablet:flex m-auto bg-white px-0 laptop:px-[20px] gap-x-[5%] '>
            
            {/* SEE ALL AVATARS LEFT DIV */}
            <div className='w-[100%] tablet:w-[40%] my-auto'>

              <div className=' ' >
                <h2 className='text-[20px] laptop:text-[30px] font-[500] text-off-black ' >Lifelike AI Avatars</h2>
                <p className='text-[15px] laptop:text-[16px] font-[400] text-dark-gray tracking-wide mt-[10px] ' >Select from an extensive library of ready-made AI Avatars or create a custom Avatar tailored to reflect your brand's essence
                </p>
                {/* <Link to={'features/ai-avatars'} ><button className='flex items-center text-off-black gap-1 text-[15px] border rounded-lg py-[7px] px-[12px] mt-[15px] ' >Watch Avatars <span><GoArrowRight /></span></button></Link> */}
              </div>
            
            </div>


            {/* SEE ALL AVATARS RIGHT DIV */}
            <div className='w-[100%] tablet:w-[60%] grid grid-cols-2 laptop:grid-cols-3 tablet:grid-cols-2 gap-x-6 gap-y-8 tablet:gap-x-8 justify-center mt-[20px] laptop:mt-0 '>
                
                
                {data.map((avatar, index) => (
                    <div key={index} onClick={() => handlePlayClick(index + 1)} className={`cursor-pointer relative h-[135px] tablet:h-[95px] laptop:h-[140px] desktop:h-[150px] overflow-hidden rounded-lg border`}>
                      <span
                          className={`block absolute bottom-1 left-1 z-30 cursor-pointer rounded-md p-[6px] text-white bg-gray-600 bg-opacity-40 hover:bg-gray-800 hover:bg-opacity-60`}
                      >
                        {isPlaying[`vid${index + 1}`] ? <FaPause /> : <FaPlay />}
                      </span>
                      {/* <img src={} alt="" /> */}
                      <video
                          onEnded={handleOnEnded}
                          ref={videoRefs[index]}
                          // poster={avatar.poster}
                          className={`h-[100%] w-[100%] object-cover pl-[25px] `}
                          src={avatar.video}
                          preload="auto"
                      ></video>
                    </div>
                    
                ))}
            </div>

            
        </div>

    </div>
  )
}

export default Avatars
















