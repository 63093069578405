import React from 'react'
import { Link } from 'react-router-dom'

export const Page404 = () => {
  return (
    <div className='font-redHat px-[20px] ' > 
        <h1 className='text-off-black text-[26px] text-center font-[500] mt-[50px] laptop:mt-[100px] ' >Oops! Page Not Found 😕 </h1>
        <p className='w-[90%] laptop:w-[60%] mx-auto text-dark-gray text-[14px] text-center  mt-[10px] '>The URL you requested was not found on this website. But don't worry, we've got you covered! Click the button below to return to the Personate homepage and continue exploring our features and services.
        </p>
        <Link to='/' ><button className='bg-primary text-white py-[5px] px-[10px] rounded-lg block mt-[20px] mx-auto  ' >Back to home</button></Link>
        <img className='my-[30px] w-[200px] mx-auto  ' src="https://d1yere00agyg9m.cloudfront.net/assets/illustrations-min/3d-ghost.png" alt="" />
    </div>
  )
}
