import React, { useRef, useState } from 'react'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'
import { TwoButtons } from '../../GlobalComponents/TwoButtons'
import { register, typeform } from '../../script/buttonLinks'
import { HiSpeakerWave, HiSpeakerXMark } from 'react-icons/hi2'

export const MultilingualVoices = () => {
  const [isMute,setIsMute] = useState(true);
  const [isLoop,setIsLoop] = useState(true);
  const videoRef = useRef(null)
  const timerRef = useRef(null)

  const handleMute = () => {
    setIsMute(!isMute);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.muted = !isMute;
    }
  }; 

  if (videoRef.current) {
      videoRef.current.play();
  }  
  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto mb-[50px] font-redHat' >
        <HeadingAndSubheading
            heading={'Multilingual AI Voice That Facilitates Global Dialogue'}
            subHeading={'Empower your projects with AI Voices capable of seamless language switching, making your content universally understandable and engaging. Our featured demo illustrates a voice transitioning smoothly between languages, underscoring our commitment to breaking communication barriers'}
        />
        <div className='w-[100%] mx-auto rounded-2xl overflow-hidden mb-[15px] relative group cursor-pointer shadow-xl ' >
          <video onClick={handleMute} ref={videoRef} muted={isMute} loop className='w-[100%] h-[100%] object-contain ' src="https://personate-data-v2.s3.amazonaws.com/website+data+/asset/global_multilingual_voices_personate_ai.mp4"></video>
          
          {/* HANDLE MUTE */}
          <div className='absolute left-[5px] top-[5px] laptop:left-[10px] laptop:top-[10px] h-[35px] w-[35px] laptop:h-[50px] laptop:w-[50px] group-hover:justify-start group-hover:pl-[5px] gap-x-[6px] group-hover:w-[80px] group-hover:laptop:w-[100px] transition-width duration-300 rounded-full bg-gray-400 bg-opacity-50 flex justify-center items-center' >
            <div className='flex justify-center overflow-hidden items-center h-[25px] w-[25px] laptop:h-[40px] laptop:w-[40px] text-[17px] laptop:text-[20px] bg-white rounded-full ' >{isMute?<HiSpeakerXMark />:<HiSpeakerWave />}
            </div>
            <div className='hidden group-hover:block text-white font-[600] text-[15px] transition ease-in-out duration-300  ' >
                {isMute?"Mute":"Listen"}
            </div>
            </div>
        </div>
        <div className='flex justify-center ' >
        <TwoButtons
            btn1name={'Hear the Difference'}
            // btn2name={'lead to create account  '}
            btn1link={register}
            // btn2link={typeform}
        />
        </div>
    </div>
  )
}
