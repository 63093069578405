import React from 'react'
import { Link } from 'react-router-dom'



export const TwoButtons = ({ isExternal=true,icon1,icon2,icon1size,icon2size, btn1name,btn2name,btn1link='https://app.personate.ai/register',btn2link='https://1i4emckhg1l.typeform.com/to/TPYwKpUc?typeform-source=personate.ai'}) => {
  return (
    <div className='flex gap-x-[10px]'>
        
        {
            isExternal?
            <div>
                <Link rel="noopener" to={btn1link} target="_blank" >
                    <button className={`transition ${btn1name?'block':'hidden'}  ease-in-out duration-300 text-[14px] tracking-wide rounded-lg font-semibold px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] text-white bg-primary hover:bg-dark-primary flex justify-center items-center  `} >{btn1name} <span className={`ml-[3px] ${icon1size} `} >{icon1}</span> </button>
                </Link>
            </div>:<div>
                <Link rel="noopener" to={btn1link}>
                    <button className={`transition ${btn1name?'block':'hidden'} ease-in-out duration-300 text-[14px] tracking-wide rounded-lg font-semibold px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] text-white bg-primary hover:bg-dark-primary flex justify-center items-center  `} >{btn1name} <span className={`ml-[3px] ${icon1size} `} >{icon1}</span> </button>
                </Link>
            </div>
        }
        <div>
            <Link rel="noopener" to={btn2link} target="_blank" >
                <button className={`${btn2name?'block':'hidden'} transition ease-in-out duration-300 flex justify-center items-center text-[14px] tracking-wide rounded-lg font-[500] px-[17px] laptop:px-[20px] py-[7px] laptop:py-[9px] border border-primary hover:bg-slate-50 hover:text-black  `} >{btn2name}<span className={`ml-[3px] ${icon2size} `} >{icon2}</span></button>
            </Link>
        </div>
    </div>
  )
}
