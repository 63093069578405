import React from 'react'
import { trustedClientsData } from '../../script/homepageData'
import styled, { keyframes, css } from "styled-components";

{/* <HeadingAndSubheading heading={'Top Brands That Transformed Their Video Content Strategy Using Personate'}  /> */}
const ClientsCarousels = () => {
  
  return (
    <AppContainer >
      <Wrapper>
      <h2 className={`text-[26px] laptop:text-[30px] font-redHat w-[90%] mx-auto font-[600] text-center mb-[9px] text-off-black`} >Top Brands That Transformed Their Video Content Strategy Using Personate</h2>
        <Marquee>
          <MarqueeGroup>
            {trustedClientsData.map((el,i) => (
              <div key={i} className={`flex  items-center h-[70px] w-[100px] laptop:h-[100px] laptop:w-[160px] `}>
              <img className={` 
                    block
                    m-auto
                    ${el.logoName=='nvidia'?'scale-125':''}
                    ${el.logoName=='angle one'?'scale-125':''}
                    ${el.logoName=='volkswagen'?'h-[40%] w-[70%]':''}
                    ${el.logoName=='university of cologne'?'h-[40%] w-[70%]':''}
                    ${el.logoName=='foto owl'?'h-[40%] w-[70%]':''}
                    ${el.logoName=='zee'?'h-[85%] w-[85%]':''}
                    ${el.logoName=='aajtak'?'h-[85%] w-[85%]':''}
                    ${el.logoName=='company house finland'?'h-[65%]':''} h-[100%] w-[100%] object-contain `}
                    src={el.logoLink} 
                    alt={el.logoName}
                    />
            </div>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {trustedClientsData.map((el,i) => (
              <div key={i} className={`flex  items-center h-[70px] w-[100px] laptop:h-[100px] laptop:w-[160px] `}>
                <img className={` 
                      block
                      m-auto
                      ${el.logoName=='nvidia'?'scale-125':''}
                      ${el.logoName=='angle one'?'scale-125':''}
                      ${el.logoName=='volkswagen'?'h-[40%] w-[70%]':''}
                      ${el.logoName=='university of cologne'?'h-[40%] w-[70%]':''}
                      ${el.logoName=='foto owl'?'h-[40%] w-[70%]':''}
                      ${el.logoName=='zee'?'h-[85%] w-[85%]':''}
                      ${el.logoName=='aajtak'?'h-[85%] w-[85%]':''}
                      ${el.logoName=='company house finland'?'h-[65%]':''} h-[100%] w-[100%] object-contain `}
                      src={el.logoLink} 
                      alt={el.logoName}
                      />
              </div>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
  )
}

export default ClientsCarousels

const AppContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin : 50px;

`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;


