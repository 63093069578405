import React from 'react'
import styles from './aboutus.module.css'
const Team = () => {
  return (
    <div className='mb-[50px] w-[90%] laptop:w-[80%] mx-auto font-redHat' >
        
        <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] text-center' >The Team Behind the Magic</h2>
        
        <div className='grid grid-cols-1 laptop:grid-cols-2 gap-[70px] mt-[30px] ' >
            {
                ourTeam.map((el,i)=>(
                    <div key={i} className=' ' >
                        <div className='flex items-center gap-x-2 laptop:gap-x-[15px] ' >
                            <div className='rounded-full overflow-hidden w-[80px] h-[80px] ' >
                                <img className='h-[100%] w-[100%] object-cover ' src={el.image} alt={el.name} />
                            </div>
                            <div>
                                <h3 className='text-off-black text-[20px] laptop:text-[24px] font-[500] ' >{el.name}</h3>
                                <h4 className='text-dark-gray font-[400] text-[16px] ' >{el.designation}</h4>
                            </div>
                        </div>
                        <p className=' mt-[5px] font-[500] text-dark-gray text-[15px] ' >{el.desc}</p>
                    </div>
                ))
            }
        </div>

        
    </div>
  )
}

export default Team

const ourTeam = [
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/11.png',
        name : 'Jai Aravind',
        designation : 'Founding Member',
        desc : `Jai embodies the spirit of entrepreneurship as a founding member. His vision and leadership have been instrumental in shaping our journey from inception to success, laying the foundation for our innovative endeavors.`
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/6.png',
        name : 'Niharika Kapoor',
        designation : 'Impact Communications Lead',
        desc : `Niharika is responsible for developing and implementing communication strategies that enhance impact, drive engagement, and effectively convey the organization’s mission and values.`
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/5.png',
        name : 'Akshat Kapil',
        designation : 'Sr. Business Operations',
        desc : 'Akshat is deeply committed to driving operational excellence and solving real-world business challenges. He plays a pivotal role in fostering innovative improvements across our operational processes and strategies.'
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/4.png',
        name : 'Madhur Arora',
        designation : 'Sr Data Scientist',
        desc : 'Madhur is skilled at building custom AI models and deep learning architectures. An alumnus from IIT Madras, he leads data science research at Personate.'
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/9.png',
        name : 'Saurabh Kumar',
        designation : 'Sr Software Developer',
        desc : `Saurabh is deeply dedicated to crafting user-centric solutions that address tangible challenges. He serves as the catalyst for pioneering advancements within our platform's functionalities.`
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/8.png',
        name : 'Sarim Khan',
        designation : 'Software Developer',
        desc : `Sarim is driven by a passion for developing user-centric interfaces that tackle real-world issues. With extensive experience in software development, he plays a pivotal role in shaping our innovative user experiences.`
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/10.png',
        name : 'Sharmilee Adgaonkar',
        designation : 'Product Management',
        desc : `Sharmilee is fueled by her enthusiasm for steering product strategies that prioritize user needs and solve practical problems. With a wealth of experience in product management, she serves as a key architect behind the evolution of our product offerings.`
    },
    
    
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/7.png',
        name : 'Rishab Bisht',
        designation : 'Business Operations',
        desc : 'Rishab is passionately focused on optimizing business operations and addressing practical challenges. With a wealth of experience in business operations, he is instrumental in driving efficiency and enhancing the effectiveness of our operational strategies.'
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/team_photos/3.png',
        name : 'Mekhala Joshi',
        designation : 'Content and Marketing Specialist',
        desc : 'Mekhala is known for her expertise in creating engaging content and developing effective marketing strategies that boost brand presence and drive audience engagement across various platforms.'
    },
    
]

