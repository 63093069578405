export const introductionUIForNormalVideo = {
    backgroundImage: 'radial-gradient(circle at top right, rgb(255,255,255) 0%, rgb(255,255,255) 48%,rgb(224,247,250) 48%, rgb(224,247,250) 53%,rgb(224,247,250) 53%, rgb(224,247,250) 56%,rgb(224,247,250) 56%, rgb(224,247,250) 69%,rgb(179,229,252) 69%, rgb(179,229,252) 100%)'
}


export const introductionUIForYoutubeVideo = {
    backgroundImage: "radial-gradient(circle at 85% 1%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 96%,transparent 96%, transparent 100%),radial-gradient(circle at 60% 90%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 20%,transparent 20%, transparent 100%),radial-gradient(circle at 79% 7%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 78%,transparent 78%, transparent 100%),radial-gradient(circle at 55% 65%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 52%,transparent 52%, transparent 100%),linear-gradient(202deg, rgb(187,222,251),rgb(255,255,255))"
}


export const UIforSavings = {
    backgroundImage: 'radial-gradient(circle at 85% 1%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 96%,transparent 96%, transparent 100%),radial-gradient(circle at 14% 15%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 1%,transparent 1%, transparent 100%),radial-gradient(circle at 60% 90%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 20%,transparent 20%, transparent 100%),radial-gradient(circle at 79% 7%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 78%,transparent 78%, transparent 100%),radial-gradient(circle at 55% 65%, rgba(3,50,255, 0.05) 0%, rgba(3,50,255, 0.05) 52%,transparent 52%, transparent 100%),linear-gradient(378deg, rgb(100,181,246),rgb(255,255,255))'
}

export const UIforAboutUs = {
    // backgroundImage: "radial-gradient(circle at 25% 54%, rgba(18, 18, 18,0.06) 0%, rgba(18, 18, 18,0.06) 22%,transparent 22%, transparent 100%),radial-gradient(circle at 67% 98%, rgba(223, 223, 223,0.06) 0%, rgba(223, 223, 223,0.06) 52%,transparent 52%, transparent 100%),radial-gradient(circle at 7% 92%, rgba(169, 169, 169,0.06) 0%, rgba(169, 169, 169,0.06) 52%,transparent 52%, transparent 100%),radial-gradient(circle at 46% 37%, rgba(187, 187, 187,0.06) 0%, rgba(187, 187, 187,0.06) 47%,transparent 47%, transparent 100%),radial-gradient(circle at 38% 35%, rgba(57, 57, 57,0.06) 0%, rgba(57, 57, 57,0.06) 99%,transparent 99%, transparent 100%),radial-gradient(circle at 46% 86%, rgba(100, 100, 100,0.06) 0%, rgba(100, 100, 100,0.06) 60%,transparent 60%, transparent 100%),linear-gradient(90deg, rgb(79,195,247),rgb(158,61,242))"
}