import React from 'react'
import { Route, Routes } from 'react-router-dom'

import HomePage from '../pages/HomePage'
import TermsAndServices from '../pages/TermsAndServices'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import FeaturePage from '../pages/FeaturePage'
import FeaturesAvatarPage from '../pages/FeaturesAvatarPage'
import FeaturesLanguagePage from '../pages/FeaturesLanguagePage'
import Pricing from '../pages/Pricing'
import Ethics from '../pages/Ethics'
import AboutUs from '../pages/AboutUs'
import Blogs from '../pages/Blogs'
import BestAIGeneratorBlogPage from '../pages/BlogPages/BestAIGeneratorBlogPage'
import UsingAIVideosBlogPage from '../pages/BlogPages/UsingAIVideosBlogPage'
import HumanCentricBlogPage from '../pages/BlogPages/HumanCentricBlogPage'
import AItoDevelopBlogPage from '../pages/BlogPages/AItoDevelopBlogPage'
import WhyDoComapany from '../pages/BlogPages/WhyDoCompany'
import { LanguageTranslation } from '../pages/LanguageTranslation'

import { InstantAiVideo } from '../pages/InstantAiVideo'
import { Page404 } from '../pages/Page404'
import { APIforScale } from '../pages/APIforScale'
import ModerationPrivacyPolicy from '../pages/ModerationPrivacyPolicy'


const MainRoutes = () => {

  return (
    <Routes>
        <Route path='/' element={<HomePage/>} ></Route>
        <Route path='/terms-of-service' element={<TermsAndServices/>}></Route>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
        <Route path='/moderation-policy' element={<ModerationPrivacyPolicy/>}></Route>

        {/* FEATURES TAB */}
        <Route path='/features' element={<FeaturePage/>}></Route>
        <Route path='/features/ai-avatars' element={<FeaturesAvatarPage/>}></Route>
        <Route path='features/ai-voices' element={<FeaturesLanguagePage/>}></Route>
        <Route path='/features/instant-ai-video' element={<InstantAiVideo/>}></Route>
        <Route path='/features/ai-video-translation' element={<LanguageTranslation/>}></Route>
        {/* <Route path='/features/api' element={<APIforScale/>}></Route> */}

        {/* <Route path='/pricing' element={<Pricing/>}></Route> */}
        
        
        {/* FEATURES PAGES */}
        {/* <Route path='/features/templates' element={<VideoTemplates/>}></Route>
        <Route path='/feature/custom-avatars' element={<FeatureCustomAvatars/>}></Route>
        <Route path='/features/script-generation' element={<ScriptGeneration/>}></Route>
        <Route path='/features/pdf-to-video' element={<PDFtoVideo/>}></Route>
        <Route path='/features/excel-to-video' element={<ExceltoVideo/>}></Route>
        <Route path='/features/text-to-video' element={<TexttoVideo/>}></Route>
        <Route path='/features/url-to-video' element={<URLtoVideo/>}></Route>
        <Route path='/features/powerpoint-to-video' element={<PPTtoVideo/>}></Route> */}
        {/* USE CASES PAGES */}
        {/* <Route path='/learning-and-development' element={<LearningAndDevelopment/>}></Route>
        <Route path='/support-centre' element={<SupportCentre/>}></Route>
        <Route path='/blog-to-videos' element={<BlogToVideos/>}></Route>
        <Route path='/personalised-video' element={<PersonalisedVideo/>}></Route>
        <Route path='/news-and-entertainment' element={<NewsAndEntertainment/>}></Route>
        <Route path='/product-explainer' element={<ProductExplainer/>}></Route>
        <Route path='/creator-studio' element={<CreatorStudio/>}></Route>
        <Route path='/product-team' element={<ProductTeam/>}></Route>
        <Route path='/video-translation' element={<VideoTranslation/>}></Route>
        <Route path='/marketing-videos' element={<MarketingVideos/>}></Route> */}


        {/* COMPANY PAGES */}
        <Route path='/ethics' element={<Ethics/>}></Route>
        <Route path='/about' element={<AboutUs/>}></Route>
        

        {/* BLOG PAGES */}
        {/* <Route path='/blog' element={<Blogs/>}></Route>
        <Route path='/blog/customer-success-stories' element={<UsingAIVideosBlogPage/>}></Route>
        <Route path='/blog/tutorials' element={<BestAIGeneratorBlogPage/>}></Route>
        <Route path='/blog/updates' element={<HumanCentricBlogPage/>}></Route>
        <Route path='/blog/tips' element={<AItoDevelopBlogPage/>}></Route>
        <Route path='/blog/insights' element={<WhyDoComapany/>}></Route> */}

        {/* 404 PAGE */}
        <Route path='*' element={<Page404/>} ></Route>
    </Routes>
  )
}

export default MainRoutes
