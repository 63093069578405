import React, { useEffect } from 'react'
import Savings from '../GlobalComponents/Saving'
import { register, typeform } from '../script/buttonLinks'
import AllThreeAvatars from '../myComponents/AIAvatar/AllThreeAvatars'
import { IntroductionComponent } from '../myComponents/AIAvatar/IntroductionComponent'
import { Introduction } from '../GlobalComponents/Introduction'
import { FourTitleBoxIcon } from '../GlobalComponents/FourTitleBoxIcon'

import { MdGesture } from "react-icons/md";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { HiOutlineLanguage } from "react-icons/hi2";
import { VscUnlock } from "react-icons/vsc";

import { PiHandWaving, PiUserFocus } from "react-icons/pi";
import { FaWalking } from "react-icons/fa";
import { MdOutlineSchool } from "react-icons/md";
import MultiLanguageAvatar from '../myComponents/AIAvatar/MultiLanguageAvatar'
import { introductionUIForYoutubeVideo } from '../script/backgroundUI'
import Comparision from '../GlobalComponents/Comparision'
import { IoManSharp } from 'react-icons/io5'
import { FiUser } from 'react-icons/fi'
import { baseURL } from '../script/baseURL'
import { Helmet } from 'react-helmet'
import { Newsletter } from '../myComponents/HomePageComponents/Newsletter'

const FeaturesAvatarPage = () => {


  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>AI Avatars | Customizable Digital Personas</title>
          <link rel="canonical" href={`${baseURL}/features/ai-avatars`} />
          <meta name="description" content="Create lifelike AI Avatars to represent your brand. Choose from our library or customize your own." />
        </Helmet>

        <Introduction
          title={<><span className='text-[#9e3df2]'>Meet Our AI Avatars</span> <br /> Diverse, Ready-to-Use Digital Personas</>}

          desc={`Explore our extensive library of AI Avatars, ranging from precise replicas of real individuals to entirely original creations, all designed to enrich your digital communications.`}
          btn1name={'Get Started'}
          btn2name={'Book a Demo'}
          btn1link={register}
          btn2link={typeform}
          UI={introductionUIForYoutubeVideo}
          poster={'/AIAvatarPosterIntro.png'}
          videoURL={'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_avatar_video_personate_ai.mp4'}
          
        />
        <AllThreeAvatars
          personateAvatars={personateAvatars}
        />
        <FourTitleBoxIcon
          title={'Interactive Gestures That Bring AI Avatars to Life'}
          desc={'Our AI Avatars use a range of dynamic gestures that enhance the realism and interactivity of each persona '}
          data={gestures}
          btn1name={'Explore the Possibilities'}
          btn1link={typeform}
        />
        
        <IntroductionComponent/>
        <MultiLanguageAvatar/>
        <Comparision 
          withOutPersonate={withOutPersonate} 
          withPersonate={withPersonate} 
          withImage={'/Comparision/Normal.png'}
          withOutImage={'https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled+design+(18).png'}
          heading={'Streamline Your Content Creation with AI Avatars'} 
          subHeading={'Opt for AI Avatars to boost viewer interaction and maintain brand identity efficiently'} 
          title2={'With AI Avatar'}
          title1={'Without AI Avatar'}
        />

      <div className='my-[30px] ' ><Newsletter/></div>

        <Savings/>
        
        
    </div>
  )
}

export default FeaturesAvatarPage



const gestures = [
  {
    title: "Greeting Gestures",
    desc: "Includes waving and shaking hands to initiate a welcoming interaction",
    icon : <PiHandWaving />
  },
  {
    title: "Movement Gestures",
    desc: "Features walking towards or away from the camera, perfect for simulating a stage presence or moving within a scene",
    icon : <FaWalking />
  },
  {
    title: "Contextual Gestures",
    desc: "Encompasses sitting for discussions or pointing to highlight information, ideal for educational and presentation settings",
    icon : <MdOutlineSchool />
  }
];

const personateAvatars = [
  {
      title : 'Synthetic Avatar',
      desc : ' Innovatively crafted from AI, these avatars act as unique digital personas',
      icon :  <PiUserFocus /> ,
      poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/syntheticAvatarPoster.png',
      video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/synthetic_avatar_video.mp4',
      youTube : 'https://www.youtube.com/embed/BtKJnRkk528'
      
  },
  {
      title : 'Real Human Avatar',
      desc : ' Designed to mirror real human features with exacting detail for authentic interaction',
      icon :  <FiUser />,
      // poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/realHumanAvatarPoster.png',
      poster : '/AvatarSecondPoster.png',
      video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/realhuman_avatar_video.mp4',
      youTube : 'https://www.youtube.com/embed/c3--zOoPXNE'
  },
  {
      title : 'Full-Body Avatars',
      desc : 'Available in both synthetic and real human avatar types, these avatars feature complete body animations to engage more fully with audiences',
      icon :<IoManSharp />,
      poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/fullBodyAvatarPoster.png',
      video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/fullbody_video.mp4',
      youTube : 'https://www.youtube.com/embed/79H9Yf0OSM0'
  },
]

const withPersonate = [
  'Significantly lower costs',
  'Instant availability, faster production',
  'Easily scales, simultaneous productions',
  'Consistent performance across videos',
  'Easy multilingual and cultural adaptation',
]

const withOutPersonate = [
  'Higher operational costs',
  'Longer timelines, scheduling delays',
  'Scaling increases complexity and costs',
  'Performance variability affects consistency',
  'Localization requires dubbing, subtitles',
]