import React from 'react'
import { ROIData } from '../../script/homepageData'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'

const ROI = () => {
  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto font-redHat laptop:mb-[70px] mb-[40px]'>
        
        <HeadingAndSubheading heading={`Maximize Your ROI With Personate's AI Video Generator`}  />
        <div className='w-full block laptop:flex laptop:gap-x-4' >
            {
                ROIData.map((el,i)=>(
                    <div key={i} className='rounded-md mb-[20px] laptop:mb-0 w-full text-dark-gray  px-[20px] py-[30px] border border-gray-200 ' >
                        
                        <h3 className='font-[700] text-[22px]' >{el.title}</h3>
                        
                        <div className='flex items-end my-[15px]' >
                            <div style={{background:'linear-gradient(43deg, #9F3DF2 25%, #F52FBF 46%,#278EFF 100%)',WebkitBackgroundClip:'text',WebkitTextFillColor:'transparent',}} 
                            className='font-bold text-[45px] leading-0' >{el.percentage}</div>
                            <div className='font-bold text-[17px] ml-[10px] pb-[10px] text-gray-400'>
                                {el.percentageTitle}
                            </div>
                        </div>

                        <p className='font-[600] text-[15px] laptop:text-[17px] mb-[20px]' >{el.desc}</p>
                        
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default ROI


