import React from 'react'
import {MdArrowRightAlt} from  'react-icons/md'
import {BsStars} from 'react-icons/bs'
import {Link} from 'react-router-dom'

const AvatarBackground = () => {
  return (
    <div className='relative w-[90%] laptop:w-[80%] mx-auto mt-0 laptop:mt-[100px] ' >
        {/* <img className='rounded-md w-[100%] h-[100%]' src="https://wallpapers.com/images/featured/purple-and-blue-background-ajp62v7qahujohnv.jpg" alt="" /> */}
        <div style={{background:'linear-gradient(235deg, #9F3DF2 15%, #F52FBF 46%,#278EFF 100%)'}} className='w-[100%] rounded-md h-[200px]' ></div>
        <div className='absolute inset-0 flex items-center laptop:items-start justify-center flex-col mx-[20px] laptop:mx-0 laptop:ml-[20px] h-[200px]' >
            <Link to='https://app.personate.ai/register' target='_blank' ><button className={`shadow-lg flex  font-semibold text-[18px] rounded-md text-primary bg-white px-[30px] py-[7px] `} > <span className={`text-primary mt-1 mr-1`} >{<BsStars/>}</span> Get Started<span className={`text-primary mt-1 ml-1 font-bold`} >{<MdArrowRightAlt/>}</span></button></Link>
            <p className='text-white mt-2 font-[500]' >Personate.ai for Enterprise? <Link to='https://calendly.com/rishab-sgy/personate-ai-demo' target="_blank" ><span className='underline underline-offset-2' >Contact Sales</span></Link> </p>
        </div>

        <img className='brightness-125 hidden tablet:hidden laptop:block absolute bottom-0 h-[330px] right-[20px] ' src="/ai-avatar1.png" alt="ai-avatar" />
    </div>
  )
}

export default AvatarBackground