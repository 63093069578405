import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './blurIntro.module.css'

export const BlurIntroduction = ({subTitle,bg='bg-white',height='h-[470px]', btn1name,btn1link,btn2name,btn2link,icon1,icon1size,icon2,icon2size,title,span,points,image1,image2}) => {

    const [scrolledDown, setScrolledDown] = useState(false);
  const [scrolledToTop, setScrolledToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      // Check if the user has scrolled down
      setScrolledDown(currentPosition > 0);
      // Check if the user has scrolled to the top
      setScrolledToTop(currentPosition === 0);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Only run this effect once, when the component mounts



  return (
    <div className={`${bg} ${styles.element} font-redHat ${height} laptop:h-screen  relative overflow-hidden `} >
        
        <div className='flex justify-between absolute  laptop:top-[70px] ' >
            <div className='w-[100%] tablet:w-[30%] ' >
                <img src={image1} className={`w-[100%] h-[100%] mx-auto object-contain ${scrolledDown?'transform -translate-x-[400px] -translate-y-[400px] rotate-180 ':''} ease-in-out duration-1000 `} alt='text-to-video' />
            </div>
            <div className='w-[100%] tablet:w-[30%] hidden tablet:block laptop:block ' >
                <img src={image2} className={`w-[100%] h-[100%] mx-auto object-contain ${scrolledDown?'transform translate-x-[400px] -translate-y-[400px] rotate-180 ':''} ease-in-out duration-1000 `} alt='text-to-video' />
            </div>
        </div>

        <div className='z-30 absolute top-[30px] laptop:top-1/2 left-1/2 transform -translate-x-1/2 laptop:-translate-y-1/2 text-center laptop:w-[75%] mx-auto w-[90%]' >
            <h1 className='text-[22px] tablet:text-[30px] laptop:text-[34px]  font-[600] text-off-black ' >{title}</h1>
            <p className='text-gray-600 font-[400] text-[15px] tablet:text-[18px] laptop:text-[20px] my-[20px]  ' >{subTitle}</p>
                
            {
                points?.map((el,i)=>(
                    <p className='text-gray-600 font-[400] text-[15px] tablet:text-[18px] laptop:text-[18px] mb-[20px] laptop:w-[80%] mx-auto  ' key={i}>{el}</p>

                ))
            }

                
            <div className='flex gap-x-[10px] justify-center laptop:mt-[70px] ' >
        
                <div>
                    <Link to={btn1link} target="_blank" >
                        <button className={`transition ${btn1name?'block':'hidden'}  ease-in-out duration-300 text-[14px] tablet:text-[16px] tracking-wide rounded-lg font-semibold px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] text-white bg-primary hover:bg-dark-primary flex justify-center items-center  `} >{btn1name} <span className={`ml-[3px] ${icon1size} `} >{icon1}</span> </button>
                    </Link>
                </div>
                <div>
                <Link to={btn2link} target="_blank" >
                    <button className={`${btn2name?'block':'hidden'} transition ease-in-out duration-300 flex justify-center items-center text-[14px] tablet:text-[16px] tracking-wide rounded-lg font-[500] px-[17px] laptop:px-[20px] py-[7px] laptop:py-[9px] border border-gray-400 hover:bg-slate-50 hover:text-black `} >{btn2name}<span className={`ml-[3px] ${icon2size} `} >{icon2}</span></button>
                </Link>
                </div>
        
            </div>
            
        </div>

            
    </div>
  )
}



