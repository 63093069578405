import React, { useEffect, useState} from 'react'
import {FaPlay} from 'react-icons/fa'
import {RxCross1} from 'react-icons/rx'
import styles from './popularPersonality.module.css'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'
import { FaAngleRight } from "react-icons/fa6";
import { TwoButtons } from '../../GlobalComponents/TwoButtons'
import { typeform } from '../../script/buttonLinks'

const PopularPersonalites = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [youTubeIndex,setYouTubeIndex] = useState(0)

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Clean up function to reset overflow when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);
    


  return (
    <div className='font-redHat mb-[50px]' >

        {/* CONTENT DIV */}
        <div className='mx-auto rounded-md w-[90%] laptop:w-[80%]  bg-white '>

            <HeadingAndSubheading heading={`Aaj Tak Created Sana, India's First AI Anchor Using Personate`} subHeading={`Join industry giants like Aaj Tak who rely on Personate's groundbreaking AI to captivate audiences worldwide. With trailblazers like AI Sana and the forthcoming Anjana 2.0, we’re redefining viewer engagement`} />
            
            
            {/* VIDEO CONTENT */}

            <div  className='relative' >

                <div className='flex laptop:flex-row flex-col laptop:justify-between  ' >
                    <div className='w-[100%] laptop:w-[35%]  ' >
                        {
                            data.map((el,i)=>(
                                <div onClick={()=>setYouTubeIndex(i)} key={i} className={`cursor-pointer flex justify-between items-center mb-[20px] rounded-lg p-[10px] py-[20px] ${youTubeIndex==i?'bg-blue-50 bg-opacity-60 border ':''} `} >  
                                    <div>
                                        <h3 className={`text-[20px] laptop:text-[26px] font-[600] ${youTubeIndex==i?'text-off-black':'text-gray-500'} `} >{el.title}</h3>
                                        {/* <h3 className='text-dark-gray font-[400] text-[13px] laptop:text-[15px] ' >{el.desc}</h3> */}
                                    </div>

                                    <div className={` ${youTubeIndex==i?'text-off-black':'text-gray-400'}`} >{<FaAngleRight />}</div>
                                </div>
                            ))
                        }
                    </div>

                    <div className='w-[100%] laptop:w-[60%]' >
                        <div className='rounded-xl overflow-hidden relative cursor-pointer ' onClick={()=>setIsModalOpen(true)} >
                            <img src={data[youTubeIndex].image} alt={data[0].title} />
                            <button  className='flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-80  h-[55px] w-[55px]' >{<FaPlay/>}</button>
                        </div>
                    </div>
                </div>

                <div className='flex justify-center mt-[30px] ' >
                    <TwoButtons btn1name={'Get in Touch for Tailored Solutions'} btn1link={typeform} />
                </div>

                {isModalOpen && (
                    <div className={styles.modalOverlay} onClick={()=>setIsModalOpen(false)} >
                        <div data-aos="zoom-in" className="w-[95%] h-[30%] tablet:w-[90%] tablet:h-[60%] laptop:w-[70%] laptop:h-[80%]">
                        <button className='text-[16px] ml-auto mb-2 hover:bg-gray-100 flex items-center bg-white text-black font-[500] rounded-lg py-[3px] px-[10px]  ' onClick={()=>setIsModalOpen(false)}>Close <span>{<RxCross1/>}</span></button>
                            <iframe
                                className='w-[100%] h-[100%] object-contain'
                                src= {data[youTubeIndex].vid}
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                )}

            </div>
            
        </div>
    </div>
  )
}
export default PopularPersonalites

let data = [
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled_design_5_b55qyc.jpg',
        title : 'Shah Rukh Khan',
        desc : 'Movie launch with Indian Superstar',
        vid : 'https://www.youtube.com/embed/5K-zIaBMXMw'
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/maxresdefault_rszlrc.jpg',
        title : 'PM Modi',
        desc : '1st AI Human to speak with a Prime Minister',
        vid : 'https://www.youtube.com/embed/K5wb2FT4lZk'
    },
    {
        image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/maxresdefault_1_pquvuk.jpg',
        title : 'Brad Smith',
        desc : 'Q&A with President at Microsoft',
        vid : 'https://www.youtube.com/embed/HOmeq9bY2lw'
    },
    {
      image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/anjana+om+kashyap+2.0+personate+ai+avatar.jpg',
      title : 'Anjana 2.0',
      desc : '1st AI Human to speak with a Prime Minister',
      vid : 'https://www.youtube.com/embed/3hzGHyJTwjA'
    },
]