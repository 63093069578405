import React from 'react'

export  const HeadingAndSubheading = ({text='text-[26px]',textLaptop='laptop:text-[30px]', heading,subHeading}) => {
  return (
    <div className='font-redHat ' >
        <h2 className={`${text}   ${textLaptop} ${subHeading?.length==0?'mb-[20px] laptop:mb-[50px]':'mb-0 laptop:mb-0'} w-[90%] mx-auto font-[600] text-center mb-[9px] text-off-black`} >{heading}</h2>
        <p className={`w-[90%] mx-auto text-center font-[500] text-[12px] laptop:text-[15px]  text-dark-gray  ${subHeading?.length==0?'mb-0 laptop:mb-0':'mb-[20px] laptop:mb-[50px]'}`} >{subHeading}</p>
    </div>
  )
}
