import React from 'react'
import style from './textticker.module.css'
export const TextTicker = () => {
  return (
    <div className={`z-10 bg-gray-50 border-b flex items-center w-[100%] h-[50px] overflow-hidden font-redHat font-[500] tracking-wider text-off-black text-[14px] `}>
      <ul className={`${style.tickerContainer} space-x-[200px] laptop:space-x-[500px] `} >
        {
          tickers.map((el,i)=>(
            <li key={i} >{el}</li>
          ))
        }
      </ul>
    </div>
  )
}



const tickers = [
  'AI Sana wins the prestigious INMA Global Media Award for ‘Best AI Customer-Facing Product’ in London.',
  // 'AI Sana wins the prestigious INMA Global Media Award for ‘Best AI Customer-Facing Product’ in London.',
]