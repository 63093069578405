import React, { useState } from 'react'
import { getStartedData } from '../../script/homepageData';
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading';
import { Link } from 'react-scroll';

const GetStarted = () => {
    const [activeIndex,setActiveIndex] = useState(0)

 

   

  return (
    <div className='mb-[50px]' >
        <div className='w-[100%] font-redHat  ' >
            <HeadingAndSubheading heading={'Make Your First Video in Minutes with Personate'} />

            {/*LAPTOP-TABBAR */}
            <div className='w-[90%] tablet:w-[80%] mx-auto flex tablet:grid tablet:grid-cols-4 scroll-smooth overflow-x-auto mb-[15px] rounded-lg bg-white p-[7px] border border-gray-100'>

                {
                    getStartedData.map((el,i)=>(
                        <div key={i}  onClick={()=>setActiveIndex(i)} 
                            className={`rounded-md flex items-center justify-center font-[500] cursor-pointer px-[10px] py-[7px] 
                            ${activeIndex==i?'bg-primary-transparency':''}
                            
                            ${i==0?'min-w-[200px]':''}
                            ${i==1?'min-w-[220px]':''}
                            ${i==2?'min-w-[275px]':''}
                            ${i==3?'min-w-[180px]':''} `}> 
                            
                            <div className={`flex font-[500] ${activeIndex==i?'text-primary':'text-dark-gray'}`} >{i+1} {`.  `} {el.tabs}</div>
                        </div>
                    ))
                }
                
            </div>

            <div className='w-[90%] laptop:w-[80%] mx-auto rounded-lg overflow-hidden border'  >  
                <video autoPlay muted loop poster={getStartedData[activeIndex].poster} className='object-contain ' src={getStartedData[activeIndex].video} />
            </div>

            

            <div className='hidden  tablet:flex tablet:flex-col items-center justify-center mt-[15px] ' >
                <p className='text-[15px] font-[500] text-dark-gray mb-[10px] w-[40%] text-center mx-auto ' >Watch videos created by our community with Personate's AI technology, and get inspired to create your own</p>
                <Link to={'PersonateVideoCollage'} spy={true} smooth={true} offset={-100} duration={500}>    
                    <button className='text-[14px] tracking-wide rounded-lg font-semibold px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] text-white bg-primary hover:bg-dark-primary flex justify-center items-center ' >Find Inspiration With Our Community Videos</button>
                </Link>
            </div>
            

        </div>
    </div>
  )
}

export default GetStarted

let tabButtons = [
    'Pick an AI Avatar ',
    'Pair it with an AI Voice',
    ' Craft script with AI or add yours',
    'Render your video',
]

// <Swiper 
//                 onSwiper={setSwiperRef}
//                 // className={styles.getStartedContainer} 
//                 // grabCursor={true} 
//                 centeredSlides={true} 
//                 slidesPerView={'auto'}
//                 onSlideChange={(swiper) => handleTabs(swiper?.activeIndex)}
//                 initialSlide={0}
//             >
//                 {
//                     getStartedData.map((el,i)=>(
//                         <SwiperSlide key={i}>
//                             {/* <img    src={el.image} alt="slider-image" /> */}
//                             <div className='w-[80%] mx-auto '  >  
//                                 <video autoPlay loop className='object-contain ' src={el.video} />
//                             </div>
//                         </SwiperSlide>
//                     ))
//                 }
//             </Swiper>







// <div className='hidden laptop:flex gap-[5%]' >
//                 <div className='w-[30%] flex flex-col justify-center ' >
//                     {
//                         getStartedData.map((el,i)=>(
//                             <div onClick={()=>setActiveIndex(i)} className={` rounded-lg p-4 mb-[20px] cursor-pointer group ${i==activeIndex?' bg-primary  ':'hover:bg-primary-transparency'} transition ease-in-out duration-300  `} >

                                
//                                 <div className='flex items-center gap-[20px] ' >
//                                     <div className={`text-[25px] p-3 rounded-full bg-primary-transparency text-primary transition ease-in-out duration-200 group-hover:bg-lite-gray `} >{el.icon}</div>
                                    
//                                     <div>
//                                         <div className={`text-[20px] font-[600] ${activeIndex==i?'text-white mb-[5px] ':''} text-gray-600 `} >{el.tabs}</div>

//                                         {/* <p className={ ` text-white font-[500] text-[14px] ${activeIndex==i?'content show':'content' }`} >{el.desc}</p> */}
//                                     </div>
                                    
//                                 </div>
                                
                                
//                             </div>
//                         ))
//                     }
                    
//                 </div>

//                 <div className='w-[65%] flex items-center   h-[500px]' >
//                     <div style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px"}} className='w-[100%]  rounded-lg' >
//                         <img className='w-[100%] h-[100%] rounded-md   ' src={getStartedData[activeIndex].image} alt="" />
//                     </div>
//                 </div>
//             </div>

//             <div style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px"}} className='w-[100%] block laptop:hidden rounded-lg' >
//                 <img className='w-[100%] h-[100%] rounded-md  ' src={getStartedData[activeIndex].image} alt="" />
//             </div>

//             <div className='flex laptop:hidden justify-center mt-[30px] gap-x-[10px]'>
//                 {
//                     getStartedData.map((el,i)=>(
//                         <button key={i} onClick={()=>handleClick2(i)} className={`transition-all ease ${mywidth==i?' bg-primary text-white':'bg-primary-transparency text-primary'} py-[6px] px-[10px] text-[12px] rounded-md font-[600]`}>
//                             {mywidth === i ? `${i + 1} . ${el.tabs}` : `${i + 1}`}
//                         </button>
//                     ))
//                 }
//             </div>


// <div className='bg-lite-gray' >
//         <div className='font-redHat py-[50px] w-[90%] laptop:w-[80%] mx-auto  ' >
            // <h2 className='font-[700] text-[30px] text-center ' > <span className='text-primary'  >Four Simple Steps</span> <span>to create a video</span> </h2>
            // {/* <p className='text-dark-gray text-[13px] font-[500] text-center tracking-wider mb-[30px] ' ></p> */}
            // <h3 className='px-[10px] text-center font-[500] text-[12px] tablet:text-[14px] laptop:text-[18px] mb-[20px] laptop:mb-[50px] text-gray-500' >Create your AI Presenter video in minutes, following these easy steps.</h3>

//             <div className='flex overflow-x-auto ease ' >
//                 {
//                     getStartedData.map((el,i)=>(
//                         <div onMouseEnter={()=>handleClick(i)} key={i} 
//                             className={` transition-all ease  relative ${mywidth==i?'w-[100%] laptop:w-[75%]':'w-0 laptop:w-[8%] '} mx-0 laptop:mx-[10px] h-[220px] laptop:h-[450px] rounded-md overflow-hidden cursor-pointer `} >
//                             <img  className={`h-[100%] w-[100%] object-cover`} src={el.image} alt="" />
//                             <button className={`hidden laptop:block ${mywidth==i?' bg-primary text-white':'bg-primary-transparency text-primary'} absolute left-3 bottom-3 py-[6px] px-[10px] text-[12px] rounded-md font-[600]`}>
//                                 {mywidth === i ? `${i + 1} . ${el.tabs}` : `${i + 1}`}
//                             </button>

//                         </div>
//                     ))
//                 }
//             </div>

            // <div className='flex laptop:hidden justify-center mt-[30px] gap-x-[10px]'>
            //     {
            //         getStartedData.map((el,i)=>(
            //             <button key={i} onClick={()=>handleClick2(i)} className={`transition-all ease ${mywidth==i?' bg-primary text-white':'bg-primary-transparency text-primary'} py-[6px] px-[10px] text-[12px] rounded-md font-[600]`}>
            //                 {mywidth === i ? `${i + 1} . ${el.tabs}` : `${i + 1}`}
            //             </button>
            //         ))
            //     }
            // </div>
//         </div>
//     </div>

// <div className='bg-[#FAFAFA] py-[40px] laptop:py-[60px] font-redHat'>
//         <div className='w-[100%] mx-auto'  data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
//             <h1 className='px-[10px] text-off-black text-center font-[600] text-[18px] tablet:text-[24px] laptop:text-[30px] mb-[25px] laptop:mb-[30px] ' >Get started in 4 simple steps</h1>
            

            // {/*LAPTOP-TABBAR */}
            // <div className='w-[95%] tablet:w-[80%] mx-auto flex tablet:grid tablet:grid-cols-4 scroll-smooth overflow-x-auto mb-[15px] rounded-lg bg-white p-[7px] border border-gray-100'>

            //     {
            //         getStartedData.map((el,i)=>(
            //             <div key={i}  onClick={()=>handleTabs(i)} 
            //             className={`rounded-md flex items-center justify-center font-[500] cursor-pointer px-[10px] py-[7px] 
            //             ${activeIndex==i?'bg-primary-transparency':''}
                        
            //             ${i==0?'min-w-[200px]':''}
            //             ${i==1?'min-w-[220px]':''}
            //             ${i==2?'min-w-[190px]':''}
            //             ${i==3?'min-w-[180px]':''} `}> 
                            
            //                 <div className={`flex font-[500] ${activeIndex==i?'text-primary':'text-dark-gray'}`} >{i+1} {`.  `} {el.tabs}</div>
            //             </div>
            //         ))
            //     }
                
            // </div>

            // <Swiper 
            // effect={'coverflow'}
            // onSwiper={setSwiperRef}
            // className={styles.getStartedContainer} 
            // grabCursor={true} 
            // coverflowEffect={{rotate: 0,stretch: 0,depth: 50,modifier: 2}} 
            // centeredSlides={true} 
            // slidesPerView={'auto'}
            // onSlideChange={(swiper) => handleTabs(swiper?.activeIndex)}
            // modules={[EffectCoverflow]} 
            // initialSlide={0}
            // >
            //     {
            //         getStartedData.map((el,i)=>(
            //             <SwiperSlide className={`${styles.getStartedSwiperSlide} `} key={i}>
            //                 <img  className={`${styles.getStartedSliderImage}`}  src={el.image} alt="slider-image" />
            //             </SwiperSlide>
            //         ))
            //     }
            // </Swiper>
//         </div>
//     </div>
