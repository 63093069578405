import React, { useRef, useState } from 'react'
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Link } from 'react-scroll';
import { FiChevronDown } from 'react-icons/fi';
import { Heading } from './Heading';

export const Steps = () => {
    const [index,setIndex] = useState(0)
    const [slideOpen,setSlideOpen] = useState(false)

    const handleTabs = (value) => {
        setIndex(value);
        setSlideOpen(false);
    };
    
  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto font-redHat pt-[50px] ' >
        
        

        {/* <h2 className=' text-blue-500  px-[7px] font-[700] tracking-wider text-center ' >HOW PERSONATE FEATURES WORKS ACROSS ALL STAGES OF VIDEO PRODUCTION</h2>
        <p className='text-dark-gray text-center ' >Find out how Personate features enhance and optimize each step of your video production process</p> */}
        <div className='mb-[20px] ' >
            <Heading title={'How Personate Features Works Across All Stages Of Video Production'} desc={'Find out how Personate features enhance and optimize each step of your video production process'} />
        </div>
        

        <div className='sticky top-[80px] z-30 bg-white bg-opacity-80 backdrop-blur-[10px] ' >
            {/*LAPTOP-TABBAR */}
            <div className="w-full hidden laptop:flex p-[6px] mb-[40px] rounded-lg border border-gray-100  ">
                {tabs.map((el, i) => (
                <div className='w-[100%]' key={i} >
                    <Link to={el.to} spy={true} smooth={true} offset={-150} duration={500} onSetActive={() => setIndex(i)} >
                    <div
                        key={i}
                        onClick={() => handleTabs(i)}
                        className={`tracking-wider flex justify-center items-center py-[10px] text-[14px] font-[500] rounded-md cursor-pointer w-[100%] ${index == i? "text-blue-400 bg-blue-50 font-[600]": "text-dark-gray"}`}
                    >
                        <span className={`${index==i?'bg-white':'bg-blue-50 text-gray-400'} font-bold rounded-[5px] px-2 mr-[4px]`}>{i+1}</span>
                        {el.title}
                    </div>
                    </Link>
                </div>
                ))}
            </div>

            {/* MOBILE AND TABLET BAR */}
            <div onClick={() => setSlideOpen(!slideOpen)}
                className={`flex items-center laptop:hidden w-full mx-auto rounded-md  bg-white text-center h-[50px] px-[10px] text-[16px] font-[500] text-blue-400 border border-gray-100 ${
                slideOpen ? "rounded-b-none border-b-0" : ""
                } `}>
                <span className="mr-1 flex h-[18px] w-[18px] rounded-[3px] text-[13px] bg-blue-50 text-blue-400 justify-center items-center ">{index+1}</span>{" "}
                {tabs[index].title}{" "}
                <span className={`ml-auto mt-1 ${ slideOpen ? "rotate-180" : ""} transition-transform transform ease-in-out duration-300 `}>
                    {<FiChevronDown />}
                </span>{" "}
            </div>

            <div className={`${slideOpen? "w-[100%] custom-slide-in-up overflow-auto h-auto rounded-t-none" : "overflow-hidden h-0 border-none"} rounded-md bg-white border border-gray-100 text-[14px] absolute z-50 text-gray-400 font-[500] `}>
                {tabs.map((el, i) => (
                    <div key={i} >
                        <Link to={el.to} spy={true} smooth={true} offset={-140} duration={500} onSetActive={() => handleTabs(i)}>
                            <div   className={`flex items-center py-[10px] pl-[20px] hover:text-blue-400 hover:bg-blue-50 `} key={i} onClick={() => handleTabs(i)}>
                                <span className="font-bold mr-2 ">{i+1}</span>
                                {el.title}
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>

        <div id='step1' ><Step1/></div>
        <div id='step2'  ><Step2/></div>
        <div id='step3'  >
            <Step3 title={`Multilingual and Accessible`}
            desc={`Expand your video's accessibility and appeal with multilingual AI tools for creating and translating videos`}
            points={multiLingual}
            image={'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/multilingual_ai_translation_personate_ai_avatars.png'}
            />
        </div>
        <div id='step4'  ><Step4/></div>
        
    </div>
  )
}


let tabs = [
    {
        title : 'Begin With Any Format',
        to : 'step1'
    },
    {
        title : 'Customize and Personalize',
        to : 'step2'
    },
    {
        title : ' Multilingual and Accessible',
        to : 'step3'
    },
    {
        title : 'Scale With Efficiency',
        to : 'step4'
    },
]

const multiLingual = [
  
    {
      title : "Effortless Language Translation:",
      desc : "Translate your content seamlessly to cater to international viewers",
    },
    {
      title : "AI-Powered Scriptwriting:",
      desc : "Automatically generate compelling scripts that resonate with diverse audiences",
    },
    {
      title : "Instant AI Videos:",
      desc : "Quickly refresh existing videos with new scripts while keeping the original visuals",
    },
    {
      title : "AI Video Translation:",
      desc : "Dub videos into multiple languages with precise lip-syncing for global reach",
    },
    {
      title : "Dynamic Gestures:",
      desc : "Enhance AI Avatars with natural gestures for more authentic interactions"
    },
]
  