import React, { useRef, useState } from 'react'
import {MdPause} from 'react-icons/md'
import {FaPlay,FaCircle} from 'react-icons/fa'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading'
import { GoChevronRight } from "react-icons/go";



const AllThreeAvatars = ({personateAvatars}) => {
    const [index,setIndex] = useState(0)
    const [buttonFlag,setButtonFlag] = useState(true)
    const [isPlay,setIsPlay] = useState(false)






    
return (    
    <div className='w-[90%] laptop:w-[80%] mx-auto font-redHat mb-[50px] ' >

        <h2 className='text-off-black text-[20px] tablet:text-[25px] laptop:text-[30px] font-[500] laptop:mb-[20px] ' >Explore AI Avatar Options Perfectly Suited to Your Brand</h2>
        <p className='text-dark-gray text-[15px] w-[100%] laptop:w-[50%] ' >Our customizable options allow you to either clone yourself or create a synthetic AI Avatar that fits your requirements</p>
        <div className='flex laptop:flex-row flex-col laptop:justify-between  ' >
            <div className='w-[100%] laptop:w-[45%]  ' >
                {
                    personateAvatars.map((el,i)=>(
                        <div key={i} >
                            <div key={i} className={`flex items-center justify-between gap-[15px] py-[25px] border-b cursor-pointer ${index==i?'border-b border-primary':''} `} onClick={() => {
                                setIndex(i);
                                setButtonFlag(true);
                            }} >
                                <div>
                                    <h3 className='flex items-center gap-[10px] ' ><div className={`${index==i?'bg-primary text-white ':''} p-[5px] rounded-md shadow-md `} >{el.icon}</div><div className={`${index==i?'text-primary ':'text-off-black'} laptop:text-[20px] font-[500] `} >{el.title}</div></h3>

                                    <div className='text-dark-gray text-[14px] font-[400] mt-[10px] tracking-wide ' >{el.desc}</div>
                                </div>
                                <div className='text-[25px] text-dark-gray ' >{<GoChevronRight />}</div>
                            </div>
                            <div className={` ${index==i?'block':'hidden'} laptop:hidden rounded-lg overflow-hidden relative transition ease-in-out duration-150 `}  >
                                <img onClick={()=>setButtonFlag(true)}  className='relative top-0 left-0 transition ease-in-out duration-200 w-full h-full object-contain rounded-xl ' src={personateAvatars[index].poster} alt='' />
                                {buttonFlag && (
                                    <iframe
                                        className="z-0 w-[100%] h-[100%] absolute top-0 left-0 bottom-0 right-0 object-fill"
                                        src={personateAvatars[index].youTube}
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>
                                )}
                                <button onClick={()=>setButtonFlag(!buttonFlag)} className={`flex items-center  z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-60  h-[45px] w-[45px] ${!buttonFlag?'block':'hidden'} `} >{<FaPlay/>}</button>
                            </div>
                        </div>
                    ))
                }
            </div>


            <div className='w-[0%] laptop:w-[45%] flex items-center transition ease-in-out duration-150  ' >
                <div className='relative cursor-pointer ' >
                    <img onClick={()=>setIsPlay(true)}  className='relative top-0 left-0 transition ease-in-out duration-200 w-full h-full object-contain rounded-xl ' src={personateAvatars[index].poster} alt='' />
                    <button onClick={()=>setIsPlay(!isPlay)} className={`flex items-center  z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-60  h-[45px] w-[45px] ${!isPlay?'block':'hidden'} `} >{<FaPlay/>}</button>
                    {isPlay && (
                        <iframe
                            className="z-0 w-[100%] h-[100%] absolute top-0 left-0 bottom-0 right-0 object-fill"
                            src={personateAvatars[index].youTube}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    )}
                </div>
            </div>
        </div>

    </div>
  )
  
}

export default AllThreeAvatars







// <div className=' w-[100%]  font-redHat overflow-hidden laptop:mb-[70px] mb-[40px]' >
        
// <div className='w-[90%] laptop:w-[80%] mx-auto ' >

//     <HeadingAndSubheading heading={`Meet Our AI Avatars`} subHeading={`Each with their own unique presence, ready to embody your message and engage your audience.  Our library is ever-expanding, with new AI Avatars introduced regularly to keep your video content fresh and engaging.`} />
//     <div className='grid grid-cols-1 laptop:grid-cols-3 gap-y-6 laptop:gap-x-6'>
//         <div onClick={()=>handlePlay("1")} className='cursor-pointer rounded-lg shadow-md overflow-hidden relative group' style={{ position: 'relative' }}>
//             <video poster='https://res.cloudinary.com/dxvi69tlz/image/upload/v1702298162/bizOps/wesbite_asset/website%20section%20videos/Untitled_design_21_zwbtgx.jpg' className='z-30' onEnded={()=>handleOnEnded()} ref={vid1Ref} src={personateAvatars[0]}></video>
//             <button  className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-80  ${isPlay.vid1?'text-[20px] hidden':'text-[17px]'} h-[45px] w-[45px] `} >{<FaPlay/>}</button>
//             <div className={`absolute w-full bottom-0 flex flex-col justify-end p-[15px]`}>
//                 <h2 className={` ${isPlay.vid1?'hidden':''} text-off-black font-[700] text-[18px] laptop:text-[20px]`} >Full Body Avatars</h2>
                
//             </div>
//         </div> 


//         <div onClick={()=>handlePlay("2")} className='rounded-lg shadow-md overflow-hidden relative group' style={{ position: 'relative' }}>
//             <video className='z-30' onEnded={()=>handleOnEnded()} ref={vid2Ref} src={personateAvatars[1]}></video>
//             <button className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-80  ${isPlay.vid2?'text-[20px] hidden':'text-[17px]'} h-[45px] w-[45px] `} >{<FaPlay/>}</button>
//             <div className={`absolute  w-full bottom-0 flex flex-col justify-end p-[15px]`}>
//                 <h2 className={` ${isPlay.vid2?'hidden':''} text-off-black font-[700] text-[18px] laptop:text-[20px]`} >Real Human Avatars</h2>
                
//             </div>
//         </div> 

//         <div onClick={()=>handlePlay("3")} className='rounded-lg shadow-md overflow-hidden relative group' style={{ position: 'relative' }}>
//             <video className='z-30' onEnded={()=>handleOnEnded()} ref={vid3Ref} src={personateAvatars[2]}></video>
//             <button className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-80  ${isPlay.vid3?'text-[20px] hidden':'text-[17px]'} h-[45px] w-[45px] `} >{<FaPlay/>}</button>
//             <div className={`absolute  w-full bottom-0 flex flex-col justify-end p-[15px]`}>
//                 <h2 className={` ${isPlay.vid3?'hidden':''} text-off-black font-[700] text-[18px] laptop:text-[20px]`} >Synthetic Face Avatars</h2>
                
//             </div>
//         </div> 
//     </div>

// </div>
// </div>



