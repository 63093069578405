import React from 'react'
import {  register, typeform } from '../script/buttonLinks'
import { FaRegUser } from "react-icons/fa";
import { PiUserFocusThin } from "react-icons/pi";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { BottomBanner1 } from '../GlobalComponents/BottomBanner1'
import { FaRegClone } from "react-icons/fa6";

import { FaLink } from "react-icons/fa6";
import { CiVideoOn } from "react-icons/ci";
import {  introductionUIForYoutubeVideo } from '../script/backgroundUI'
import { Steps } from '../myComponents/FeaturePageComponent/Steps'
import { IntroductionWithoutImage } from '../GlobalComponents/IntroductionWithoutImage'
import { Helmet } from 'react-helmet';
import { baseURL } from '../script/baseURL';
import { Newsletter } from '../myComponents/HomePageComponents/Newsletter';

const FeaturePage = () => {

  return (
    <div className='font-redHat ' >
        <Helmet>
          <meta charSet="utf-8" />
          <title>All Features | Explore All Capabilities</title>
          <link rel="canonical" href={`${baseURL}/features`} />
          <meta name="description" content="Discover Personate’s AI tools: text-to-video, AI Avatars, AI Voices, instant AI videos, video translations, and more for dynamic video content." />
        </Helmet>

        <IntroductionWithoutImage
          title={<>Discover the Full Range of<br/><span className='text-[#9e3df2]'>Personate Features</span></>}
          desc={`From the initial input of content to the final stages of global distribution, each feature is designed to simplify your workflow, increase engagement, and expand your reach across diverse audiences`}
          btn1name={`See Features in Action`}
          btn1link={register}
          UI={introductionUIForYoutubeVideo}
        />
        <Steps/>
        <div className='my-[30px] ' ><Newsletter/></div>
        <BottomBanner1
          heading={`Step Into the Future of Video Production With Personate `}
          para={`Whether you're a small business or a large enterprise, Personate provides the tools you need to succeed in the digital age`}
          btn2name={`Get in Touch`}
          btn2link={typeform}
        />
        
        
      
    </div>
  )
}

export default FeaturePage

let step4 = [
  {
    title:'API for Scale',
    desc : 'Integrate Personate’s automation features to streamline video production at any scale',
    icon : <FaLink />
  },
  {
    title:'Personalized Videos at Scale',
    desc : 'Share custom messages on email, WhatsApp, and other platforms',
    icon : <CiVideoOn />
  },
]

const faqData =[
  {
    "question": "What makes Personate AI Video Generator a cost-efficient alternative to traditional video production?",
    "answer": "With Personate, you drastically reduce the need for costly actors, locations, and filming equipment. Personate Studio minimizes both your expenses and production time, making high-quality video content more accessible and affordable. The efficiency and scalability of Personate ensure that it’s a cost-effective solution tailored to your diverse video content needs."
  },
  {
    "question": "How long does AI video generation take with Personate?",
    "answer": "The time it takes to generate a video with Personate depends on the length of the content you are creating. On average, you can expect a rendering time of about 10–20 minutes. This rapid turnaround time allows you to produce and deploy your content swiftly, managing both simple and complex projects efficiently."
  },
  {
    "question": "What level of creative control do I have over videos with Personate?",
    "answer": "Personate gives you complete creative control over your videos. You can choose the video style, characters, and background music to ensure everything captures your vision. This level of flexibility lets you craft customized, engaging content that truly resonates with your audience and elevates your digital presence."
  },
  {
    "question": "Is there a video quality difference based on input formats while using Personate?",
    "answer": "No matter whether you start with text, URLs, PDFs, Excel, or PowerPoint, Personate guarantees you uniform, top-tier video quality. This ensures that regardless of your input format, the end product always meets high-quality standards, empowering you to create professional-grade video content with ease."
  }
]


let converter = [
  {
      title : 'Text to Video',
      desc : 'Turn text into visually engaging stories with ease ',
      // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296719/bizOps/wesbite_asset/feature%20logo/URL-1_e3w0p4.png',
      button : 'Start Converting'
  },

  {
      title : 'URL to Video',
      desc : ' Create videos from web content instantly',
      // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296712/bizOps/wesbite_asset/feature%20logo/URL_x7auru.png',
      button : 'Bring Web to Life'
  },
  {
      title : 'PDF to Video',
      desc : ' Animate PDF documents to enhance viewer interaction',
      // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296724/bizOps/wesbite_asset/feature%20logo/PDF_m1mg6u.png',
      button : 'Revitalize PDFs'
      
  },
  {
      title : 'PowerPoint to Video',
      desc : 'Convert presentations into engaging videos that captivate',
      // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296721/bizOps/wesbite_asset/feature%20logo/PPT_untmjt.png',
      button : 'Transform Presentations'
  },
  {
      title : 'Excel to Video  ',
      desc : 'Transform complex data from spreadsheets into clear, informative videos',
      // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296721/bizOps/wesbite_asset/feature%20logo/PPT_untmjt.png',
      button : 'Bring Data to Life'
  },
]


const custom = [
  {
    title: "AI Avatar Library",
    desc: "Access a diverse array of AI Avatars ready to represent your narrative",
    icon : <FaRegUser />
  },
  {
    title: "Custom AI Avatars",
    desc:" Tailor avatars to reflect your brand’s unique identity",
    icon : <PiUserFocusThin />
  },
  {
    title:" AI Voices",
    desc: "Ensure consistent audio across your productions with lifelike synthetic voices or cloned voices",
    icon : <MdOutlineKeyboardVoice />
  },
  {
    title:"AI Voice Cloning",
    desc: "Exactly replicate specific voices or create a new one for a uniform brand identity across all platforms",
    icon : <FaRegClone />
  }
]
// AI Voices: Exactly replicate specific voices or create a new one for a uniform brand identity across all platforms
// Exactly replicate specific voices or create a new one for a uniform brand identity across all platform
const multiLingual = [
  
  {
    "title": "Effortless Language Translation",
    "desc": "Translate your content seamlessly to cater to international viewers"
  },
  {
    "title": "AI-Powered Scriptwriting",
    "desc": "Automatically generate compelling scripts that resonate with diverse audiences"
  },
  {
    "title": "Instant AI Videos",
    "desc": "Quickly refresh existing videos with new scripts while keeping the original visuals"
  },
  {
    "title": "AI Video Translation",
    "desc": "Dub videos into multiple languages with precise lip-syncing for global reach"
  },
  {
    "title": "Dynamic Gestures",
    "desc": "Enhance AI avatars with natural gestures for more authentic interactions"
  },
]

