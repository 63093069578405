import React, { useRef, useState } from 'react'
import { TwoButtons } from './TwoButtons'
import {FaPlay} from 'react-icons/fa'
import {FaCircle} from 'react-icons/fa'

export const ThreeDots = ({poster,videoURL,title,desc,points,btn1name,btn2name,btn1link,btn2link,icon1,icon1size,icon2,icon2size,image}) => {
    const videoRef = useRef(null)
    const [isPlay,setIsPlay] = useState(false)

    const handlePlay = ()=>{
        if (videoRef.current) {
          if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlay(()=>true)
          } else {
            videoRef.current.pause();
            setIsPlay(()=>false)
          }
        }
    }

    const handleVideoEnd = ()=>{
        setIsPlay(false)
    }
  
    return (
    <div className='bg-lite-gray py-[30px] ' >
        <div className='w-[90%] laptop:w-[80%] block laptop:flex mx-auto font-redHat laptop:gap-[40px] bg-white rounded-2xl laptop:p-[60px] p-[20px]  '>
            {/* LEFT DIV */}
            <div className='w-[100%] laptop:w-[50%] mb-[30px] laptop:mb-0 '>

                <h2 className='text-[28px] font-[500] mb-[10px] text-off-black' >{title}</h2>

                <p className='text-[16px] text-[#919191] mb-[20px]  ' >{desc}</p>

                <ul>
                {
                    points.map((el,i)=>(
                      <li key={i} className='mb-[10px] laptop:mb-[15px] flex text-[16px] tracking-wide text-dark-gray '>
                            <span className='mr-3 text-[8px] pt-2  text-primary' >{<FaCircle/>}</span>
                            <span>
                                <span className='font-[700] mr-1 ' >{el.title}</span>
                                <span>{el.desc}</span>
                            </span>
                        </li>
                    ))
                }
                </ul>
                
                <TwoButtons
                    btn1name={btn1name}
                    btn2name={btn2name}
                    btn1link={btn1link}
                    btn2link={btn2link}
                    icon1={icon1}
                    icon2={icon2}
                    icon1size={icon1size}
                    icon2size={icon2size}
                />
            </div>

            {/* RIGHT DIV */}
            <div className='w-[100%] laptop:w-[50%] flex items-center' >
                {
                    !videoURL && <div className='' >
                        <img className='rounded-xl ' src={image} alt="" />
                    </div>
                }

                {
                    videoURL && <div 
                    className='relative cursor-pointer rounded-xl overflow-hidden' 
                    onClick={handlePlay}
                    style={{boxSizing: 'border-box'}}
                  >
                    <video 
                      onEnded={handleVideoEnd} 
                      ref={videoRef} 
                      poster={poster}
                      className='w-full h-full block' 
                      src={videoURL} 

                      style={{ display: 'block' }}
                    ></video>
                    <button  
                      className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white ${isPlay ? 'text-[22px] hidden' : 'text-[17px]'} h-[45px] w-[45px] laptop:h-[50px] laptop:w-[50px] bg-black bg-opacity-50`} 
                    >
                      <FaPlay/>
                    </button>
                  </div>
                }
            </div>
        </div>
    </div>
  )
}
