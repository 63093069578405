import React, { useEffect } from 'react'
import AvatarBackground from '../myComponents/Ethics/AvatarBackground'
import Responsible from '../myComponents/Ethics/Responsible'
import { Helmet } from 'react-helmet'
import { baseURL } from '../script/baseURL'
import { Newsletter } from '../myComponents/AboutUs/Newsletter'

const Ethics = () => {
  return (
    <div className={`py-[50px]`} style={{ backgroundImage: "url('https://img.freepik.com/free-photo/studio-background-concept-abstract-empty-light-gradient-purple-studio-room-background-product-plain-studio-background_1258-71253.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', }}  >
        <Helmet>
            <meta charSet="utf-8" />
            <title>Ethics | Responsible AI Use</title>
            <link rel="canonical" href={`${baseURL}/ethics`} />
            <meta name="description" content="Learn about Personate’s commitment to ethical AI innovation and responsible technology use" />
        </Helmet>

        <Responsible/>
        <div><Newsletter/></div>
        <AvatarBackground/>
    </div>
  )
}

export default Ethics
