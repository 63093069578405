import React, { useRef, useState } from 'react'
import { FaPlay } from 'react-icons/fa'

export const InstantAIVideo = () => {
    const videoRef = useRef(null)
    const [isPlay,setIsPlay] = useState(false)
   
    
    const handlePlay = ()=>{
        if (videoRef.current) {
          if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlay(()=>true)
          } else {
            videoRef.current.pause();
            setIsPlay(()=>false)
          }
        }
    }

    const handleVideoEnd = ()=>{
        setIsPlay(false)
    }

  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto font-redHat mb-[50px] block laptop:flex justify-between ' >
        <div className='w-[100%] laptop:w-[45%] my-auto ' >
            <div className='pr-0 laptop:pr-[10px] ' >
                <h2 className='text-[20px] laptop:text-[30px] font-[500] text-off-black ' >Instant AI Videos</h2>
                <p className='text-[15px] laptop:text-[16px] font-[400] text-dark-gray tracking-wide ' >Upload a video, then easily replace the script to generate new, lip-synced video content instantly</p>
            </div>
        </div>
        <div className='w-[100%] laptop:w-[55%] my-auto mt-[20px] laptop:mt-0 ' >
            <div className='relative cursor-pointer ' >
                <video poster='/instantAIVideoHomePoster.png' onClick={handlePlay} ref={videoRef} onEnded={handleVideoEnd} className='transition ease-in-out duration-200 w-full h-full object-contain rounded-xl ' src="https://personate-data-v2.s3.amazonaws.com/website+data+/instant-ai-videos-personate-ai.mp4"></video>

                <button onClick={handlePlay} className={`flex items-center  z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-60  h-[45px] w-[45px] ${!isPlay?'block':'hidden'} `} >{<FaPlay/>}</button>
            </div>
        </div>
    </div>
  )
}
