import React from 'react'
import styled, { keyframes, css } from "styled-components";
import { mediaLogos } from '../../script/homepageData';

const MediaCarousels = () => {
  return (
<AppContainer >
      <Wrapper>
      <h2 className={`text-[26px] laptop:text-[30px] font-redHat w-[90%] mx-auto font-[600] text-center mb-[9px] text-off-black`} >Media Coverage</h2>
        <Marquee>
          <MarqueeGroup>
            {mediaLogos.map((el,i) => (
                <div key={i} className={`flex items-center h-[70px] w-[100px] laptop:h-[70px] laptop:w-[140px] mx-[10px]`} >
                <img className={` 
                    block
                    m-auto
                    ${el.logoName=='dw'?'h-[70%]':''}
                    ${el.logoName=='abc news'?'h-[70%]':''}
                    ${el.logoName=='mia'?'h-[80%]':''}
                    ${el.logoName=='jagran'?'h-[80%]':''}
                    ${el.logoName=='adweek'?'h-[50%]':''}
                    ${el.logoName=='yahoo'?'h-[50%]':''}
                    ${el.logoName=='aajtak'?'h-[80%]':''}
                    ${el.logoName=='india ai'?'h-[80%]':''}
                    ${el.logoName=='frontline'?'h-[80%]':''}
                    ${el.logoName=='scoop woop'?'h-[80%]':''}
                    ${el.logoName=='ibw'?'scale-125':''}
                    ${el.logoName=='financial express'?'scale-125':''}
                    ${el.logoName=='the economis times'?'scale-125':''}
                    h-[100%] w-[100%] object-contain 
                    `}  src={el.logoLink} alt={el.logoName} />
                </div>
                ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {mediaLogos.map((el,i) => (
              <div key={i} className={`flex items-center h-[70px] w-[100px] laptop:h-[70px] laptop:w-[140px] mx-[10px]`} >
              <img className={` 
              block
              m-auto
              ${el.logoName=='dw'?'h-[70%]':''}
              ${el.logoName=='abc news'?'h-[70%]':''}
              ${el.logoName=='mia'?'h-[80%]':''}
              ${el.logoName=='jagran'?'h-[80%]':''}
              ${el.logoName=='adweek'?'h-[50%]':''}
              ${el.logoName=='yahoo'?'h-[50%]':''}
              ${el.logoName=='aajtak'?'h-[80%]':''}
              ${el.logoName=='india ai'?'h-[80%]':''}
              ${el.logoName=='frontline'?'h-[80%]':''}
              ${el.logoName=='scoop woop'?'h-[80%]':''}
              ${el.logoName=='ibw'?'scale-125':''}
              ${el.logoName=='financial express'?'scale-125':''}
              ${el.logoName=='the economis times'?'scale-125':''}
              
              h-[100%] w-[100%] object-contain 
              
              `}  src={el.logoLink} alt={el.logoName} />
          </div>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
  )
}

export default MediaCarousels

const AppContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin : 50px;

`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


const Marquee = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  user-select: none;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;






{/* <div className=' w-[90%] laptop:w-[100%] mx-auto font-redHat mb-[50px]' >

<HeadingAndSubheading heading={'Media Coverage'} />
<div className={`${styles.car} flex overflow-x-auto mt-[22px]`} >
    <div className={styles.slider}>
    {
        mediaLogos.map((el,i)=>(
            <div key={i} className={`flex items-center h-[70px] w-[100px] laptop:h-[70px] laptop:w-[140px] mx-[10px]`} >
                <img className={` 
                block
                m-auto
                ${el.logoName=='dw'?'h-[70%]':''}
                ${el.logoName=='abc news'?'h-[70%]':''}
                ${el.logoName=='mia'?'h-[80%]':''}
                ${el.logoName=='jagran'?'h-[80%]':''}
                ${el.logoName=='adweek'?'h-[50%]':''}
                ${el.logoName=='yahoo'?'h-[50%]':''}
                ${el.logoName=='aajtak'?'h-[80%]':''}
                ${el.logoName=='india ai'?'h-[80%]':''}
                ${el.logoName=='frontline'?'h-[80%]':''}
                ${el.logoName=='scoop woop'?'h-[80%]':''}
                ${el.logoName=='ibw'?'scale-125':''}
                ${el.logoName=='financial express'?'scale-125':''}
                ${el.logoName=='the economis times'?'scale-125':''}
                
                h-[100%] w-[100%] object-contain 
                
                `}  src={el.logoLink} alt="logos" />
            </div>
        ))
    }
    </div>
</div>

</div> */}