import React, { useState,useEffect,useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { fourthRow, secondRow } from '../../script/homepageData'
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import styles from './newsletter.module.css'
import { FiUser } from 'react-icons/fi';
import axios from 'axios';


const Footer = () => {
  const location = useLocation()
  const [isLoading,setIsLoading] = useState(false)
  const timeoutRef = useRef(null);

  const [email,setEmail] = useState('')
  const [resData,setResData] = useState({
    successfull : false,
    error : false,
    empty : false,
  })

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleClick = () => {

    if (!emailRegex.test(email)) {
      setResData({
        successfull: false,
        error: false,
        empty: true,
      });
      timeoutRef.current = setTimeout(() => {
        setResData({
          successfull: false,
          error: false,
          empty: false,
        });
      }, 5000);
    } 
    else{
      setIsLoading(true)
      let data = new FormData();
      data.append('Email', email);
    
      let config = {
        method: 'post',
        url: 'https://script.google.com/macros/s/AKfycbxgVl-xAM5bbycdfsGrH7ECnWiv_27FkLpHVqt4Cw14nyV8ZzOw4jvVoSzr2wCgEkpp0g/exec',
        headers: { 
          'Content-Type': 'multipart/form-data'
        },
        data : data
      };
    
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setIsLoading(false)
        setResData({
          successfull : true,
          error : false,
          empty : false,
        })
        
        setEmail('')
        timeoutRef.current = setTimeout(() => {
          setResData({
            successfull: false,
            error: false,
            empty: false,
          });
        }, 5000);

      })
      .catch((error) => {
        console.log(error);
        setResData({
          successfull : false,
          error : true,
          empty : false,
        })
        setEmail('')
        timeoutRef.current = setTimeout(() => {
          setResData({
            successfull: false,
            error: false,
            empty: false,
          });
        }, 5000);
      });


      
    }


  }


  useEffect(() => {
    // Clear the timeout if the component unmounts or if email changes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className={`bg-lite-gray font-redHat text-dark-gray py-[50px] `}>
        
      <div className='bg-lite-gray w-[90%] laptop:w-[80%] mx-auto flex flex-col-reverse laptop:flex-row gap-[10%] ' >
        <div className='w-[100%] laptop:w-[25%] mt-[60px] laptop:mt-0 ' >
          
          
          
          <div className='w-[60%] laptop:w-[80%] mx-auto laptop:mx-0 mb-[30px] ' ><img className='w-[100%] h-[100%] ' src="/personate-logo.png" alt="personate-logo" /></div>
 
          <p className='text-[14px] font-[400] tracking-wide  text-dark-gray mb-[30px] text-center laptop:text-left ' >Create engaging videos with Personate AI Video Generator, maximizing your ROI with innovative AI-powered features.</p>
          <hr className='' />

          <div className='flex justify-around laptop:justify-between ' >
              <div className='h-[100px] items-center ' >
                <img className='w-[100%] h-[100%] object-contain block'  src="https://personate-data-v2.s3.amazonaws.com/website+data+/CAI_Lockup_RGB_Black_social-removebg-preview_kumoe0.png" alt="authencity-logo" />
              </div>
              <div className='h-[50px] w-[50px] my-auto' >
                <img className='w-[100%] h-[100%] object-contain  block' src="https://personate-data-v2.s3.amazonaws.com/website+data+/PNG_GDPR-e1672263252689_mgs7l7.png" alt="GDPR" />
              </div>
          </div>

        </div>





        <div className='w-[100%] laptop:w-[70%] grid grid-cols-2 laptop:grid-cols-2 gap-y-[40px] laptop:gap-y-0 ' >
          


          {/* SECOND ROW */}
          <div className='flex flex-col gap-[20px] ' >
            <div className='text-[16px] font-[500] text-primary ' >Features</div>
            {
              secondRow.map((el,i)=>(
                <div key={i} ><Link to={el.link}><div className='text-dark-gray text-[14px] font-[400] ' >{el.title}</div></Link></div>
              ))
            }
          </div>


          {/* FOURTH ROW */}
          <div className='flex flex-col gap-[20px] ' >
            <div className='text-[16px] font-[500] text-primary ' >Company</div>
            {/* NEWSLETTER  */}
            {
                // (location.pathname !== '/' && location.pathname !== '/about') && <div className='hidden laptop:block mx-auto w-[80%] laptop:w-[100%]' >
                //   <h2 className='text-[17px]  font-[500] text-center laptop:text-left text-off-black   ' >Subscribe to Our Newsletter</h2>
                //   <div className='flex items-center mt-[10px] gap-1 w-[100%]  ' >
                //       <div className={`${styles.inputContainer} w-[100%] `}>
                //           <span className={styles.inputIcon}><FiUser /></span>
                //           <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" className={`${styles.inputField}`} id="username" placeholder="Enter your email" />
                //       </div>
                //       <button onClick={handleClick} disabled={isLoading} className={`text-[14px] items-center justify-center py-[5px]  ${isLoading?'bg-gray-100':'bg-off-black'} text-white rounded-md px-[10px]  `} >Subscribe</button>
                //   </div>
                //   {
                //   resData.successfull && (
                //     <p className={` text-green-500 font-[500] text-[14px] `} >Your are now subscribed to Personate newsletter</p>
                //   )
                //   }
                //   {
                //     resData.error && <p className={` text-red-500 font-[500] text-[14px] `} >Ohoo! Something went wrong</p>
                //   }
                //   {
                //     resData.empty && (<p className={` text-red-500 font-[500] text-[14px] `} >Please enter your email</p>)
                //   }
                // </div>
              }
            
             
              {
                fourthRow.map((el,i)=>(
                  <div className='hidden laptop:block ' key={i} ><Link to={el.link}><div key={i} className='text-dark-gray text-[14px] font-[400] ' >{el.title}</div></Link></div>
                ))
              }

              {
                fourthRow.map((el,i)=>(
                  <div className='block laptop:hidden ' key={i} ><Link to={el.link}><div key={i} className='text-dark-gray text-[14px] font-[400] ' >{el.title}</div></Link></div>
                ))
              }

          </div>
        </div>

      </div>

        {/* {
          location.pathname != '/' && <div className='block laptop:hidden  mx-auto w-[80%] laptop:w-[100%] laptop:mb-[20px] ' >
            <h2 className='text-[17px] mt-[20px] font-[500] text-center laptop:text-left text-off-black   ' >Subscribe to Our Newsletter</h2>
            <div className='flex items-center mt-[10px] gap-1 w-[100%]  ' >
                <div className={`${styles.inputContainer} w-[100%] `}>
                    <span className={styles.inputIcon}><FiUser /></span>
                    <input type="email" className={styles.inputField} id="username" placeholder="Enter Your Email" />
                </div>
                <button className='text-[13px] bg-off-black text-white rounded-md h-[30px] px-[10px] ' >Subscribe</button>
            </div>
          </div>
        } */}

      <hr className='my-[30px] w-[90%] laptop:w-[80%] mx-auto ' />


      <div className='w-[90%] laptop:w-[80%] mx-auto  flex flex-col laptop:flex-row laptop:justify-between ' >
        <div className='text-dark-gray text-[13px] font-[400] text-center laptop:text-left ' >© 2024 Techboxai Technologies Pvt. Ltd.. All rights reserved.</div>

        
        {/* {
          location.pathname !== '/' && (
            <div className='hidden laptop:flex gap-x-4' >
              <div className='text-dark-gray text-[14px] ' ><Link to={'/terms-of-service'} >Terms of Service</Link></div>
              <div className='text-dark-gray text-[14px] ' ><Link to={'/privacy-policy'}>Privacy Policy </Link></div>
              <div className='text-dark-gray text-[14px] ' ><Link to={'/moderation-policy'}>Moderation Policy</Link></div>
            </div>
          )
        } */}
        
        
        
        
        <div className=' flex justify-center laptop:justify-end gap-x-[20px] text-[20px] mt-[30px] laptop:mt-0 ' >
            <div className='w-[20px] h-[20px] cursor-pointer ' >
              <Link to={'https://x.com/personate_ai'} target='_blank'>
                <img src="/x-icon.png" alt="x-persoante" /> 
              </Link>
            </div>
            <div><Link to='https://www.linkedin.com/company/74974624' target='_blank' >{<FaLinkedin />}</Link></div>
            <div><Link to='https://www.instagram.com/personate_ai/' target='_blank' >{<FaInstagram />}</Link></div>
        </div>
      </div>
      
    </div>
  )
}
export default Footer
