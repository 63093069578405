import React from 'react'
import styles from './ethics.module.css'
// style={{background: "linear-gradient(229deg, rgba(159, 61, 242, 0.55) 28.89%, rgba(244, 47, 189, 0.55) 67.12%), linear-gradient(76deg, #03C9FE 7.52%, rgba(159, 61, 242, 0.00) 74.23%)"}}
const Responsible = () => {
  return (
    <div className={`bg-white bg-opacity-30 font-redHat  w-[90%] laptop:w-[80%] mx-auto rounded-xl p-[20px] relative`}  >
      
        <div  className='' >
          {/* <div className='w-[500px] h-[500px] blur-[50px] bg-gradient-to-r from-blue-100 via-red-50 to-pink-100 rounded-full absolute top-0 left-0 -z-10 ' ></div> */}
            <h1 className='text-off-black text-[26px] laptop:text-[32px] font-[500] text-center mb-[30px] '>Responsible Use of Technology</h1>

            <div className='w-[100%] laptop:w-[90%] mx-auto ' >
                <h2 className='text-off-black text-[20px] laptop:text-[24px] font-[500]' >Leading with Responsibility</h2>
                <p className='text-dark-gray mb-[50px] text-[16px] ' >At Personate, we embrace our responsibility to lead with integrity. As pioneers in artificial intelligence, we believe AI technologies must be grounded in strong ethical principles. We've woven these values into our organization, shaping our policies, products, and solutions to reflect our commitment to ethical AI.</p>

                <h2 className='text-off-black text-[20px] laptop:text-[24px] font-[500]' >Rigorous Screening Process</h2>
                <p className='text-dark-gray mb-[50px] text-[16px] ' >Every input undergoes a meticulous internal screening process before it reaches our trusted clients. This ensures that our products are not only innovative but also uphold the highest standards of integrity and reliability.</p>
            </div>

            <div className='w-[100%] laptop:w-[90%] mx-auto block laptop:flex gap-[40px] ' >
              {/* CONTENT AUTHENCITY */}
              <div>
                <h2 className='text-off-black text-[20px] laptop:text-[24px] font-[500]' >Content Authenticity Initiative</h2>
                <p className='text-dark-gray mb-[50px] text-[16px]' >We are committed to maintaining transparency and authenticity in all our AI-generated content.</p>
                
                <div className='h-[200px] w-[200px] ' ><img className='w-[100%] h-[100%] object-contain' src='https://personate-data-v2.s3.amazonaws.com/website+data+/CAI_Lockup_RGB_Black_social-removebg-preview_kumoe0.png' alt='Personte-Content-Authencity'/> </div>
              </div>

              {/* GDPR */}
              <div>
                <h2 className='text-off-black text-[20px] laptop:text-[24px] font-[500]' >GDPR Compliance</h2>
                <p className='text-dark-gray mb-[50px] text-[16px]' >Our practices are fully compliant with GDPR regulations, ensuring the privacy and security of all data.</p>

                <div className='h-[200px] w-[100px] my-auto ' ><img className='w-[100%] h-[100%] object-contain' src='https://personate-data-v2.s3.amazonaws.com/website+data+/PNG_GDPR-e1672263252689_mgs7l7.png' alt='Personate-GDPR'/> </div>
              </div>
            </div>

        </div>
    </div>
  )
}

export default Responsible
