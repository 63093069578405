import React from 'react'

export const Heading = ({title,desc}) => {
  return (
    <div>
        <h2 className='text-[22px] laptop:text-[30px] text-off-black font-[700] tracking-wide ' >{title}</h2>
        <p className='text-[16px] laptop:text-[18px] text-dark-gray ' >{desc}</p>
    </div>
  )
}
