import React from 'react'
import { GoDotFill } from "react-icons/go";

const ModerationPrivacyPolicy = () => {
  return (
    <div className='bg-[#F8F8F8] font-redHat py-[50px] ' >
      <div className='w-[90%] laptop:w-[80%] mx-auto  ' >
            <h1 className='text-[36px] font-[700] text-[#232833] text-center mb-[20px]  ' >Acceptable Use and Moderation Policy</h1>
          
            <h2 className='text-[28px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >1. Introduction</h2>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >At Personate, our Acceptable Use and Moderation Policy underscores our commitment to fostering trust, inclusivity, and creativity. This policy, which applies to your access to and use of our Services is designed to promote their safe, respectful, and inclusive use</p>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] '>Attempts to circumvent or violate this policy will not be tolerated. Violations of this policy may result in the removal of violative content or the suspension or termination of access to our Services.</p>
        
            <h2 className='text-[28px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >2. Avatars</h2>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] '>Avatars created on the Personate Services, including the content used to create such avatars, must respect the rights of others, including individuals depicted and only be used for permissible purposes. We offer a variety of avatar creation options, including public avatars from our library, photo avatars, and custom avatars, which include both instant and studio avatars.</p>
        

            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >2.1. Ownership, Consent and Violations</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' ><span className='font-[700] ' >Ownership:</span> As between us and you, you own the rights to photo, or custom avatars you create. You are responsible for ensuring that your creation, use, or sharing of the avatar does not violate any laws or rights of third parties or our Terms or Acceptable Use and Moderation Policy. This includes ensuring that the avatar is only created, used, or shared, in a manner consistent with the rights you have obtained to use the Avatar, including the consent of the person depicted in the Avatar or the holder of any rights in the depiction.</p>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' ><span className='font-[700] '>Consent for Custom Avatars:</span>Consent for Custom Avatars: In the case of creating custom avatars, you are required to obtain the explicit consent of the individual being represented (the “Actor”). Unless explicit consent has been provided, creating avatars of other individuals is strictly prohibited. You are also responsible for ensuring that you have the consent of the Actor for your use or sharing of the avatar and any content generated based on the avatar. The Actor reserves the right to request removal of their likeness from our Services at any time. You are responsible for honoring any request by or on behalf of an Actor to remove content depicting the Actor wherever you have published such depictions. An Actor may also contact our moderation team at moderation (at) personate.ai to request removal of content depicting them from our Services.</p>


            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >2.2. Prohibited Avatars</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >To maintain a safe and respectful environment on our Services, the creation of certain types of avatars is strictly prohibited. This includes avatars that:</p> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span> Display illegal, defamatory, sexually explicit, pornographic, violent, threatening, abusive, inflammatory, harmful, hateful, cruel, insensitive, or deceptive imagery, including through likenesses, symbols, or insignia;</div>
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Represent or appear in the sole discretion of Personate to represent individuals under the age of 18;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Represent real individuals, including celebrities or public figures, without their explicit consent; or</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Represent or appear in the sole discretion of Personate to infringe copyrighted images without the consent of the copyright owner.</div> 
            <h2 className='text-[28px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >3. Video Content</h2>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Video content created on the Services must respect the rights of others, and only be used for permissible purposes.</p> 


            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >3.1. Non-Permissible Content</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Non-permissible content refers to any video content that is not allowed to be uploaded to or generated using our Servicesunder any circumstances. This includes, but is not limited to:</p> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Content that exploits or harms children;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Pornographic or sexually explicit material;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Violence, including graphic or gratuitous violence, or content that promotes violence or harm to individuals or animals;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Hate speech, discrimination, or inflammatory content that harasses or threatens individuals or groups, including based on attributes such as race, religion, gender, age, nationality, or sexual orientation;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Content promoting unlawful or illegal activities, including but not limited to drug use, hacking, or other criminal activities;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Misinformation or disinformation, including fake news, hoaxes, or conspiracy theories;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Personal and confidential information, such as credit card numbers, social security numbers, or non-public personal contact information;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Legal, medical, or financial advice without legally required qualifications;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Political campaigning or lobbying government officials;</div> 
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Fraudulent or deceptive content, including, but not limited to scams,spam, plagiarism, and fake review or grassroots support generation;</div> 
            <div className='text-[#232833] text-[16px] font-[500] ml-3 ' >OR</div>
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px] flex  '> <span className='mt-2 mr-1 text-[12px] ' >{<GoDotFill />}</span>Other content that we may determine, in our sole discretion, is inconsistent with Personate’s values.</div> 
      

            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] '>3.2. Conditional Content</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Conditional content refers to types of content that are generally allowed to be uploaded to or generated using our Services, but with certain restrictions. These types of content can be sensitive and may involve themes or subjects that could potentially cause discomfort, disagreement, or distress. Such content can be created using custom avatars only. Personate in its sole discretion may determine whether content is Conditional or Non-Permissible content. Conditional content includes, but is not limited to:</p>
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px]  '><span className='underline' >Informational and educational content about sex:</span> We understand the importance of sex education, but any content discussing this topic should be strictly educational, respectful, and not explicit or inappropriate. It should also be clearly labeled at the outset of the video as mature content, and not targeted at minors.</div>
            <div className='text-[18px] text-[#232833] font-[500] mb-[10px]  '><span className='underline' >Religious content:</span> We respect all religions and belief systems, but any content discussing religion should be respectful and not promote division, discrimination, or hate.</div>
      
      
            <h2 className='text-[28px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >4. Video Content</h2>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >At Personate, we utilize a two-pronged approach to content moderation to promote the upload and creation of content permitted under the terms described above</p> 

            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >4.1. Automated Review</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >We use automated moderation tools, powered by machine learning algorithms, to scan and flag content uploaded to or generated using our Services that is potentially non-permissible.</p> 
            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >4.2. Email Reports</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >If you believe that content uploaded to or created using our Services contains non-permissible content, please email us at moderation (at) Personate.ai with: a link to or copy of the content; a brief description of the reason you believe the content is not permissible under this policy; and contact information to reach you regarding your communication.</p> 
            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >4.3. Manual Review</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Any content, including content flagged through the processes described above may also be reviewed by human moderators.</p> 
            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >4.4. Decision and Enforcement</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >If content is found to breach our policies, Personate may, in its sole discretion, taking into account the severity of the violation: remove the content or prevent the generation of the content on our Services; notify the user about the decision; temporarily or permanently suspend the user’s access to our Services; report the content to legal authorities; or such other actions as Personate determines may be appropriate under the circumstances.</p> 
            <h3 className='text-[20px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >4.5. Appeals Process</h3>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Users can appeal moderation decisions they believe to be unfair by emailing us at moderation (at) personate.ai and providing: a description of the content that was deemed to violate this policy; a description of the reason that you believe this decision was in error; contact information regarding your appeal. Personate will review and respond to appeals in its sole discretion. The submission of an appeal does not guarantee that you will receive a response. Where possible, appeals will be reviewed by a moderator that was not involved in the original decision.</p> 
            
            <h2 className='text-[28px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >5. Updates and Changes to the Acceptable Use and Moderation Policy</h2>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Personate may revise these guidelines from time to time, including, but not limited to, modifications in response to: technical advancements, societal changes, legal requirements, or user feedback. </p> 
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >When changes are implemented, they come into effect immediately upon being posted on this page, and your continued use of our Services constitutes acceptance of this policy, including any modifications. If we make material modifications to this policy we will make reasonable efforts to communicate the changes to you, such as via email or notifications on the Services. </p> 
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >Users are encouraged to review this section periodically for any updates.</p> 
            <h2 className='text-[28px] text-[#232833] font-[700] mb-[10px] mt-[30px] ' >6. Contact</h2>
            <p className='text-[18px] text-[#232833] font-[500] mb-[20px] ' >We value your feedback on our content moderation processes and policies. Please feel free to share your thoughts and suggestions by emailing us at moderation (at) personate.ai.</p> 

      </div>
    </div>
  )
}

export default ModerationPrivacyPolicy

