import React from 'react'
import { Link } from 'react-router-dom'

const TabInsideContent = ({title,desc,icon,link,setMenuOpen,setHoverTab}) => {

  const handleClick = () => {
    setMenuOpen(false);
    setHoverTab(0)
  };

  return (
    <Link to={link} onClick={handleClick}>
      <div className={`font-redHat group bg-white flex p-[5px] w-[100%]  gap-x-3`} >

        <div className='text-[22px] flex items-center justify-center'>
          <div className='bg-gray-50 text-gray-700 font-[400] rounded-full p-2' >{icon}</div>
        </div>

        <div>
          <div className='flex text-[14px] text-gray-700 tracking-wide font-[700]' >{title}</div>
          <p className='text-[13px] tracking-wider font-[400] '>{desc}</p>
        </div>

      </div>

    </Link>
  )
}

export default TabInsideContent
