
import React, { useRef, useState } from 'react'
import {FaPlay} from 'react-icons/fa'
import {FaCircle} from 'react-icons/fa'
import { TwoButtons } from '../../GlobalComponents/TwoButtons'
import { Heading } from './Heading';
export const Step3 = ({videoURL,title,desc,points,btn1name,btn2name,btn1link,btn2link,icon1,icon1size,icon2,icon2size,image}) => {
    const videoRef = useRef(null)
    const [isPlay,setIsPlay] = useState(false)

    const handlePlay = ()=>{
        if (videoRef.current) {
          if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlay(()=>true)
          } else {
            videoRef.current.pause();
            setIsPlay(()=>false)
          }
        }
        // console.log("plau")
    }

    const handleVideoEnd = ()=>{
        setIsPlay(false)
    }
  
    return (
    <div className='block laptop:flex mx-auto font-redHat laptop:gap-[40px] mb-[70px] '>
        {/* LEFT DIV */}
        <div className='w-[100%] laptop:w-[60%] mb-[30px] laptop:mb-0 '>
            <div className='mb-[20px] ' ><Heading title={title} desc={desc}/></div>

            <ul>
                {
                    points.map((el,i)=>(
                        <li key={i} className='mb-[10px] laptop:mb-[15px] flex text-[16px] tracking-wide text-dark-gray '>
                            <span className='mr-3 text-[8px] pt-2  text-blue-500' >{<FaCircle/>}</span>
                            <span>
                                <span className='font-[700] mr-1 ' >{el.title}</span>
                                <span>{el.desc}</span>
                            </span>
                        </li>
                    ))
                }
            </ul>
            
            <TwoButtons
                btn1name={btn1name}
                btn2name={btn2name}
                btn1link={btn1link}
                btn2link={btn2link}
                icon1={icon1}
                icon2={icon2}
                icon1size={icon1size}
                icon2size={icon2size}
            />
        </div>

        {/* RIGHT DIV */}
        <div className='w-[100%] laptop:w-[40%] flex items-center' >
            {
                !videoURL && <div className='w-[100%] mx-auto ' >
                    <img className='rounded-xl w-[100%] h-[100%] object-contain ' src={image} alt="" />
                </div>
            }

            {
                videoURL && <div className='relative cursor-pointer rounded-xl overflow-hidden ' onClick={handlePlay} >
                    <video onEnded={handleVideoEnd} ref={videoRef} className='w-[100%] h-[100%] ' src={videoURL} ></video>
                    <button  className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white   ${isPlay?'text-[22px] hidden':'text-[17px]'} h-[45px] w-[45px] laptop:h-[50px] laptop:w-[50px] bg-black bg-opacity-50 `} >{<FaPlay/>}</button>
                </div>
            }
        </div>
    </div>
  )
}
