import React from 'react'
import { GoArrowRight } from "react-icons/go";
import { Link } from 'react-router-dom';
import { UIforSavings } from '../script/backgroundUI';
import { typeform } from '../script/buttonLinks';

const Savings = () => {
  const conicGradientStyle = {
    background: 'conic-gradient(from 90deg at 50% 50%, rgb(165, 180, 252), rgb(79, 70, 229))',
  };
  return (
    <div className='w-[90%] laptop:w-[100%] mx-auto rounded-xl laptop:rounded-none py-[50px] font-redHat px-[20px] laptop:px-0 ' style={UIforSavings} >
      <h2 className='laptop:text-[32px] text-[28px] text-white text-center  mb-[10px] font-[500] ' >AI-Powered Video Generation</h2>
      <p className='laptop:text-[18px] text-[14px] text-lite-gray text-center mb-[30px] ' >Boost Your ROI, Simplify Video Production, and Craft Unforgettable Videos with Personate!</p>
      <div className='flex items-center justify-center ' >
        <Link to={typeform} target='_blank' >
        <button className={`transition ease-in-out duration-300 text-[14px] tracking-wide rounded-lg font-[600]  px-[17px] laptop:px-[20px] py-[7px] shadow-md laptop:py-[10px] text-black bg-white bg-opacity-70 hover:bg-opacity-50 flex justify-center items-center  `} >Get Started</button>
        </Link>
      </div>
    </div>
  )
}

export default Savings

// <div className='mb-[15px] laptop:mb-0 relative font-redHat w-[90%] laptop:w-[100%] rounded-lg laptop:rounded-none mx-auto h-[400px] laptop:h-[300px] ' style={UIforSavings} >
    //   <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[70%] ' >
    //     <div className='mb-[20px] laptop:mb-0 ' >
    //       <h2 className='laptop:text-[30px] text-[28px] text-white  font-[500] text-center  mb-[10px] laptop:mb-0 ' >AI-Powered Video Generation</h2>
    //       <h2 className='laptop:text-[18px] text-[14px] text-white text-center ' >Boost Your ROI, Simplify Video Production, and Craft Unforgettable Videos with Personate!</h2>
    //     </div>
    //     <div className='flex items-center justify-center ' >
    //       <Link to='https://1i4emckhg1l.typeform.com/to/TPYwKpUc?typeform-source=personate.ai' target='_blank' >
    //         <button className='rounded-lg mx-auto laptop:mx-0 bg-white hover:bg-gray-200 text-black laptop:text-[16px] text-[16px] flex items-center py-[8px] px-[25px] shadow-lg font-[500]  ' >Get Started<span className=' ' ><MdArrowRightAlt /></span></button>
    //       </Link>
    //     </div>
    //   </div>
    // </div>