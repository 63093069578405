import React, { useRef, useState } from 'react'
import { TwoButtons } from './TwoButtons'
import {FaPlay} from 'react-icons/fa'
export const Introduction = ({mb='mb-[50px]',videoURL,title,desc,btn1name,btn1link,btn2name,btn2link,icon1,icon2,icon1size,icon2size,youtube,image,UI,poster}) => {
    const videoRef = useRef(null)
    const [isPlay,setIsPlay] = useState(false)

    const handlePlay = ()=>{
      setIsPlay(!isPlay)
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setIsPlay(()=>true)
        } else {
          videoRef.current.pause();
          setIsPlay(()=>false)
        }
      }
    }

    const handleVideoEnd = ()=>{
        setIsPlay(false)
    }
    
  return (
    <div style={UI} className={mb} >
    <div className='w-[90%] laptop:w-[80%] block laptop:flex mx-auto py-[40px] laptop:py-[80px] font-redHat'>
      {/* LEFT DIV */}
      <div className='flex items-center w-[100%] laptop:w-[45%] mb-[50px] laptop:mb-0' >
        <div className='my-auto pr-0 laptop:pr-[10px] ' >
          <h1 className={`text-[24px] font-[600] laptop:text-[32px] text-off-black tracking-wider `} >{title}</h1>
          <p className='text-[13px] laptop:text-[16px] mb-[20px] laptop:mb-[30px] text-dark-gray font-[400] ' >{desc}</p>

          <TwoButtons
            btn1name={btn1name}
            btn1link={btn1link}
            btn2name={btn2name}
            btn2link={btn2link}
            icon1={icon1}
            icon1size={icon1size}
            icon2={icon2}
            icon2size={icon2size}
          />
        </div>
      </div>

        {/* RIGHT DIV */}

        <div className='w-[100%] tablet:w-[80%] laptop:w-[50%] mx-auto overflow-hidden relative rounded-xl' >
            
            {/* FOR IMAGES AND YOUTUBE VIDEO */}
            {
              !videoURL &&
                <div className='relative overflow-hidden  pt-[56.25%]'>
                {
                  !isPlay && (
                    <img  src={image} className='z-30 h-[100%] w-[100%] cursor-pointer absolute top-0 left-0 bottom-0 right-0' alt="images" />
                  )
                }
                {
                  !isPlay && (
                    <button onClick={()=>setIsPlay(!isPlay)} className={`${youtube?'block':'hidden'} flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-primary bg-white  text-[19px] h-[50px] w-[50px] `} >{<FaPlay/>}</button>
                  )
                }

                {
                  isPlay && (
                      <iframe
                        className='z-0 w-[100%] h-[100%] absolute top-0 left-0 bottom-0 right-0 object-fill'
                        src= {youtube}
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen
                      ></iframe>
                    )
                }
              </div>
            }


            {/* FOR VIDEO */}
            {
              videoURL && <div className='relative cursor-pointer rounded-xl w-[100%] 'onClick={handlePlay} >
                <video poster={poster} onEnded={handleVideoEnd} ref={videoRef} className='w-[100%] h-[100%] object-cover ' src={videoURL} ></video>
               

                <button  className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-80  h-[45px] w-[45px] ${!isPlay?'block':'hidden'} `} >{<FaPlay/>}</button>
              </div>
            }
        </div>
    </div>
    </div>

  )
}
