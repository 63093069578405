import React from 'react'
import Savings from '../GlobalComponents/Saving';
import { FourTitleBoxIcon } from '../GlobalComponents/FourTitleBoxIcon';

import { register, typeform } from '../script/buttonLinks';

import { FaVoicemail } from "react-icons/fa";
import { RiVoiceRecognitionLine } from "react-icons/ri";
import { BiUserVoice } from "react-icons/bi";
import { HeadingAndSubheading } from '../GlobalComponents/HeadingAndSubheading';
import { FaCheck } from "react-icons/fa6";
import { TwoButtons } from '../GlobalComponents/TwoButtons';
import { MultilingualVoices } from '../myComponents/AIVoices/MultilingualVoices';
import Comparision from '../GlobalComponents/Comparision';
import { AllVoices } from '../myComponents/AIVoices/AllVoices';
import { Introduction } from '../myComponents/AIVoices/Introduction';
import { baseURL } from '../script/baseURL';
import { Helmet } from 'react-helmet';
import { Newsletter } from '../myComponents/HomePageComponents/Newsletter';


const FeaturesLanguagePage = () => {


    return (
        <div>
            <Helmet>
              <meta charSet="utf-8" />
              <title>AI Voices | Replicate and Customize Voices</title>
              <link rel="canonical" href={`${baseURL}/features/ai-voices`} />
              <meta name="description" content="Access hyper-realistic AI voices in 60+ languages. Enhance your narratives with emotional clarity and realistic voice options." />
            </Helmet>

            <Introduction/>

            <FourTitleBoxIcon
                title={`Find the Perfect AI Voice for Your Brand`}
                desc={`Explore our library or replicate your voice for consistent brand representation and messaging`}
                data={data}
                noOfColumuns='laptop:grid-cols-3'
            />

            <div className='laptop:w-[80%] w-[90%] mx-auto font-redHat pb-[50px] laptop:pb-[100px] pt-[50px] '>
              <HeadingAndSubheading 
                heading={`Dynamic Features That Bring AI Voices to Life`} 
                subHeading={`Our AI Voices come equipped with dynamic features that enhance their realism and adaptability`} 
              />

              <div className={`grid grid-cols-1 laptop:grid-cols-3 gap-[40px]`}>
                {
                    voiceFeatures.map((el,i)=>(
                        <div className='p-[20px] rounded-lg border ' key={i} >
                            <h3 className='text-[18px] font-[500] text-off-black mb-[10px] flex gap-2 items-center ' ><span className='text-[18px] text-blue-500  ' ><FaCheck /></span> {el.title}</h3>
                            <p className='font-[400] text-[14px] text-dark-gray ' >{el.desc}</p>
                        </div>
                    ))
                }
              </div>
              <div className='flex justify-center mt-[30px] ' >
                <TwoButtons
                  btn1name={`Discover the Possibilities`}
                  btn2name={`Book a Demo  `}
                  btn1link={register}
                  btn2link={typeform}
                />
              </div>
            </div>

            <AllVoices/>
            <MultilingualVoices/>
            <Comparision 
              heading={'Streamline Your Content Creation With AI Voices'} 
              subHeading={'Embrace AI Voices to simplify and accelerate your content production process, achieving consistency and scalability effortlessly'}
              withPersonate={withAIVoices}
              withImage={'/Comparision/Normal.png'}
              withOutImage={'https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled+design+(18).png'}
              withOutPersonate={withOutAIVocies}
              title2={'With AI Voices'}
              title1={'Without AI Voices'}
            
            />
            <div className='my-[30px] ' ><Newsletter/></div>
            <Savings/>
        </div>
    )
}

export default FeaturesLanguagePage
let data = [
    {
      "title": "Cloned Voice",
      "desc": " Perfectly replicates specific voices, offering consistent brand representation across all platforms",
      icon : <FaVoicemail />
    },
    {
      "title": "Synthetic Voice",
      "desc": "Custom-built from advanced AI algorithms, these voices provide versatile audio solutions for any scenario",
      icon : <RiVoiceRecognitionLine />
    },
    {
      "title": "Emotion-Enhanced Voices",
      "desc": "Engineered to convey a broad spectrum of emotions, enhancing the realism and impact of interactions",
      icon : <BiUserVoice />
    }
  ]

const voiceFeatures = [
    {
      title: "Tonal Adjustment",
      desc: "Adjust the pitch and speed of speech to suit your video’s tone, ensuring the voiceover sounds just right for your audience"
    },
    {
      title: "Custom Expressive Modulation",
      desc: "Customize your AI voice to deliver the exact emotion and expressiveness your content requires, making your message more impactful"
    },
    {
      title: "Linguistic Versatility",
      desc: "Offers support for over 60 languages and accents, broadening your reach and making your content resonate with audiences worldwide"
    }
];

const withAIVoices = [
  'On-demand availability',
  'Easily scalable for various projects',
  'Broad multilingual support from a single source',
  'Seamless integration with existing systems',
  'Consistent brand voice worldwide',
]

const withOutAIVocies = [
  'Dependency on talent schedules',
  'Complexity and cost increases with scale',
  'Multiple talents needed for language diversity',
  'Requires extensive setup and coordination',
  'Brand voice varies with different actors',
]



