import React, { useRef, useState } from 'react'
import { languageAvatars} from '../../script/homepageData'

import {FaPlay} from 'react-icons/fa'

const LanguageAvatars = () => {

    const videoRef = useRef(null)
    const [isPlay,setIsPlay] = useState(false)
   
    const [language,setLanguage] = useState(0)
    const [index,setIndex] = useState(0)

    const handleLanguage = (value) => {
        setIsPlay(()=>false)
        if(value == 0){
          setLanguage(0)
          setIndex(0)
        }
        else if(value == 1){
            setLanguage(1)
            setIndex(1)
        }
        else if(value == 2){
            setLanguage(2)
            setIndex(2)
        }
        else if(value == 3){
            setLanguage(3)
            setIndex(3)
        }
        else if(value == 4){
            setLanguage(4)
            setIndex(4)
        }
        else if(value == 5){
            setLanguage(5)
            setIndex(5)
        }
    }

    const handlePlay = ()=>{
        if (videoRef.current) {
          if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlay(()=>true)
          } else {
            videoRef.current.pause();
            setIsPlay(()=>false)
          }
        }
    }

    const handleVideoEnd = ()=>{
        setIsPlay(false)
    }

  return (
    <div className='w-[90%] tablet:w-[90%] laptop:w-[80%] flex flex-col-reverse laptop:flex-row  justify-between mx-auto font-redHat mb-[50px] px-0 laptop:px-[20px] '>

      

      {/* LEFT DIV */}
      <div className='relative w-[100%] laptop:w-[60%] mt-[30px] tablet:mt-0 laptop:mt-0 p-0 tablet:p-[30px] laptop:p-[30px] desktop:px-[100px] ' >

        <div className='border-[2px] border-gray-300  cursor-pointer relative overflow-hidden rounded-md transition ease-in-out duration-200 h-[300px] tablet:h-[360px] laptop:h-[350px] w-full mb-[15px] laptop:mb-0 '>
            <video poster='/multiligualAvatarPoster.png' alt='Multilingual Personate Avatar' onClick={handlePlay} ref={videoRef} onEnded={handleVideoEnd} className='transition ease-in-out duration-200 w-full h-full object-cover ' src={languageAvatars[index].videoLink} ></video>
           
            <button onClick={handlePlay} className={`flex items-center  z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-white bg-gray-600 bg-opacity-60  h-[45px] w-[45px] ${!isPlay?'block':'hidden'} `} >{<FaPlay/>}</button>
        </div>

        {/* LANGUAGE BUTTONS FOR TABLET AND LAPTOP */}
        <div className='hidden tablet:flex laptop:flex scroll-smooth items-start justify-start flex-col gap-y-2 tablet:absolute tablet:bottom-[70px] laptop:bottom-[40px] left-0 desktop:left-[50px]' >
            {
              languageAvatars.map((el,i)=>(
                <button key={i} onClick={()=>handleLanguage(i)} className={` ${language==i?'border-2 border-blue-500 hover:border-blue-500 tablet:w-[167px] laptop:w-[167px] bg-blue-50 ':'tablet:w-[150px] laptop:w-[150px] border border-gray-200 bg-white text-gray-700 hover:border-gray-400'} transition-width duration-300 flex  px-[18px] py-[7px] rounded-md hover:text-black`} > <img className='w-[20px] my-auto mr-[10px]' src={el.flag} alt={`Personate ${el.name} AI Voice `} />{el.language}</button>
              ))
            }
        </div>

        {/* LANGUAGE BUTTON FOR MOBILE */}
        <div className='flex tablet:hidden laptop:hidden scroll-smooth items-center justify-start overflow-x-auto flex-row gap-x-2' >
          <div className="flex">
            
            {
              languageAvatars.map((el,i)=>(
                <button key={i} onClick={()=>handleLanguage(i)} className={` ${language==i?'border-2 border-blue-500 hover:border-blue-500 bg-blue-50 ':'border border-gray-200 bg-white text-gray-700 hover:border-gray-400'} transition-width duration-300 flex  px-[18px] pr-[35px] py-[5px] rounded-md hover:text-black mr-2`} > <img className='w-[20px] my-auto mr-[10px]' src={el.flag} alt={`Personate ${el.name} AI Voice `} />{el.language}</button>
              ))
            }

          </div>
        </div>
        
      </div>

      {/* RIGHT DIV */}
      <div className='flex flex-col justify-center w-[100%] laptop:w-[40%]' >
        <div className='pr-[10px] laptop:pr-0 ' >
          <h2 className='text-[20px] laptop:text-[30px] font-[500] text-off-black ' >Realistic AI Voices</h2>
          <p className='text-[15px] laptop:text-[16px] font-[400] text-dark-gray tracking-wide mt-[10px] ' >Enrich your narratives with hyper-realistic AI Voices, including our advanced AI Voice Cloning, tuned to deliver your message with clarity and emotion</p>
        </div>
      </div>

    </div>
  )
}

export default LanguageAvatars

// poster='https://res.cloudinary.com/dxvi69tlz/image/upload/v1698148194/bizOps/wesbite_asset/Images%20of%20avatars/Screenshot_2023-10-24_113016_moufju.png'