import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { useCasesData } from "../../script/homepageData";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { HeadingAndSubheading } from "../../GlobalComponents/HeadingAndSubheading";
import { TwoButtons } from "../../GlobalComponents/TwoButtons";
import { GoArrowRight } from "react-icons/go";

const UseCases = () => {
  const [index, setIndex] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [slideOpen, setSlideOpen] = useState(false);

  const handleTabs = (value) => {
    setIsPlay(false);
    setIndex(value)
    setSlideOpen(false);
  };

  return (
    <div className="w-[90%] laptop:w-[80%] mx-auto mb-[50px] font-redHat">
      <HeadingAndSubheading heading='Versatile Video Solutions for Every Industry, Department, and Team' />
      {/*LAPTOP-TABBAR */}
      <div className="w-full hidden laptop:flex p-[6px] mb-[20px] rounded-lg border border-gray-100 bg-white">
        {useCasesData.map((el, i) => (
          <div
            key={i}
            onClick={() => handleTabs(i)}
            className={`flex justify-center items-center py-[10px] text-[14px] font-[500] rounded-md cursor-pointer  ${
              index == i
                ? "text-primary bg-primary-transparency font-[700]"
                : "text-dark-gray"
            }
              ${i == 0 ? "min-w-[200px] laptop:min-w-[20%]" : ""} 
              ${i == 1 ? "min-w-[170px] laptop:min-w-[18%]" : ""}
              ${i == 2 ? "min-w-[180px] laptop:min-w-[18%]" : ""}
              ${i == 3 ? "min-w-[250px] laptop:min-w-[26%]" : ""}
              ${i == 4 ? "min-w-[180px] laptop:min-w-[18%]" : ""}`}
          >
            <span className="font-bold mr-1">{el.icon}</span>
            {el.tab}
          </div>
        ))}
      </div>

      {/* MOBILE AND TABLET DROPDOWN */}
      <div
        onClick={() => setSlideOpen(!slideOpen)}
        className={`flex laptop:hidden w-full mx-auto rounded-md  bg-white text-center py-[8px] px-[10px] text-[14px] font-[500] text-primary border border-gray-100 ${
          slideOpen ? "rounded-b-none border-b-0" : ""
        } `}
      >
        <span className="mt-1 mr-2">{useCasesData[index].icon}</span>{" "}
        {useCasesData[index].tab}{" "}
        <span
          className={`ml-auto mt-1 ${
            slideOpen ? "rotate-180" : ""
          } transition-transform transform ease-in-out duration-300 `}
        >
          {<FiChevronDown />}
        </span>{" "}
      </div>
      <div
        className={`${
          slideOpen
            ? "custom-slide-in-up overflow-auto h-auto rounded-t-none"
            : "overflow-hidden h-0 border-none"
        } rounded-md bg-white border border-gray-100 text-[14px] absolute z-50 w-[90%] text-gray-400 font-[500] `}
      >
        {useCasesData.map((el, i) => (
          <div
            key={i}
            onClick={() => handleTabs(i)}
            className={`flex py-[10px] pl-[20px] hover:text-primary hover:bg-primary-transparency `}
          >
            <span className="font-bold mt-1 mr-2 ">{el.icon}</span>
            {el.tab}
          </div>
        ))}
      </div>

      <div className="block laptop:flex gap-[5%] mt-[15px] laptop:mt-0 ">
        {/* LEFT DIV`` */}
        <div className="w-[100%] laptop:w-[40%] my-auto laptop:pr-[10px] pr-0 ">
          <div>
            <h3 className="text-[20px] laptop:text-[30px] font-[500] text-off-black ">
              {useCasesData[index].tab}
            </h3>
            <p className="text-[15px] laptop:text-[16px] font-[400] text-dark-gray tracking-wide mt-[10px] ">
              {useCasesData[index].title}
            </p>
            <Link to={"features/ai-avatars"}>
              <button className="flex items-center text-off-black gap-1 text-[16px] border rounded-lg py-[12px] px-[18px] mt-[10px] laptop:mt-[25px] ">
                Learn more{" "}
                <span>
                  <GoArrowRight />
                </span>
              </button>
            </Link>
          </div>
        </div>

        {/* RIGHT DIV */}
        <div className="w-[100%] laptop:w-[60%] mt-[15px] laptop:mt-0 ">
          <div className="relative overflow-hidden  pt-[56.25%] rounded-xl ">
            {!isPlay && (
              <img
                onClick={() => setIsPlay(!isPlay)}
                src={useCasesData[index].image}
                className="z-30 h-[100%] w-[100%] cursor-pointer absolute top-0 left-0 bottom-0 right-0"
                alt="images"
              />
            )}
            {!isPlay && (
              <button
                onClick={() => setIsPlay(!isPlay)}
                className={`flex items-center z-30 justify-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute inset-0 text-primary bg-white  text-[19px] h-[50px] w-[50px] `}
              >
                {<FaPlay />}
              </button>
            )}

            {isPlay && (
              <iframe
                className="z-0 w-[100%] h-[100%] absolute top-0 left-0 bottom-0 right-0 object-fill"
                src={`${useCasesData[index].youtubeLink}?autoplay=1`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
