import React, { useContext, useState } from 'react'
import {TbChevronDown} from 'react-icons/tb'
import HoveringInfoTab from './HoveringInfoTab';
import TabInsideContent from './TabInsideContent'
import { hoverTab1Data,hoverTab2Data,hoverTab4Data,hoverTab5Data,tab1, tab2, tab3, tab4, tab5 } from '../../script/homepageData';
import { Link } from 'react-router-dom';
import 'animate.css';
import './navbar.css'
import { MyContext } from '../../MyContext/Context';

const Navbar = () => {

  const [menuOpen,setMenuOpen] = useState(false)

  const [hoverTab,setHoverTab] = useState(0)

  const {isMenuOpen,setIsMenuOpen} = useContext(MyContext)


  const handleHoverTabEnter = (value) => {
    if(value==1){
      setHoverTab(1)
    }

    else if(value==2){
      setHoverTab(2)
    }
    else if(value==3){
      setHoverTab(3)
    }
    else if(value==4){
      setHoverTab(4)
    }
    else if(value==5){
      setHoverTab(5)
    }
  }

  const handleMobileTab = (value) => {
    if(value==1){
      if(hoverTab==1){
        setHoverTab(0)
      }else{
        setHoverTab(1)
      }
    }
    else if(value==2){
      if(hoverTab==2){
        setHoverTab(0)
      }else{
        setHoverTab(2)
      }
    }
    else if(value==3){
      if(hoverTab==3){
        setHoverTab(0)
      }else{
        setHoverTab(3)
      }
    }
    else if(value==4){
      if(hoverTab==4){
        setHoverTab(0)
      }else{
        setHoverTab(4)
      }
    }
    else if(value==5){
      if(hoverTab==5){
        setHoverTab(0)
      }else{
        setHoverTab(5)
      }
    }
  }

  const handleHoverTabLeave = () => {
    setHoverTab(0)
  }

  const handleHamburger=()=>{
    setMenuOpen(!menuOpen)
    if(menuOpen){
      setIsMenuOpen(true)
    }else{
      setIsMenuOpen(false)
    }
  }




  return (
    <div className={`w-full sticky top-0 bg-white z-50 desktop:px-[10%] `}>
      <div className={`px-[20px] border-b tablet:px-[30px] laptop:px-[135px] desktop:px-0 font-redHat h-[70px] w-full bg-white  flex items-center `} >
        
        <div className='laptop:h-[50px] h-[25px]  ' >
          <Link to='/' ><img onClick={()=>setMenuOpen(false)} className='my-auto object-contain h-[100%] w-[100%] ' src="/personate-logo.png" alt="logo" /></Link>  
        </div>
        
      
        <div className={`hidden w-[100%] laptop:flex justify-center laptop:space-x-2 font-[500] text-dark-gray `}>
            
            {/* TAB-4 */}
            <div className={`flex text-[14px] hover:text-primary px-[10px] py-[30px]  duration-300 ease-in-out cursor-pointer`} onMouseEnter={()=>handleHoverTabEnter(4)} onMouseLeave={()=>handleHoverTabLeave(4)} >
              {tab4} <span className={`text-[15px] flex items-center hover:text-primary ml-1 transition-transform transform ease-in-out duration-300 font-bold ${hoverTab==4 ? 'rotate-180' : ''}`} >{<TbChevronDown/>}</span>
            </div>


            
            {/* HOVER DATA TAB 2 */}
            
            {/* <div onMouseEnter={()=>handleHoverTabEnter(2)} onMouseLeave={()=>handleHoverTabLeave(2)} className={`${hoverTab==2 ? 'grid grid-cols-3 ':'hidden'} absolute z-50 custom-slide-in shadow-2xl bg-[#FFFFFF] top-[65px] p-[10px] rounded-md`} >
              {
                hoverTab2Data.map((el,i)=>(
                  <HoveringInfoTab key={i} title={el.title} link={el.link} desc={el.desc} icon={el.icon} mb={hoverTab2Data.length-1==i?true:false}/>
                ))
              }
            </div> */}


              {/* HOVER TAB 4 DATA */}
            <div onMouseEnter={()=>handleHoverTabEnter(4)} onMouseLeave={()=>handleHoverTabLeave(4)} className={`${hoverTab==4 ? 'block':'hidden'} grid grid-cols-3 absolute z-50 custom-slide-in shadow-2xl bg-[#FFFFFF] top-[65px] p-[10px] rounded-md`} >
              {
                hoverTab4Data.map((el,i)=>(
                  <HoveringInfoTab key={i} title={el.title} link={el.link} desc={el.desc} icon={el.icon} mb={hoverTab4Data.length-1==i?true:false}/>
                ))
              }
            </div>
          {/* TAB-2 */}
          {/* <div className={`flex text-[14px] hover:text-primary px-[10px] py-[30px]  duration-300 ease-in-out cursor-pointer`} onMouseEnter={()=>handleHoverTabEnter(2)} onMouseLeave={()=>handleHoverTabLeave(3)} >
            {tab2} <span className={`text-[15px] flex items-center hover:text-primary ml-1 transition-transform transform ease-in-out duration-300 font-bold ${hoverTab==2 ? 'rotate-180' : ''}`} >{<TbChevronDown/>}</span>
          </div> */}


          {/* TAB-3 */}
          {/* <div className={`flex text-[14px] hover:text-primary px-[10px] py-[30px]  duration-300 ease-in-out cursor-pointer`}>
            <Link to='/pricing' >{tab3}</Link>
          </div> */}


          


          



          {/* TAB-5 */}
          <div className={`flex text-[14px] hover:text-primary px-[10px] py-[30px]  duration-300 ease-in-out cursor-pointer`} onMouseEnter={()=>handleHoverTabEnter(5)} onMouseLeave={()=>handleHoverTabLeave(5)} >
            {tab5} <span className={`text-[15px] flex items-center hover:text-primary ml-1 transition-transform transform ease-in-out duration-300 font-bold ${hoverTab==5 ? 'rotate-180' : ''}`} >{<TbChevronDown/>}</span>

            <div className={`${hoverTab==5 ? 'block':'hidden'} absolute z-50 custom-slide-in shadow-2xl bg-[#FFFFFF] top-[65px] p-[10px] rounded-md`} >
              {
                hoverTab5Data.map((el,i)=>(
                  <HoveringInfoTab key={i} title={el.title} desc={el.desc} icon={el.icon} link={el.link} mb={hoverTab5Data.length-1==i?true:false}/>
                ))
              }
            </div>
          </div>
    
        </div>
        
        {/* CREATE ACCOUNT BUTTON */}
        <div className='hidden laptop:flex justify-end gap-x-3' >
          <button className={`block font-[600] text-[13px] text-off-black py-[7px] border border-primary rounded-lg  text-center tracking-wider w-[70px]`} >
            <Link to='https://app.personate.ai/login' target='_blank' >Login</Link>
          </button>

          <button className={`block font-[700] text-[13px] bg-primary hover:bg-dark-primary text-[#FFFFFF] rounded-md py-[7px] text-center w-[120px] shadow-md `} >
            <Link to='https://app.personate.ai/register' target='_blank' >Create Account</Link>
          </button>
        </div>

        {/* HAMBURGER */}
        <div className={`${menuOpen?'mr-[24px]':''} ml-auto flex flex-col laptop:hidden `} onClick={handleHamburger} > 
          <span className={`h-[2px] ml-auto w-[22px] bg-gradient-to-r from-[#0CD9FA] to-primary mb-[6px] ${menuOpen?'w-[27px] rotate-45 absolute ':''} ease-in-out duration-300`}></span>
          <span className={`h-[2px] ml-auto w-[15px] bg-gradient-to-r from-[#0CD9FA] to-primary mb-[6px] ${menuOpen?'w-[27px] -rotate-45 absolute ':''} ease-in-out duration-300`}></span>
          <span className={`h-[2px] ml-auto w-[20px] bg-gradient-to-r from-[#0CD9FA] to-primary ${menuOpen?'rotate-180 hidden':''}  `}></span>
        </div>

      </div>
      
      {/* HAMBURGER INSIDE CONTENT IN MOBILE AND TABLET */}
      <div className={`${menuOpen?'top-[68px] fixed w-[100%] h-[100%] ':'-top-full fixed '} z-50 bg-white p-[20px] w-full laptop:hidden ease-in-out duration-500 overflow-x-auto `}>
        
        {/* TAB-1 */}
        {/* <div onClick={()=>handleMobileTab(1)} className={`${hoverTab==1?'mb-[0px] pt-[20px]':'py-[20px]'} flex justify-between text-[16px]  bg-lite-gray px-[10px] rounded-md text-black font-semibold`} >
          <div>{tab1}</div>
          <div  className={`${hoverTab==1?'rotate-180':''} transition-transform transform ease-in-out duration-300 `} >{<TbChevronDown/>}</div>
        </div>
        <div className={`${hoverTab==1?'custom-slide-in-up overflow-auto h-auto p-[10px] ':'overflow-hidden h-0'} bg-lite-gray mb-[22px]`} >
              {
                  hoverTab1Data.map((el,i)=>(
                      <TabInsideContent key={i} link={el.link} setMenuOpen={setMenuOpen} setHoverTab={setHoverTab} title={el.title} desc={el.desc} icon={el.icon}/>
                  ))
              }
        </div> */}

        {/* TAB-4 */}
        <div onClick={()=>handleMobileTab(4)} className={`${hoverTab==4?'mb-[3px] pt-[20px] font-[600] ':'py-[20px] border-b border-gray-100 font-[500]'} flex justify-between text-[18px]  bg-white px-[10px] text-off-black `} >
          <div>{tab4}</div>
          <div  className={`${hoverTab==4?'rotate-180':''} transition-transform transform ease-in-out duration-300 my-auto `} >{<TbChevronDown/>}</div>
        </div>
        <div className={`${hoverTab==4?'custom-slide-in-up overflow-auto h-auto p-[5px] ':'overflow-hidden h-0'} bg-white`} >
            {
              hoverTab4Data.map((el,i)=>(
                  <TabInsideContent key={i} link={el.link} setMenuOpen={setMenuOpen} setHoverTab={setHoverTab} title={el.title} desc={el.desc} icon={el.icon}/>
              ))
            }
        </div>

        
        {/* TAB-2 */}
        {/* <div onClick={()=>handleMobileTab(2)} className={`${hoverTab==2?'mb-[3px] pt-[20px] font-[600] ':'py-[20px] border-b border-gray-100 font-[500]'} flex justify-between text-[18px]  bg-white px-[10px] text-off-black `} >
          <div>{tab2}</div>
          <div  className={`${hoverTab==2?'rotate-180':''} transition-transform transform ease-in-out duration-300 my-auto `} >{<TbChevronDown/>}</div>
        </div>

        <div className={`${hoverTab==2?'custom-slide-in-up overflow-auto h-auto p-[5px] ':'overflow-hidden h-0'} bg-white`} >
            {
              hoverTab2Data.map((el,i)=>(
                  <TabInsideContent key={i} link={el.link} setMenuOpen={setMenuOpen} setHoverTab={setHoverTab} title={el.title} desc={el.desc} icon={el.icon}/>
              ))
            }
        </div> */}

        {/* TAB-3 */}
        {/* <Link to='/pricing' >
          <div onClick={()=>setMenuOpen(false)} className={`py-[20px] flex justify-between text-[18px]  bg-white px-[10px] text-off-black font-[500] border-b border-gray-100`} >
            {tab3}
          </div>
        </Link> */}
        
        

        {/* TAB-5 */}
        <div onClick={()=>handleMobileTab(5)} className={`${hoverTab==5?'mb-[3px] pt-[20px] font-[600] ':'py-[20px] border-b border-gray-100 font-[500]'} flex justify-between text-[18px]  bg-white px-[10px] text-off-black `} >
          <div>{tab5}</div>
          <div  className={`${hoverTab==5?'rotate-180':''} transition-transform transform ease-in-out duration-300 my-auto `} >{<TbChevronDown/>}</div>
        </div>
        <div className={`${hoverTab==5?'custom-slide-in-up overflow-auto h-auto p-[5px] ':'overflow-hidden h-0'} bg-white`} >
            {
              hoverTab5Data.map((el,i)=>(
                  <TabInsideContent key={i} link={el.link} setMenuOpen={setMenuOpen} setHoverTab={setHoverTab} title={el.title} desc={el.desc} icon={el.icon}/>
              ))
            }
        </div>

        <div className={`text-center text-[16px] mb-[20px] bg-white hover:bg-primary py-[13px]  px-[10px] rounded-md text-primary hover:text-white border border-primary font-semibold`} >
          <Link to='https://app.personate.ai/login' target='_blank' ><div className='text-center'>Login</div></Link>
        </div>

        <div className={`text-center text-[16px] mb-[20px] bg-primary hover:bg-dark-primary py-[15px] px-[10px] rounded-md text-white font-semibold`} >
          <Link to='https://app.personate.ai/register' target='_blank' ><div className='text-center'>Create Account</div></Link>
        </div>

      </div>

    </div>
  )
}

export default Navbar



