import React from 'react'
import Introduction from '../myComponents/AboutUs/Introduction'
import { QuestionAndAns } from '../myComponents/AboutUs/QuestionAndAns'
import {Helmet} from "react-helmet";
import Team from '../myComponents/AboutUs/Team'
import { Newsletter } from '../myComponents/AboutUs/Newsletter';
import { baseURL } from '../script/baseURL';

const AboutUs = () =>{

    return(
        <div style={{ backgroundImage: "url('https://img.freepik.com/free-photo/studio-background-concept-abstract-empty-light-gradient-purple-studio-room-background-product-plain-studio-background_1258-71253.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', }} className='py-[50px]' >
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | Personate’s Mission and Vision</title>
                <link rel="canonical" href={`${baseURL}/about-us`} />
                <meta name="description" content="Discover Personate’s mission to revolutionize storytelling with innovative AI technologies." />
            </Helmet>

            <Introduction/>
            <Team/>
            <QuestionAndAns/>
            <Newsletter/>
        </div>
    )
} 

export default AboutUs

  
// https://img.freepik.com/free-photo/studio-background-concept-abstract-empty-light-gradient-purple-studio-room-background-product-plain-studio-background_1258-71253.jpg
