import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import {AiOutlineLeft,AiOutlineRight} from 'react-icons/ai'

import {testemonialsData } from '../../script/homepageData'
import styles from './testemonials.module.css'
import { HeadingAndSubheading } from '../../GlobalComponents/HeadingAndSubheading';

const Testemonials = () => {

  return (
    <div className='relative'  >

        <HeadingAndSubheading heading={'Hear From Our Happy Customers'} />

        <Swiper 
        initialSlide={1}   
        grabCursor={true} 
        centeredSlides={true} 
        slidesPerView={'auto'} 
        coverflowEffect={{ rotate: 0,stretch: 0,depth: 0,modifier: 0,}} 
        pagination={{ el: '.swiper-pagination', clickable: true }} 
        navigation={{nextEl: '.button-next',prevEl: '.button-prev',clickable: true}} 
        
        modules={[Pagination, Navigation]} 
        className={`${styles.testemonialSwiperContainer}  `}>
            {
                testemonialsData.map((el,i)=>(
                    <SwiperSlide key={i} className={`${styles.testemonialSwiperSlide} `}>
                        <div  key={i} className={`${styles.box} border  font-redHat px-[20px] py-[20px] bg-white rounded-xl`} >
                            <div className='h-[100%]' >
                                

                            <div className='flex flex-col laptop:flex-row gap-y-2 justify-between mb-[30px] ' >
                                <div className='flex gap-x-3 h-[40px] mb-[15px] tablet:mb-0'>
                                    <div>
                                        <img className='rounded-full h-[40px] w-[40px]' src={el.authorImage} alt="testemonials" />
                                    </div>
                                    <div className='my-auto' >
                                        <h4 className='font-[500] text-[14px] text-gray-500' >{el.authorName}</h4>
                                        <p className='font-[400] text-[14px] text-gray-400'  >{el.position}</p>
                                    </div>
                                </div>


                                <div className='h-[40px] w-[100px]' >
                                    <img className='object-contain'  src={el.companyLogo} alt="image" />
                                </div>
                            </div>


                            <div className=' text-gray-400 leading:[16px] laptop:leading-[32px] font-[400] text-[16px] laptop:text-[18px]'>
                                    <i>{el.title}</i>
                                </div>


                            </div>
                        </div>
                    </SwiperSlide>
                ))
            }

            <div className='slider-controller px-[40px] absolute z-50 hidden tablet:flex justify-between w-[100%] top-1/2 transform -translate-y-1/2' >
                <div className="p-[10px] text-[20px] bg-slate-300 bg-opacity-50 text-gray-500 rounded-full button-prev cursor-pointer">
                    {<AiOutlineLeft/>}
                </div>
                <div className="p-[10px] text-[20px] bg-slate-300 bg-opacity-50 text-gray-500 rounded-full button-next cursor-pointer">
                    {<AiOutlineRight/>}
                </div>
            </div>
                
        </Swiper>

    </div>
  )
}

export default Testemonials


// <div className='flex flex-col tablet:flex-row tablet:items-end tablet:h-[80px] tablet:justify-between' >
//                                     {/* AUTHOR CARD */}
                                    // <div className='flex gap-x-3 h-[40px] mb-[15px] tablet:mb-0'>
                                    //     <div>
                                    //         <img className='rounded-full h-[40px] w-[40px]' src={el.authorImage} alt="testemonials" />
                                    //     </div>
                                    //     <div className='my-auto' >
                                    //         <h2 className='font-[500] text-[14px] text-gray-500' >{el.authorName}</h2>
                                    //         <p className='font-[400] text-[14px] text-gray-400'  >{el.position}</p>
                                    //     </div>
                                    // </div>


                                    // <div className='h-[40px] w-[100px]' >
                                    //     <img className='object-contain'  src={el.companyLogo} alt="image" />
                                    // </div>
//                                 </div>

                                // <div className=' text-gray-400 leading:[16px] laptop:leading-[32px] font-[400] text-[16px] laptop:text-[18px]'>
                                //     <i>{el.title}</i>
                                // </div>