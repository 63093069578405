import React, { useRef, useState } from 'react'
import { TwoButtons } from '../../GlobalComponents/TwoButtons'
import { register, typeform } from '../../script/buttonLinks'
import { IoPlaySharp } from "react-icons/io5";
import { IoPauseSharp } from "react-icons/io5";
import { UIforAboutUs } from '../../script/backgroundUI';


export const Introduction = () => {
    const [isMute,setIsMute] = useState(true);
    const [isPlay,setIsPlay] = useState(false)
    const videoRef = useRef(null)
    const timerRef = useRef(null)
    const [index,setIndex] = useState(0)

    const handleMute = () => {
        setIsMute(!isMute);

        if(timerRef.current){
            clearTimeout(timerRef.current)
        }
        
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
          videoRef.current.muted = !isMute;
        }
    }; 
 
    if (videoRef.current) {
        videoRef.current.play();
    }  

  return (
    <div className='font-redHat py-[70px] ' style={UIforAboutUs} >
        <h1 className='text-center text-[30px] laptop:text-[34px] font-[500] text-off-black' >Explore Our Range of <span className='text-primary' >AI Voices Hyper-Realistic,</span> <br />Instantly Available</h1>
        <p className='text-dark-gray font-[400] text-[15px] text-center w-[90%] laptop:w-[80%] mx-auto ' >Explore our extensive collection of Al Voices, from accurate imitations of specific characters to unique new voices, all crafted for global communication</p>
        <div className='flex justify-center mt-[20px] ' >
            <TwoButtons  btn1name={'Get Started'} btn2name={'Book a Demo'} btn1link={register} btn2link={typeform} />
        </div>

        {/* VIDEO DIV */}
        <div className='w-[95%] laptop:w-[60%] mx-auto mt-[20px] rounded-xl laptop:rounded-2xl relative cursor-pointer ' style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} >
            {/* PLAY PAUSE BUTTON */}
            
            
            <div className='relative' >
                <video onClick={handleMute} style={{clipPath: 'inset(0.01px 0.01px)'}} autoPlay muted={isMute} loop className='shadow-2xl rounded-3xl w-[100%] h-[100%] object-cover  ' poster={data[index].image}  src={data[index].video} >
                </video>
                
                <div className='flex items-center justify-center h-[40px] w-[100px] laptop:w-[110px]  gap-[5px] rounded-2xl bg-gray-500 text-white absolute top-2 left-2  bg-opacity-60 cursor-pointer ' onClick={handleMute} >
                    <div className='text-[20px] laptop:text-[22px]  ' >{!isMute?<IoPauseSharp/>:<IoPlaySharp/>}</div>
                    <div className='text-[18px] laptop:text-[20px] ' > {!isMute?'Pause':'Play'} </div>
                </div>
            </div>

            {/* FOR LAPTOP */}
            <div className='hidden laptop:flex flex-col laptop:gap-y-[10px] absolute top-[100px] -left-[200px] ' >
                {
                    data.map((el,i)=>(
                        <div className='flex justify-between  items-end w-[250px] ' >
                            <div className={`text-[30px] font-redHat font-[500] text-gray-700 ${index==i?'text-opacity-100':'text-opacity-50'} `} >{el.name}</div>
                            <div onClick={()=>setIndex(i)} key={i} style={{boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}} className={`${index==i?'border-[3px] border-blue-400 ':''} cursor-pointer rounded-lg w-[100px] h-[100px] overflow-hidden `} >
                                <img className='w-[100%] h-[100%] object-cover ' src={el.image} alt={`${el.name} AI Voices`} /></div>
                        </div>
                    ))
                }
            </div>
        </div>
        {/* FOR MOBILE AND TABLET */}
        <div className='flex gap-x-[10px] overflow-x-auto laptop:hidden mt-[17px] px-[10px] ' >
            {
                data.map((el,i)=>(
                    <div onClick={()=>setIndex(i)} key={i} className={`flex items-center gap-x-[5px] rounded-lg px-[17px] py-[10px] ${index==i?'bg-black bg-opacity-90 ':'bg-gray-100 bg-opacity-70 '} `} >
                        <div className='w-[20px] h-[20px] rounded-full ' ><img className='w-[100%] h-[100%]  ' src={el.flag} alt={`${el.name} AI Voice`} /></div>
                        <div className={`text-[17px] tracking-wide font-[400] ${index==i?'text-white ':'text-off-black '}  `} >{el.name}</div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

let data = [
    {
        video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/four_avatar_video_ai_voices/7d684d2b-22d5-417c-9317-988544a009d2+(2).mp4',
        name : 'English',
        image : '/AIVoicesAvatarPoster/englishAvatar.png',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/uk-flag.png'
    },
    {
        video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/four_avatar_video_ai_voices/a0793320-0c8f-46dc-b97d-e8e65b66e490+(2).mp4',
        name : 'Hindi',
        image : '/AIVoicesAvatarPoster/hindiAvatar.png',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png'
    },
    
    {
        video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/four_avatar_video_ai_voices/84dfef12-0ee6-47fc-8fa1-b1c08aefe199+(2).mp4',
        name : 'Spanish',
        image : '/AIVoicesAvatarPoster/spanishAvatar.png',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/spain-flag.png'
    },
    {
        video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/four_avatar_video_ai_voices/178d32b7-5de1-49dc-9006-46f815c964ae+(2).mp4',
        name : 'Japanese',
        image : '/AIVoicesAvatarPoster/japaneseAvatar.png',
        flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/japanese-flag.png'
    },
]
