import React from 'react'
import {FiArrowRight} from 'react-icons/fi'
import { Link } from 'react-router-dom'

const HoveringInfoTab = ({icon,title,desc,mb,link}) => {
  return (
    
    <Link to={link}>
        <div className={`font-redHat hover:bg-primary-transparency group flex p-[10px] w-[280px]  text-primary gap-x-3 rounded-md cursor-pointer ${mb?'mb-0':'mb-[20px]'}`} >

          <div className=' text-[22px] flex items-center justify-center'>
            <div className='text-gray-500 group-hover:text-primary group-hover:bg-gray-50 rounded-full p-2  transform rotate-45 ' ><div className='transform -rotate-45 ' >{icon}</div></div>
          </div>
          <div>
            <div className='flex text-[15px] tracking-wide text-[#404449] font-bold group-hover:text-primary' >{title}<span className='font-bold pt-1 ml-1 hidden group-hover:block group-hover:text-primary' >{<FiArrowRight/>}</span> </div>
            <p className='text-[13px] font-[500] text-gray-600 '>{desc}</p>
          </div>
        </div>
    </Link>

  )
}

export default HoveringInfoTab

// group-hover:bg-gradient-to-r from-[#9e3df2] via-[#b968ff] to-white


{/* <div className={`grid grid-cols-3 grid-rows-3 font-redHat hover:bg-primary-transparency group p-[10px] w-[280px]  text-primary gap-x-3 rounded-md cursor-pointer ${mb?'mb-0':'mb-[20px]'}`}>
      <div className='row-span-2  group-hover:text-primary text-[22px] text-[#404449] pt-1'>{icon}</div>
      <div className='flex text-[15px] tracking-wide text-[#404449] font-bold group-hover:text-primary' >{title}<span className='font-bold pt-1 ml-1 hidden group-hover:block group-hover:text-primary' >{<FiArrowRight/>}</span> </div>
      <div className='text-[13px] tracking-wide font-[400] text-dark-gray '>{desc}</div>
    </div> */}

