import React from 'react'
import { HeadingAndSubheading } from './HeadingAndSubheading'
import { TwoButtons } from './TwoButtons'

export const FourTitleBoxIcon = ({bottomDesc, bg='bg-white',title,desc,data,btn1name,icon1,icon2,btn2name,btn1link,btn2link,icon2size,icon1size}) => {

  return (
    <div className={`${bg}`} >
    <div className='laptop:w-[80%] w-[90%] mx-auto font-redHat py-[50px] '>
        
        <HeadingAndSubheading heading={title} subHeading={desc}/>

        
    
        <div className={`grid ${data.length==2?'grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2':''}
                        ${data.length==3?'grid-cols-1 tablet:grid-cols-3 laptop:grid-cols-3':''}
                        ${data.length==4?'grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4':''} gap-[40px]`}>
            {
                data.map((el,i)=>(
                    <div className='p-[20px] rounded-lg border ' key={i} >
                        <span className='flex  items-center justify-center w-[50px] h-[50px] mb-[20px] text-[20px] bg-primary-transparency text-primary rounded-full ' >
                            {el.icon}
                        </span>

                        <h3 className='text-[20px] font-[500] text-off-black mb-[15px] ' >{el.title}</h3>
                        <p className='font-[400] text-[16px] text-dark-gray ' >{el.desc}</p>
                    </div>
                ))
            }
        </div>

        <p className={`text-off-black text-[16px] text-center font-[400] ${bottomDesc?.length==0?'':'mt-[20px]'} `} >{bottomDesc}</p>

        {
            (btn1name || btn2name) && <div className='flex justify-center mt-[10px]' >
            <TwoButtons
                btn1name={btn1name}
                icon1={icon1}
                icon2={icon2}
                icon1size={icon1size}
                icon2size={icon2size}
                btn2name={btn2name}
                btn1link={btn1link}
                btn2link={btn2link}
            />
        </div>
        }
    
    </div>
    </div>
  )
}
