import React from 'react'
import {RxCross2} from 'react-icons/rx'
import {MdCheck} from 'react-icons/md'
import { HeadingAndSubheading } from './HeadingAndSubheading'

const Comparision = ({withPersonate,withOutPersonate,heading,subHeading,title1,title2,withOutImage,withImage}) => {
  return (
    <div className='font-redHat laptop:mb-[70px] mb-[50px] w-[90%] laptop:w-[80%] mx-auto '>
        <HeadingAndSubheading heading={heading} subHeading={subHeading} />
        
        <div className='rounded-lg overflow-x-auto laptop:overflow-hidden flex mx-auto p-[20px] px-[10px]  border shadow ' >
            
            {/* COL-1 */}
            <div className='min-w-[85%] laptop:min-w-[50%] text-center bg-white ' >
                <h4 className='text-center mb-[20px] font-semibold text-[14px] laptop:text-[18px] text-gray-400' >{title1}</h4>
                <div className='overflow-hidden h-[120px] w-[80%] laptop:w-[70%] mx-auto rounded-xl border laptop:h-[200px] ' >
                    <img className='w-full h-full object-cover'  src={withOutImage} alt="" />
                </div>

                {
                    withOutPersonate.map((el,i)=>(
                        <div key={i} className='flex flex-row-reverse laptop:flex-row w-[100%]  mx-auto laptop:w-full font-[500] my-[20px] justify-end laptop:justify-end laptop:pr-[100px] items-center' > 
                            <div className= 'flex font-[500] text-[11px] laptop:text-[17px] text-gray-400 ' >{el}</div>
                            <div className='mx-[5px] rounded-full h-[12px] w-[12px] laptop:h-[22px] laptop:w-[22px] text-white  flex justify-center items-center bg-red-300 text-[14px] p-[1px] laptop:p-[2px]' >{<RxCross2/>}</div>
                        </div>
                    ))
                }
            </div>

            {/* COL-2 */}
            <div className='min-w-[85%]  laptop:min-w-[50%] text-center bg-white' >
                <h4 className='text-center mb-[20px] font-bold text-[14px] laptop:text-[18px] text-primary' >{title2}</h4>
                <div className='overflow-hidden h-[120px] w-[80%] laptop:w-[70%] mx-auto rounded-xl border laptop:h-[200px] ' >
                    <img className='w-full h-full object-cover'  src={withImage} alt="" />
                </div>

                {
                    withPersonate.map((el,i)=>(
                        <div key={i} className='flex w-[100%] laptop:w-full mx-auto items-center justify-start font-[500]  my-[20px] laptop:pl-[100px] ' > 
                            <div className='mr-[5px] rounded-full h-[12px] w-[12px] laptop:h-[22px] laptop:w-[22px] text-white  flex justify-center items-center bg-green-500 text-[14px] p-[1px] laptop:p-[2px]' >{<MdCheck />}</div>
                            <div className= 'flex font-[500] text-[11px] laptop:text-[17px] text-black ' >{el}</div>
                        </div>
                    ))
                }

                
            </div>
        </div>
    </div>
  )
}

export default Comparision
// https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled+design+(18).png
// https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled_design_17_xn65j7.png