import React from 'react'
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { IoDocumentText } from "react-icons/io5";
import { FaLink } from "react-icons/fa";
import { FaFilePowerpoint } from "react-icons/fa6";
import { Heading } from './Heading';


export const Step1 = () => {
  return (
    <div className='mb-[70px]' >
        <Heading title={'Begin With Any Content Format'} desc={'Start with any format and let Personate effortlessly transform your existing media into dynamic videos'} />

        <div className='grid grid-cols-1 laptop:grid-cols-2 gap-[40px] mt-[40px] ' >
            {
                converter.map((el,i)=>(
                    <div key={i} className={`border border-blue-100 rounded-md px-[20px] pt-[28px] py-[10px] relative`} >
                        <div className='rounded-full h-[40px] w-[40px] absolute -top-[20px] bg-blue-50 flex justify-center items-center text-blue-400 ' >{el.icon}</div>
                        <h3 className='text-[18px] text-off-black font-[500] ' >{el.title}</h3>
                        <p className='text-dark-gray font-[400] text-[16px] ' >{el.desc}</p>
                    </div>
                ))
            }
        </div>
    
    </div>
  )
}


let converter = [
    {
        title : 'Text to Video',
        desc : 'Turn text into visually engaging stories with ease ',
        // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296719/bizOps/wesbite_asset/feature%20logo/URL-1_e3w0p4.png',
        button : 'Start Converting',
        icon : <IoDocumentText />
    },
  
    {
        title : 'URL to Video',
        desc : ' Create videos from web content instantly',
        // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296712/bizOps/wesbite_asset/feature%20logo/URL_x7auru.png',
        button : 'Bring Web to Life',
        icon : <FaLink />
    },
    {
        title : 'PDF to Video',
        desc : ' Animate PDF documents to enhance viewer interaction',
        // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296724/bizOps/wesbite_asset/feature%20logo/PDF_m1mg6u.png',
        button : 'Revitalize PDFs',
        icon : <BsFillFileEarmarkPdfFill />
        
    },
    {
        title : 'PowerPoint to Video',
        desc : 'Convert presentations into engaging videos that captivate',
        // image : 'https://res.cloudinary.com/dxvi69tlz/image/upload/v1702296721/bizOps/wesbite_asset/feature%20logo/PPT_untmjt.png',
        button : 'Transform Presentations',
        icon : <FaFilePowerpoint />
    },
  ]