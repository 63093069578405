import { AiOutlineAppstoreAdd, AiOutlineVideoCameraAdd  } from 'react-icons/ai';
import { RxAvatar } from 'react-icons/rx'; 
import {FiFastForward} from 'react-icons/fi'
import {RiCustomerService2Line} from 'react-icons/ri'
import {TbBuildingBank} from 'react-icons/tb'
import {FaRegNewspaper} from 'react-icons/fa'
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdSettingsVoice } from "react-icons/md";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { BiMessageDetail } from "react-icons/bi";
import { LiaHandPointer } from "react-icons/lia";
import { HiLanguage } from "react-icons/hi2";
import { FiType } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { PiTranslate } from "react-icons/pi";
import { FiLink } from "react-icons/fi";
import { IoFingerPrintOutline } from "react-icons/io5";
import { CiVideoOn } from "react-icons/ci";

// NAVBAR COMPONENT DATA STARTED FROM HERE

// NAVBAR TABS SINGLE DATA
export const tab1 = 'Product'
export const tab2 = 'Use Cases'
export const tab3 = 'Pricing'  // not drop down
export const tab4 = 'Features'
export const tab5 = 'Company'

// NAVBAR HOVER TAB DATA


// export const hoverTab2Data = [
//     {
//       title: "L&D Training Videos",
//       desc: "For Training Videos",
//       icon: <TbBuildingSkyscraper />,
//       link : '/learning-and-development'
//     },
//     {
//       title: "Support Centre Videos",
//       desc: "For Explaining the Products",
//       icon: <BiSupport />,
//       link : '/support-centre'
//     },
//     {
//       title: "Product Team",
//       desc: "Product Development and Enhancement Team.",
//       icon: <PiCubeLight />,
//       link : '/product-team'
//     },
//     {
//       title: "Personalised Video",
//       desc: "Create Your Personalised Video",
//       icon: <LuCommand />,
//       link : '/personalised-video'
//     },
//     {
//       title: "News Broadcasting",
//       desc: "For Creating News Videos",
//       icon: <ImNewspaper />,
//       link : '/news-and-entertainment'
//     },
//     {
//       title: "Video Translation",
//       desc: "Translate Your Video In Multiple Languages",
//       icon: <PiTranslate />,
//       link : '/video-translation'
//     },
//     {
//       title: "Marketing Video",
//       desc: "Promotional Video for Marketing Purposes.",
//       icon: <SiGooglemarketingplatform />,
//       link : '/marketing-videos'
//     },
//     {
//       title: "Product Explainers",
//       desc: "For how-to and product videos",
//       icon: <TfiStatsUp />,
//       link : '/product-explainer'
//     },
//     {
//       title: "Creator Studio",
//       desc: "For creating content",
//       icon: <SiYoutubestudio />,
//       link : '/creator-studio'
//     },{
//       title: "Blogs to Videos",
//       desc: "Convert your blogs into videos",
//       icon: <RiComputerLine />,
//       link : '/blog-to-videos'
//     },
// ];


export const hoverTab4Data = [
  {
    title: "All Features",
    desc: "Explore All Capabilities",
    icon: <AiOutlineAppstoreAdd />,
    link : '/features'
  },
  {
    title: "AI Avatars",
    desc: "Customizable Digital Personas",
    icon: <RxAvatar />,
    link : '/features/ai-avatars'
  },
  {
    title: "AI Voices",
    desc: "Replicate and Customize Voices",
    icon: <MdSettingsVoice />,
    link : '/features/ai-voices'
  },
  {
    title : 'Instant AI Video',
    desc : 'Streamline Your Video Updates',
    icon : <CiVideoOn />,
    link : '/features/instant-ai-video'
  },
  
  {
    title : 'AI Video Translation',
    desc : 'Perfect Lip Sync in Every Language',
    icon : <PiTranslate />,
    link : '/features/ai-video-translation'
  },
  // {
  //   title: "Personate API for Scale",
  //   desc: "Automate Your Video Production",
  //   icon:<FiLink />,
  //   link : '/features/api'
  // },

  // {
  //   title: "Video Templates",
  //   desc: "Pre Design Templates",
  //   icon: <CiFolderOn />,
  //   link : '/features/templates'
  // },
  // {
  //   title: "Language Translation",
  //   desc: "Translate One Language to Another.",
  //   icon: <PiTranslate />,
  //   link : '/features/language-translation'
  // },
  // {
  //   title: "Script Generation",
  //   desc: "Generate Scripts with GPT-4 AI Model.",
  //   icon:<MdOutlineDescription />,
  //   link : '/features/script-generation'
  // },
  
  // {
  //   title: "Text to Video",
  //   desc: "Convert Text to Engaging Videos.",
  //   icon:<PiTextT />,
  //   link : '/features/text-to-video'
  // },
  // {
  //   title: "URL to Video",
  //   desc: "Convert URL Links into Videos.",
  //   icon:<MdLink />,
  //   link : '/features/url-to-video'
  // },
  // {
  //   title: "PDF to Video",
  //   desc: "Transform PDFs into Dynamic Videos.",
  //   icon:<FaRegFilePdf />,
  //   link : '/features/pdf-to-video'
  // },
  // {
  //   title: "PowerPoint to Video",
  //   desc: "Convert PowerPoint Presentations to Videos.",
  //   icon:<PiMicrosoftPowerpointLogo />,
  //   link : '/features/powerpoint-to-video'
  // },
  // {
  //   title: "Excel to Video",
  //   desc: "Turn Excel Data into Dynamic Videos.",
  //   icon:<RiFileExcel2Line />,
  //   link : '/features/excel-to-video'
  // },
];

export const hoverTab5Data = [
  {
    title: "About Us",
    desc: "About our company",
    icon: <IoFingerPrintOutline />,
    link : '/about'
  },
  {
    title: "Ethics",
    desc: "Media Resources",
    icon: <VscWorkspaceTrusted />,
    link : '/ethics'
  },
  // {
  //   title: "Blogs",
  //   desc: "All about blogs",
  //   icon: <BiMessageDetail />,
  //   link : '/blog'
  // },
];



// FOOTER DATA



export const firstRow = [
  {
    title : 'Learning & Development',
    link : '/learning-and-development'
  },
  {
    title : 'Support Centre',
    link : '/support-centre'
  },
  {
    title : 'Product Team',
    link : '/product-team'
  },
  {
    title : 'Personalised Video',
    link : '/personalised-video'
  },
  {
    title : 'News Broadcasting',
    link : '/news-and-entertainment'
  },
  {
    title : 'Video Translation',
    link : '/video-translation'
  },
  {
    title : 'Marketing Video',
    link : '/marketing-videos'
  },
]

export const secondRow = [
  {
    title : 'All Features',
    link : '/features'
  },
  {
    title : 'AI Avatars',
    link : '/features/ai-avatars'
  },
  {
    title : 'AI Voices',
    link : '/features/ai-voices'
  },
  {
    title : 'Instant AI Video',
    link : '/features/instant-ai-video'
  },
  {
    title : 'AI Video Translation',
    link : '/features/ai-video-translation'
  },
  
]


export const thirdRow = [
  {
    title : 'Text to Video',
    link : '/features/text-to-video'
  },
  {
    title : 'URL to Video',
    link : '/features/url-to-video'
  },
  {
    title : 'PDF to Video',
    link : '/features/pdf-to-video'
  },
  {
    title : 'PPT to Video',
    link : '/features/powerpoint-to-video'
  },
  {
    title : 'Excel to Video',
    link : '/features/excel-to-video'
  },
]

export const fourthRow = [
  {
    title : 'About Us',
    link : '/about'
  },
  {
    title : 'Ethics',
    link : '/ethics'
  },
  {
    title : 'Terms of Service',
    link : '/terms-of-service'
  },
  {
    title : 'Privacy Policy',
    link : '/privacy-policy'
  },
  {
    title : 'Moderation Policy',
    link : '/moderation-policy'
  },
]



// OUR TRUSTED CLIENTS LOGOS


export const trustedClientsData = [
  {
    logoName : 'zee',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/3_do0bsy.png' 
  },
  {
    logoName : 'aajtak',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/6_ztfxh3.png' 
  },
  {
    logoName : 'lg',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/9_a2kdva.png' 
  },
  {
    logoName : 'nvidia',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/8_knojgr.png' 
  },
  {
    logoName : 'angle one',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/5_okikjb.png' 
  },
  
  {
    logoName : 'india today',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/4_qfqzxa.png' 
  },
  {
    logoName : 'bengal',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/7_yoey21.png' 
  },
  {
    logoName : 'company house finland',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/Companies-House-Finland-1_qciwld.png' 
  },
  {
    logoName : 'kcw',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/2_vk3vu3.png' 
  },
  {
    logoName : 'jeevan health',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/1_qwefjy.png' 
  },
  {
    logoName : 'ai forum',
    logoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/10_rvdqfz.png' 
  },
  
]





// COMPARISON SECTION DATA
export const withOutPersonate = [
  "Involves expensive camera & studios fees",
  "Requires at least $2000 per video",
  "Needs days to create",
  "Actors can speak limited languages",
  "Feasible only during work hours",
  "Hassle of managing studio setup and crew"
]



export const withPersonate = [
  "Expensive camera & studios not required",
  "Get started for free",
  "Create fabulous videos in minutes",
  "Choose from 60+ languages and accents",
  "Generate videos 24x7 in API mode",
  "Hassle-free video production from anywhere"
]






export const getStartedData = [
  {
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/Untitled_design_gtwzvi.png',
    poster : '/getStartedPoster/tab1.png',
    tabs : 'Pick an AI Avatar',
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/home_page_steps_video/Comp+1_1.mp4'
  },
  {
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/page_4_1_vn7ons.png',
    poster : '/getStartedPoster/tab2.png',
    tabs : 'Pair it with an AI Voice',
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/home_page_steps_video/Comp+2_1.mp4'
  },
  {
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/page_2_frbdrc.png',
    poster : '/getStartedPoster/tab3.png',
    tabs : 'Craft script with AI or add yours',
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/script.mp4'
  },
  {
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/page_3_tnlr6u.png',
    poster : '/getStartedPoster/tab4.png',
    tabs : 'Render your video',
    video : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/home_page_steps_video/Comp+3_1.mp4'
  }
]




export const ROIData = [
  {
    title:'Cut Production Time',
    percentage:'-90%',
    percentageTitle : 'Production',
    desc:`A leader in BFSI slashed video creation time by 90%. Using Personate's URL to Video, they turned knowledge into captivating videos efficiently.`
  },
  {
    title:'Ramp Up Conversions',
    percentage:'70%',
    percentageTitle : 'Conversions',
    desc:`An entertainment leader employed Personate's AI Avatars for personalized campaigns, spiking engagement and conversions by +70%. `
  },
  {
    title:'Boost Savings',
    percentage:'80%',
    percentageTitle : 'Savings',
    desc:`Our media partner saved +80% on video production costs. Personate's multilingual features and automated API streamlined operations for significant financial benefits. `
  }
]


export const useCasesData = [
  {
    tab : 'Learning & Development',
    icon : <TbBuildingBank/>,
    title : `Transform your team's training experience with Personate. Our AI Video Generator turns traditional materials into captivating video content, making learning more interactive and improving retention across your organization. `,
    youtubeLink : 'https://www.youtube.com/embed/DcBNLKDAPRA',
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/Sequence+01.00_02_51_13.Still001.jpg'
  },
  {
    tab : 'Product Explainers',
    icon : <FiFastForward/>,
    title : `Enhance customer comprehension and engagement with Personate. Our platform enables you to create succinct, informative product explainer videos that clearly articulate your product's value, driving customer confidence and increasing conversion rates.`,
    youtubeLink : 'https://www.youtube.com/embed/7aVzYBjyEg8',
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/Screenshot+2024-04-30+at+3.39.26+PM.png'
  },
  {
    tab : 'Customer Support',
    icon : <RiCustomerService2Line/>,
    title : `Elevate your customer service with Personate. Convert your support documentation into engaging video tutorials, providing customers with an easily accessible and understanding resource, thereby enhancing their experience and reducing support inquiries.    `,
    youtubeLink : 'https://www.youtube.com/embed/9h9G9XsaFoU',
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/Screenshot+2024-04-30+at+3.40.11+PM.png'
  },
  {
    tab : 'Personalized Video Campaigns',
    icon : <AiOutlineVideoCameraAdd/>,
    title : `Personalize your communication and strengthen connections with your audience using Personate. From marketing campaigns to special occasion greetings, our platform allows you to create unique video messages that captivate and resonate with your audience.`,
    youtubeLink : 'https://www.youtube.com/embed/CsDyDWfzZU0',
    image : 'https://personate-data-v2.s3.amazonaws.com/website+data+/Screenshot+2024-04-30+at+3.40.31+PM.png  '
  },
  {
    tab : 'News & Broadcasting',
    icon : <FaRegNewspaper/>,
    title : `Keep your audience informed and engaged with Personate. Utilize our AI-driven platform to produce and deliver news content in multiple languages, ensuring your broadcast is relevant, timely, and accessible to a global audience.`,
    youtubeLink : 'https://www.youtube.com/embed/Z0gXnx642yc',
    image :'https://personate-data-v2.s3.amazonaws.com/website+data+/Screenshot+2024-04-30+at+3.40.47+PM.png'
  },

]



// DIVERSE RANGE PERSONATE AVATARS
export const ourAvatars = [
  {
    video : 'sdf',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/avatar_images/amina_red_blazer_hijab_talking.png'
  },
  {
    video : 'https://res.cloudinary.com/dxvi69tlz/video/upload/v1696073004/bizOps/wesbite_asset/avatar_intro_video/7083a3c9-e475-4349-a043-ced6e4fd1b0e_wzicuv.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/avatar_images/lily_party_dress_close.png'
  },
  {
    video : 'https://res.cloudinary.com/dxvi69tlz/video/upload/v1696073004/bizOps/wesbite_asset/avatar_intro_video/71cdefdc-832c-47f6-a12b-ea0019bf7d96_htxtfz.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/avatar_images/maya_counting_blue_stripe_blazer.png'
  },
  {
    video : 'https://res.cloudinary.com/dxvi69tlz/video/upload/v1696073006/bizOps/wesbite_asset/avatar_intro_video/997edb1f-1395-4040-bed1-f19d3211c22e_ljkeep.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/avatar_images/maya_counting_saree.png'
  },
  {
    video : 'https://res.cloudinary.com/dxvi69tlz/video/upload/v1696073005/bizOps/wesbite_asset/avatar_intro_video/171e4dee-477b-4644-a993-237150adec8e_gzud0g.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/avatar_images/parul_blue_coat_close.png'
  },
  {
    video : 'https://res.cloudinary.com/dxvi69tlz/video/upload/v1696073005/bizOps/wesbite_asset/avatar_intro_video/9961fea5-8aac-4b5b-bf21-3b6f6b848cea_bk151n.mp4',
    poster : 'https://personate-data-v2.s3.amazonaws.com/website+data+/avatar_images/rehan_arabic.png'
  }

]

// LANGUAGE AVATARS
export const languageAvatars = [
  {
    videoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/akshat/lily_multilingual_videos+/english_lily.mp4',
    flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/uk-flag.png',
    language : 'English'
  },
  {
    videoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/akshat/lily_multilingual_videos+/spanish_lily.mp4',
    flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/spain-flag.png',
    language : 'Spanish'
  },
  {
    videoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/akshat/lily_multilingual_videos+/russian_lily.mp4',
    flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/russian-flag.png',
    language : 'Russian'
  },
  {
    videoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/akshat/lily_multilingual_videos+/hindi_lily.mp4',
    flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
    language : 'Hindi'
  },
  {
    videoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/akshat/lily_multilingual_videos+/bengali_lily.mp4',
    flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
    language : 'Bangla'
  },
  {
    videoLink : 'https://personate-data-v2.s3.amazonaws.com/website+data+/akshat/lily_multilingual_videos+/gujarati_lily.mp4',
    flag : 'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/sarim_uploads/flags/india-flag.png',
    language : 'Gujarati'
  }
]

// MEDIA CAROUSELS LOGOS 
export const mediaLogos = [
  {   "logoName" : "dw",
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/1200px-Deutsche_Welle_symbol_2012.svg_wbgqda.png"
  },
  {
      "logoName": 'abc news',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/ABC_News_logo_2021.svg_zu8tcf.png"
  },
  {
      "logoName": 'south china',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/scmp_logo_mbbmgo.png"
  },
  {
      "logoName": 'yahoo',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/Yahoo__Finance_logo_2021_gquaso.png"
  },
  {
      "logoName": 'mia',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/MIA-Favicon-3D-1_aadjoc.png"
  },
  {
      "logoName": 'nikkie asia',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/image-removebg-preview_18_h6czsz.png"
  },
  {
      "logoName": 'hidustan times',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/Hindustan_Times_logo.svg_xmez12.png"
  },
  {
      "logoName": 'buissness today',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/unnamed_m1rjmc.png"
  },
  {
      "logoName": 'money control',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/mc_gsqcyb.png"
  },
  {
      "logoName": 'financial express',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/fe_jnvuga.png"
  },
  {
      "logoName": 'the economics times',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/The_Economic_Times_logo.svg_tsokfg.png"
  },
  {
      "logoName": 'lallantaap',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/lpp_jt1fnb.png"
  },
  {
      "logoName": 'india today',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/icon-512x512_b2btqm.png"
  },
  {
      "logoName": 'aajtak',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/Aaj_tak_logo_qaelol.png"
  },
  {
      "logoName": 'ibw',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/IBW-LOGO-PNG_nt5pxk.png"
  },
  {
      "logoName": 'india ai',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/Hindustan_Times_logo.svg_xmez12.png"
  },
  {
      "logoName": 'the hindu',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/493-4935300_the-hindu-newspaper-logo-png-logo-of-the_fcc5wx.png"
  },
  {
      "logoName": 'frontline',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/unnamed_1_jg0qgs.png"
  },
  {
      "logoName": 'scoop woop',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/scoopwhoop-appstore-for-android-507745_dzx66p.png"
  },
  {
      "logoName": 'jagran',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/jagran-logo-A06AC1B413-seeklogo.com_jc18uz.png"
  },
  {
      "logoName": 'adweek',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/Adweek_logo.svg_xb0wav.png"
  },
  {
      "logoName": 'news',
      "logoLink": "https://personate-data-v2.s3.amazonaws.com/website+data+/media_logo/news18_e7yhjy.png"
  },

  

]

// TESTEMONIALS
export const testemonialsData = [
  {
    title : `“With Personate, we can easily communicate the essence of our services to visitors in a minute. Visitors who wouldn't bother to read the text on our site may become interested and we have got many good leads this way. We see Personate as one of our best investments in marketing.“`,
    authorImage : 'https://personate-data-v2.s3.amazonaws.com/website+data+/customer_feedback/ECjq2IyUYAE3E8D-removebg-preview-modified_njc3bh.webp',
    authorName : 'Timo Nieminen',
    position : 'Founder',
    companyLogo : 'https://personate-data-v2.s3.amazonaws.com/website+data+/customer_feedback/Companies-House-Finland-1_qciwld.png'
  },
  {
    title : `“Personate, helped us boost our website SEO through their embeddable video solution. Not only the product was beyond our expectations, but the services and support was really impressive. Personate.ai has served us at scale, and delivered quality content on time. We also liked the creative mindset of the Personate team“`,
    authorImage : 'https://personate-data-v2.s3.amazonaws.com/website+data+/customer_feedback/ZJ1640595393754-p-500_i4rpgz.webp',
    authorName : 'Akshay N. Sahaju',
    position : 'Founder',
    companyLogo : 'https://personate-data-v2.s3.amazonaws.com/website+data+/customer_feedback/cropped-ASPIRANT-LEARNING-LOGO-PNG-BLUE-removebg-preview-p-500_zywiw4.png'
  },
  {
    title : `“Personate AI video generation api helped us generate marketing and customer education videos at scale. With Personate, we can easily generate high volumes of quality content at an affordable production cost. We see Personate platform as one of our best investments in marketing.“`,
    authorImage : 'https://personate-data-v2.s3.amazonaws.com/website+data+/customer_feedback/WhatsApp-Image-2022-11-15-at-3.32.16-PM-p-500_cdbjrp.jpg',
    authorName : 'Tushar Kolhe',
    position : 'Founder',
    companyLogo : 'https://personate-data-v2.s3.amazonaws.com/website+data+/customer_feedback/logo-white_uwqk8l.png'
  },
  
]












  