import React from 'react'
import { FourTitleBoxIcon } from '../GlobalComponents/FourTitleBoxIcon';
import { BottomBanner1 } from '../GlobalComponents/BottomBanner1';
import { register, typeform } from '../script/buttonLinks';
import { Introduction } from '../GlobalComponents/Introduction';
import { MdFileUpload } from "react-icons/md";
import { IoOptionsOutline } from "react-icons/io5";
import { MdDownloadDone } from "react-icons/md";
import { ThreeDots } from '../GlobalComponents/ThreeDots';
import { introductionUIForYoutubeVideo } from '../script/backgroundUI';
import { baseURL } from '../script/baseURL';
import { Helmet } from 'react-helmet';
import { Newsletter } from '../myComponents/HomePageComponents/Newsletter';

export const LanguageTranslation = () => {
  return (
    <div className='' >
        <Helmet>
          <meta charSet="utf-8" />
          <title>AI Video Translation | Perfect Lip Sync in Every Language</title>
          <link rel="canonical" href={`${baseURL}/features/ai-video-translation`} />
          <meta name="description" content="Effortlessly translate and dub videos in multiple languages with perfect lip-sync using Personate AI Video Translator. " />
        </Helmet>
        <Introduction
          title={<>Effortlessly Convert Videos to Multiple Languages With <br /><span className='text-primary ' >AI Video Translation</span></>}
 
          desc={`AI Video Translation allows you to dub existing videos into multiple languages with perfect lip synchronization. This technology not only translates the spoken content but also clones the voice texture and quality of the original presenter, ensuring the new video maintains the authenticity of the original performance.`}
          btn1name={'Get Started'}
          btn1link={register}
          UI={introductionUIForYoutubeVideo}
          image={'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/AI_Video_Translation_top_banner.png'}
        />
        <FourTitleBoxIcon
            title={`How Our AI Video Translation Works`}
            desc={`Unlock global opportunities for your content with our cutting-edge AI translation technology`}
            data={mydata}
        />

        <ThreeDots
            title={'Bridge the Language Gap With  AI Video Translation '}
            desc={`Increase your content's reach with our precise multi-language dubbing`}
            points={threeDotsData}
            videoURL={'https://personate-data-v2.s3.amazonaws.com/website+data+/asset/ai_video_translation_how_to.mp4'}

        />
        <div className='' ><Newsletter bg='bg-lite-gray' py='py-[30px]' /></div>

        <BottomBanner1
            heading={`Visualize the Difference Our AI Video Translation Makes Across Languages`}
            para={`Explore our gallery to see how our AI precisely syncs translations with lip movements`}
            btn1name={'Start Your Journey to Global Connection with Personate'}
            btn1link={register}
        />

    </div>
  )
}
let mydata = [
  {
    "title": "Upload Your Video",
    "desc": "Simply upload the video that needs translation",
    icon : <MdFileUpload />
  },
  {
    "title": "Select Translation Type",
    "desc": "Choose between full visual and audio dubbing for complete lip-sync or opt for audio-only translation if visual sync isn't required",
    icon : <IoOptionsOutline />
  },
  {
    "title": "Get Your Translated Video",
    "desc": "Our AI will process and deliver your video in the new language with high-quality, natural-sounding audio",
    icon : <MdDownloadDone />
  }
]
const threeDotsData = [
  {
    "title": "Broad Language Selection:",
    "desc": "From major world languages to specific regional dialects, we cover it all"
  },
  {
    "title": "Culturally Adapted Dubbing:",
    "desc": "Beyond translation, our AI incorporates cultural nuances to enhance relatability"
  },
  {
    "title": "Seamless Lip-Sync Technology:",
    "desc": "Experience unmatched accuracy in lip-syncing, making dubbed videos appear as original"
  },
  {
    "title": "Scalability for All Projects:",
    "desc": "Whether it's a single video or a large batch, our platform handles your needs efficiently"
  },
  {
    "title": "Cost-Effective Solutions:",
    "desc": "Save significantly compared to traditional dubbing methods"
  }
]

