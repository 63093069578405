import React from 'react'
import styles from './aboutus.module.css'
export const QuestionAndAns = () => {
  return (
    <div className={`bg-white bg-opacity-30 font-redHat w-[90%] laptop:w-[80%] mx-auto rounded-xl p-[20px]`}   >
        <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] mb-[20px]' >Why Choose Personate?</h2>
        {
          data.map((el,i)=>(
            <div key={i} className='mb-[30px]  ' >
              <h3 className='text-off-black text-[22px] laptop:text-[22px] font-[500] ' >{el.title}</h3>
              <p className='text-dark-gray text-[14px] w-[80%] '>{el.description}</p>
            </div>
          ))
        }

        <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] mb-[20px] mt-[40px] ' >Join Us on This Journey</h2>
        {
          data2.map((el,i)=>(
            <div key={i} className='mb-[30px]' >
              <h3 className='text-off-black text-[22px] laptop:text-[22px] font-[500] ' >{el.title}</h3>
              <p className='text-dark-gray text-[14px] w-[80%] '>{el.description}</p>
            </div>
          ))
        }

      <h2 className='text-off-black text-[26px] laptop:text-[32px] font-[500] mt-[40px] ' >Future Goals and Vision</h2>
      <p className='text-dark-gray text-[14px] w-[80%] '>As we look to the future, Personate aims to continue pushing the boundaries of AI in storytelling, expanding our suite of tools and enhancing user experience. Join us as we explore new frontiers and empower storytellers worldwide.
      </p>
    </div>
  )
}


const data = [
  {
    "title": "Ethical Innovation",
    "description": "We advance AI responsibly, ensuring our platform serves as a force for good. We uphold the highest standards of integrity, making technology accessible globally and fostering a community where every voice is heard, and every story is told."
  },
  {
    "title": "Captivating, Engaging Videos at a Fraction of the Cost",
    "description": "Why spend a fortune on traditional video production? With Personate, you can craft stunning, multilingual videos for a fraction of the cost. Our AI Video Generator makes it easy, letting you focus on telling your story."
  },
  {
    "title": "Innovation That Empowers",
    "description": "Our customizable avatars and multilingual capabilities set new industry standards, making sophisticated video production accessible to creators everywhere. We simplify production and break barriers, enabling powerful storytelling."
  },
  
]


const data2 = [
  {
    "title": "Innovate With Us",
    "description": "Push the boundaries of what’s possible with Personate. Our platform is trusted by industry leaders, educators, and innovators seeking to make an impact."
  },
  {
    "title": "Empower Your Stories",
    "description": "Break language barriers and forge deeper connections with every frame. With Personate, you're not just creating videos—you’re connecting, educating, and inspiring."
  },
]