import React, { useState } from 'react'
import styles from './popularPersonality.module.css'
import { RxCross1 } from 'react-icons/rx'
export const PersonalisedVideo = () => {
    const [isPlay,setIsPlay] = useState(false)

    

  return (
    <div className='w-[90%] laptop:w-[80%] mx-auto font-redHat mb-[50px] flex flex-col-reverse laptop:flex-row ' >
        
        <div className='w-[100%] laptop:w-[55%] my-auto mb-[20px] laptop:mb-0 ' >
            <div className='relative cursor-pointer ' >
                <img onClick={()=>setIsPlay(true)}  className='relative top-0 left-0 transition ease-in-out duration-200 w-full h-full object-contain rounded-xl ' src="https://personate-data-v2.s3.amazonaws.com/website+data+/zee+hyper+personalized+video+campaign+personate+ai.jpg" alt='' />
                {isPlay && (
                    <iframe
                        className="z-0 w-[100%] h-[100%] absolute top-0 left-0 bottom-0 right-0 object-fill"
                        src={`https://www.youtube.com/embed/tRU9rZuP6tM`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </div>

        <div className='w-[100%] laptop:w-[45%] my-auto ' >
            <div className='pl-0 laptop:pl-[20px] ' >
                <h2 className='text-[20px] laptop:text-[30px] font-[500] text-off-black ' >Personalized Videos at Scale</h2>
                <p className='text-[15px] laptop:text-[16px] font-[400] text-dark-gray tracking-wide ' >Create and send millions of personalized video messages instantly across WhatsApp, push notifications, email, and more</p>
            </div>
        </div>

    </div>
  )
}

{/* <iframe width="338" height="602" src="https://www.youtube.com/embed/tRU9rZuP6tM" title="Personate Ai Zee Tv Hyper Personalized Campaign" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
