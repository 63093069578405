import './index.css'
import React, { useContext, useEffect, useState } from 'react';
import 'aos/dist/aos.css'; 
import AOS from 'aos';
import MainRoutes from './routes/MainRoutes';
import Navbar from './myComponents/NavbarComponents/Navbar';
import Footer from './myComponents/HomePageComponents/Footer';
import ScrollToTop from './routes/ScrollToTop';
import { MyContext } from './MyContext/Context';
import { useLocation } from 'react-router-dom';
import { TextTicker } from './myComponents/HomePageComponents/TextTicker';

function App() {

  const location = useLocation();
  const {isMenuOpen,setIsMenuOpen} = useContext(MyContext)
  console.log(isMenuOpen,"menu")



  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div className='relative ' >
        <ScrollToTop/>
        {
          location.pathname=='/' && <div className={`${!isMenuOpen?'hidden':'block'}`} ><TextTicker  /></div>
        }
        <Navbar/>
        <MainRoutes/>
        <Footer/>
    </div>
    
  );
}

export default App;
